// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FancyIcon_root_3_nmO{display:block;width:70px;height:70px;overflow:visible}.FancyIcon_root_3_nmO.FancyIcon_inverted_2RvUh .shape{fill:#ffffff}.FancyIcon_root_3_nmO.FancyIcon_inverted_2RvUh .fill{fill:#5a5a5a}\n", ""]);
// Exports
exports.locals = {
	"root": "FancyIcon_root_3_nmO",
	"inverted": "FancyIcon_inverted_2RvUh"
};
module.exports = exports;
