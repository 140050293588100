// core
import React, { forwardRef, memo, MouseEvent, Ref, useCallback } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { Loading } from 'components/basic/Loading/Loading'

// utils
import { runCallback } from 'utils/functions'

import { ButtonComponentWrapper } from './ButtonComponentWrapper'

// styles
import * as css from './Button.scss'

export type ButtonColor =
  | 'primary'
  | 'transparent'
  | 'white'
  | 'neutral'
  | 'success'
  | 'warning'
  | 'danger'

export type ButtonSize = 'mini' | 'small' | 'medium' | 'large'

export interface IButtonProps extends IDotappComponentProps {
  /**
   * component
   */
  component?: 'button' | 'a'
  /**
   * Button vriant
   */
  variant?: 'icon' | 'text'

  contained?: boolean
  /**
   * Button size
   */
  size?: ButtonSize
  /**
   * Styling of the button, default theme color will be used if none specified.
   */
  color?: ButtonColor
  /**
   * OnClick event handler.
   */
  onClick?: (event: MouseEvent) => void
  /**
   * OnMouseDown event handler. We need this event for WysiwygEditor
   */
  onMouseDown?(event: MouseEvent): void
  /**
   * Display loading indicatior instead of text and icon
   */
  loading?: boolean
  /**
   * Disables actions of any type.
   */
  disabled?: boolean
  /**
   * The icon to be shown on the left side
   */
  iconLeft?: string | null
  /**
   * The icon to be shown on trhe right side.
   */
  iconRight?: string | null
  /**
   * The text of a tooltip.
   */
  tooltip?: string
  /**
   * Type of the button used by forms (e.g. 'submit' would submit the HTML form).
   * NOTE: Default is 'button'.
   */
  type?: 'button' | 'reset' | 'submit'

  children?: any
  /**
   * link props
   */
  href?: string
  rel?: string
  target?: string
}

export const Button = memo(
  forwardRef(function Button(
    {
      children,
      component = 'button',
      onClick,
      onMouseDown,
      className,
      loading,
      disabled,
      iconLeft,
      iconRight,
      tooltip,
      type = 'button',
      color = 'neutral',
      size = 'medium',
      variant = 'text',
      contained = false,
      classes = {},
      ...passingProps
    }: IButtonProps,
    ref: Ref<HTMLButtonElement>
  ) {
    const handleClick = useCallback(
      (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()
        event.stopPropagation()
        runCallback(onClick, event)
        if (event.target instanceof Element) {
          const parent = event.target.parentElement?.parentElement?.parentElement
          if (parent) {
            parent.blur()
          }
        }
      },
      [onClick]
    )

    // setting icon(s) if any, otherwise null
    const icons = loading
      ? { left: null, right: null }
      : {
          left: iconLeft ? (
            <div className={classnames(css.iconSection)}>
              <Icon className={classes.icon} name={iconLeft} />
            </div>
          ) : null,
          right: iconRight ? (
            <div className={classnames(css.iconSection)}>
              <Icon className={classes.icon} name={iconRight} />
            </div>
          ) : null,
        }

    return (
      // eslint-disable-next-line
      <ButtonComponentWrapper
        ref={ref}
        className={classnames(
          css.button,
          css[size],
          css[color],
          css[variant],
          { [css.disabled]: disabled, [css.contained]: contained },
          className
        )}
        component={component}
        disabled={loading || disabled}
        type={component === 'button' ? type : undefined}
        onClick={onClick ? handleClick : undefined}
        onMouseDown={onMouseDown}
        {...passingProps}
      >
        {/* folowing div is to make it able to use clickable area larger around button, but display background only in this div */}
        <div className={classnames(css.wrap, classes.wrap)}>
          {icons.left}

          {loading ? (
            <Loading
              size={
                size === 'small'
                  ? 'small'
                  : size === 'mini'
                  ? 'small'
                  : size === 'medium'
                  ? 'default'
                  : 'large'
              }
            />
          ) : children && variant === 'text' ? (
            <div className={classnames(css.content, classes.content)}>{children}</div>
          ) : null}

          {icons.right}

          {/* If there is a tooltip text specified, display a tooltip with the desired text on hover */}
          {tooltip ? <div className={css.tooltip}>{tooltip}</div> : null}
        </div>
      </ButtonComponentWrapper>
    )
  })
)
