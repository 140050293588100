import { gql } from '@apollo/client'

export const AuthMutations = {
  CONTACT_SUPPORT: gql`
    mutation contactSupport(
      $userId: ID
      $userFullName: String
      $userEmail: String
      $title: String!
      $description: String!
      $domainUrl: String!
    ) {
      contactSupport(
        userId: $userId
        userFullName: $userFullName
        userEmail: $userEmail
        title: $title
        description: $description
        domainUrl: $domainUrl
      )
    }
  `,

  LOGIN: gql`
    mutation login($email: String!, $password: String!) {
      login(email: $email, password: $password)
    }
  `,

  REFRESH_TOKEN: gql`
    mutation refreshToken {
      refreshToken
    }
  `,

  RESET_PASSWORD: gql`
    mutation resetPassword($email: String!) {
      resetPassword(email: $email)
    }
  `,

  SET_NEW_PASSWORD: gql`
    mutation setNewPassword($id: ID!, $secret: String!, $newPassword: String!) {
      setNewPassword(id: $id, secret: $secret, newPassword: $newPassword)
    }
  `,
}
