// core
import React from 'react'

// libraries
import classnames from 'classnames'

// data types
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from './AttributeList.scss'

export type AttributeListSize = 'small' | 'medium' | 'large'

export interface IAttributeListProps extends IDotappWrapperComponentProps {
  /**
   * Variant of component to display
   */
  variant?: 'default' | 'bordered'
  /**
   * Coin size
   */
  size?: AttributeListSize
}

/**
 * Simple list of attributes displayed in one line and divided by dot
 */
export const AttributeList = ({
  children,
  className,
  variant = 'default',
  size = 'medium',
}: IAttributeListProps) => {
  return children ? (
    <div className={classnames(css.root, css[variant], css[size], className)} data-testid="root">
      {React.Children.map(children, (child) => child && <div className={css.child}>{child}</div>)}
    </div>
  ) : null
}
