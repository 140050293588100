// core
import React, { LabelHTMLAttributes, ReactElement } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from './FormFieldLabel.scss'

export interface IFormFieldLabelProps
  extends IDotappComponentProps,
    LabelHTMLAttributes<HTMLLabelElement> {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Pass translated text to component
   */
  children: ReactElement<ITranslationProps> | string // TODO: remove string
  /**
   * Id of controll element
   */
  htmlFor: string
}

/**
 * Label for any form field
 */
export const FormFieldLabel = ({
  inverted,
  className,
  children,
  htmlFor,
  ...passingProps
}: IFormFieldLabelProps) => {
  return (
    <label
      className={classnames(css.root, { [css.inverted]: inverted }, className)}
      htmlFor={htmlFor}
      {...passingProps}
    >
      {children}
    </label>
  )
}
