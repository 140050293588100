// core
import React, { memo, MouseEvent, useCallback } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './Backdrop.scss'

export interface IBackdropProps extends IDotappComponentProps {
  /**
   * Whether bacdrop should be visible. There is css animation for showing/hiding backdrop.
   */
  visible: boolean
  /**
   * Css z-index value
   */
  zIndex?: number
  /**
   * Callback to run on backdrop click
   */
  onClick?: () => void
}

/**
 * Simple blurred backdrop
 */
export const Backdrop = memo(function Backdrop({
  visible,
  zIndex = 500,
  onClick,
  className,
}: IBackdropProps) {
  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.preventDefault()
      event.stopPropagation()

      runCallback(onClick)
    },
    [onClick]
  )

  return (
    <div
      className={classnames(css.root, visible ? css.visible : '', className)}
      data-testid="root"
      style={{ zIndex }}
      onClick={visible ? handleClick : undefined}
    />
  )
})
