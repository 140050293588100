// core
import React, { ChangeEvent, forwardRef, Ref, useCallback } from 'react'

import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { getTranslation } from 'components/complex/Translation'
import { IInputProps, Input } from 'components/form/Input/Input'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './SearchInput.scss'

export interface ISearchInputChildrenArgs {}

export interface ISearchInputProps extends Omit<IInputProps, 'onChange'> {
  /**
   * Callback to run on input value change
   */
  onChange?: (value: string) => void
}

export const SearchInput = forwardRef(function SearchInput(
  { onChange, className, ...props }: ISearchInputProps,
  ref: Ref<HTMLInputElement>
) {
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      runCallback(onChange, event.target.value)
    },
    [onChange]
  )

  return (
    <Input
      ref={ref}
      className={classnames(css.root, className)}
      classes={{ input: css.input, inputWrap: css.inputWrap }}
      leftRender={<Icon className={css.icon} name="search" />}
      placeholder={getTranslation('general.search_something')}
      {...props}
      onChange={handleChange}
    />
  )
})
