// core
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

// libraries
import classnames from 'classnames'
import moment from 'moment'
import { Link, Route, Switch } from 'react-router-dom'

// apollo
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'
import { SubscriptionsQueries } from 'queries/Subscriptions/SubscriptionsQueries'

// data types
import { UserSubscriptionStatus } from 'generated/global-types'
import { GetLoggedInUser, GetLoggedInUserVariables } from 'queries/Layout/types/GetLoggedInUser'
import { GetMenu } from 'queries/Layout/types/GetMenu'
import { GetSubscriptionsLaunched } from 'queries/Subscriptions/types/GetSubscriptionsLaunched'

// components
import { ModalContainer, ModalContext } from 'components/basic/Modal'
import { Button } from 'components/complex/Button'
import { LoadingWrapper } from 'components/complex/LoadingWrapper/LoadingWrapper'
import { ITab, Tabs } from 'components/complex/Tabs/Tabs'
import { ToastContextProvider, ToastRenderer } from 'components/complex/Toast/Toast'
import { Translation } from 'components/complex/Translation'

// utils
// import { getMessaging, storeFirebaseMessagingToken } from 'utils/firebase'
// assets
import { ReactComponent as Logo } from 'assets/images/redbull-logo.svg'

// context
import { LoggedInUserContext } from '../index'

// partials
import { Content } from './Content/Content'
import { MainMenu } from './MainMenu/MainMenu'
import { MenuIcon } from './MenuIcon/MenuIcon'
// import { NotificationsMenu } from './Notifications/Notifications'
import { Router } from './Router/Router'
import { SearchContext, useSearchProvider } from './Search/useSearch'
import { SubscribeBanner } from './SubscribeBanner/SubscribeBanner'
import { UserMenu } from './UserMenu/UserMenu'
import { Footer, Header, SidePanel } from './index'

// styles
import * as css from './Layout.scss'

export type TabValue = 'notifications' | 'userMenu'

export type ISidePanelTypes = 'mainMenu' | 'userMenu' | 'notifications'

export const SidePanelContext = createContext<{
  currentlyOpenedSidePanel: ISidePanelTypes | null
  openSidePanel: (menu: ISidePanelTypes | null) => any
}>({
  currentlyOpenedSidePanel: null,
  openSidePanel: () => {
    // do nothing
  },
})

export function Layout() {
  const modalRef = useRef<HTMLDivElement>(null)
  const { loggedInUser } = useContext(LoggedInUserContext)
  const [tab, setTab] = useState<TabValue>('notifications')

  const [openedSidePanel, setOpenedSidePanel] = useState<ISidePanelTypes | null>(null)
  const searchContextOptions = useSearchProvider()

  const client = useApolloClient()

  const { data: userData, loading: userLoading, error } = useQuery<
    GetLoggedInUser,
    GetLoggedInUserVariables
  >(LayoutQueries.GET_LOGGED_IN_USER, {
    skip: !loggedInUser,
    variables: {
      id: loggedInUser?.id || '',
    },
  })

  if (error) {
    console.error(error)
  }

  const subscriptionsLaunchedQuery = useQuery<GetSubscriptionsLaunched>(
    SubscriptionsQueries.GET_SUBSCRIPTIONS_LAUNCHED
  )

  const areSubscriptionsLaunched = subscriptionsLaunchedQuery.data?.sysSettingsOptionItem.value

  //   useEffect(() => {
  //     const messaging = getMessaging()

  //     if (messaging) {
  //       messaging.onTokenRefresh(() => {
  //         messaging
  //           .getToken()
  //           .then((refreshedToken) => refreshedToken && storeFirebaseMessagingToken(refreshedToken))
  //           .catch(console.error)
  //       })
  //     }
  //   }, [])

  useEffect(() => {
    if (loggedInUser) {
      client.writeQuery({
        data: { loggedInUserId: loggedInUser.id },
        query: gql`
          query {
            loggedInUserId
          }
        `,
      })
    }
  }, [loggedInUser])

  const { data } = useQuery<GetMenu>(LayoutQueries.GET_MENU)

  const closeAllMenus = useCallback(() => {
    setOpenedSidePanel(null)
  }, [])

  const handleMainMenuButtonClick = useCallback(() => {
    setOpenedSidePanel((openedSidePanel) => (openedSidePanel === 'mainMenu' ? null : 'mainMenu'))
  }, [openedSidePanel === 'mainMenu'])

  const handleLogoClick = useCallback(() => {
    setOpenedSidePanel(null)
  }, [])

  const sidePanelContextOptions = useMemo(
    () => ({
      currentlyOpenedSidePanel: openedSidePanel,
      openSidePanel: setOpenedSidePanel,
    }),
    []
  )

  const handleTabSelect = useCallback((tab: ITab) => {
    setTab(tab.key as TabValue)
  }, [])

  const tabs = useMemo(
    () => [
      {
        key: 'userMenu',
        title: <Translation keyValue="general.user_menu" />,
      },
    ],
    []
  )

  const trialActiveUntil = userData?.sysUser?.subscriptions[0]?.activeUntil
  const a = moment(trialActiveUntil)
  const b = moment()

  const daysLeft = Math.abs(a.diff(b, 'days'))

  const isTrial = userData?.sysUser?.subscriptionStatus === UserSubscriptionStatus.TRIAL
  const isRestricted = userData?.sysUser?.subscriptionStatus === UserSubscriptionStatus.RESTRICTED

  return (
    <SidePanelContext.Provider value={sidePanelContextOptions}>
      <ModalContext.Provider value={modalRef.current || null}>
        <ModalContainer ref={modalRef} />

        <SearchContext.Provider value={searchContextOptions}>
          <ToastContextProvider>
            <LoadingWrapper loading={userLoading}>
              <React.Suspense fallback={<LoadingWrapper loading />}>
                <Switch>
                  <Route>
                    <Content>
                      <div className={css.headerWrap}>
                        <Header tabSelect={handleTabSelect} />

                        {(isTrial || isRestricted) && areSubscriptionsLaunched && (
                          <SubscribeBanner
                            daysLeft={daysLeft}
                            type={isTrial ? 'trial' : 'restricted'}
                          />
                        )}

                        <MenuIcon
                          isOpen={openedSidePanel === 'mainMenu'}
                          onClick={handleMainMenuButtonClick}
                        />

                        <SidePanel
                          inverted
                          open={openedSidePanel === 'mainMenu'}
                          side="left"
                          width={280}
                          onRequestClose={closeAllMenus}
                        >
                          <div
                            className={classnames(css.mainMenuHeader, {
                              [css.open]: openedSidePanel === 'mainMenu',
                            })}
                          >
                            <Link className={css.link} to="/" onClick={handleLogoClick}>
                              {/* #TODO - this  logo needs to be "dynamic" depending on the URL / company */}
                              <Logo className={css.logo} />
                            </Link>
                          </div>

                          <MainMenu items={data?.sysMenu || []} />

                          <Footer />
                        </SidePanel>
                      </div>

                      <div className={css.content}>
                        {/**
                         * Use another Suspense to display loader only inside main content
                         * (parent Suspense is in Content compoent)
                         */}
                        <React.Suspense fallback={<LoadingWrapper loading />}>
                          <Router routes={data?.sysMenu || []} />
                        </React.Suspense>
                      </div>

                      {loggedInUser ? (
                        <>
                          <SidePanel
                            className={css.sidePanel}
                            open={openedSidePanel === 'userMenu'}
                            side="right"
                            width={400}
                            onRequestClose={closeAllMenus}
                          >
                            <div className={css.tabsWrap}>
                              <Tabs
                                activeTab={tab}
                                classes={{ tab: css.tab }}
                                tabs={tabs}
                                onTabSelect={handleTabSelect}
                              />
                              <Button
                                className={css.closeButton}
                                color="neutral"
                                iconLeft="chevron_right"
                                size="small"
                                variant="icon"
                                onClick={closeAllMenus}
                              />
                            </div>
                            <div className={css.tabContent}>
                              <UserMenu
                                loggedInUser={loggedInUser}
                                openSidePanel={setOpenedSidePanel}
                              />
                            </div>
                          </SidePanel>
                        </>
                      ) : null}

                      {/* <SystemNotifications /> */}
                    </Content>
                  </Route>
                </Switch>
              </React.Suspense>
            </LoadingWrapper>

            <ToastRenderer />
          </ToastContextProvider>
        </SearchContext.Provider>
      </ModalContext.Provider>
    </SidePanelContext.Provider>
  )
}
