// core
import React, { memo, useCallback } from 'react'

// data types
import { MenuItemFragment } from 'queries/Layout/types/MenuItemFragment'

import { getTranslation, Translation } from 'components/complex/Translation'

// components
import { MainMenuItem } from 'layouts/MainMenuItem/MainMenuItem'

// import { LoggedInUserContext } from '../../index'
// styles
import * as css from './MainMenu.scss'

type MenuItem = MenuItemFragment & { subMenu?: MenuItem[] }

export interface IMainMenuProps {
  /**
   * List of items to render
   */
  items: MenuItem[]
}

export const MainMenu = memo(function MainMenu({ items }: IMainMenuProps) {
  // const { loggedInUser } = useContext(LoggedInUserContext)

  const activePath = '/access-right'

  const renderItems = useCallback((items: MenuItem[], firstLevel = true) => {
    return items.map((item) =>
      (item.subMenu && item.subMenu.length > 0) || item.url ? (
        <MainMenuItem
          key={item.id}
          activePath={activePath}
          icon={
            firstLevel
              ? item.icon ||
                // will be left here as a default fallback
                (item.menuTitle === 'users'
                  ? 'supervisor_account'
                  : item.menuTitle === 'banners'
                  ? 'image'
                  : item.menuTitle === 'gifts'
                  ? 'card_giftcard'
                  : item.menuTitle === 'news'
                  ? 'text_snippet'
                  : item.menuTitle === 'ppe.posts_demands'
                  ? 'emoji_flags'
                  : item.menuTitle === 'ppe.posts_offers'
                  ? 'local_offer'
                  : item.menuTitle === 'ppe.posts_my'
                  ? 'text_snippet'
                  : 'language')
              : undefined
          }
          title={item.menuTitle ? <Translation keyValue={item.menuTitle} /> : ''}
          to={item.url && `/${item.url}`}
        >
          {item.subMenu && item.subMenu.length > 0 ? renderItems(item.subMenu, false) : null}
        </MainMenuItem>
      ) : null
    )
  }, [])

  return items && items.length > 0 ? (
    <nav className={css.root} role="navigation">
      <ul>
        <MainMenuItem icon="home" title={getTranslation('general.home')} to="/" />
        {renderItems(items)}

        <hr />

        <MainMenuItem
          key="feedback"
          activePath={activePath}
          icon="feedback"
          title={<Translation keyValue="feedback" />}
          to="/support"
        />

        {/* {loggedInUser?.accessGroupId === 1 && (
          <MainMenuItem key="dev" activePath={activePath} icon="code" title="Dev" to="/dev">
            <MainMenuItem key="dev/dev" activePath={activePath} title="Dev" to="/dev/dev" />
            <MainMenuItem
              key="dev/button"
              activePath={activePath}
              title="Buttons"
              to="/dev/button"
            />
            <MainMenuItem key="dev/form" activePath={activePath} title="Form">
              <MainMenuItem
                key="dev/form-elements"
                activePath={activePath}
                title="Form elements"
                to="/dev/form-elements"
              />
              <MainMenuItem
                key="dev/special-elements"
                activePath={activePath}
                title="Special elements"
                to="/dev/special-elements"
              />
              <MainMenuItem
                key="dev/wysiwyg"
                activePath={activePath}
                title="Wysiwyg editor"
                to="/dev/wysiwyg"
              />
            </MainMenuItem>
            <MainMenuItem key="dev/chips" activePath={activePath} title="Chips" to="/dev/chips" />
            <MainMenuItem
              key="dev/popup-menus"
              activePath={activePath}
              title="Popup Menus"
              to="/dev/popup-menus"
            />
            <MainMenuItem key="dev/tabs" activePath={activePath} title="Tabs" to="/dev/tabs" />
          </MainMenuItem>
        )}

        <MainMenuItem
          key="help"
          activePath={activePath}
          icon="help_outline"
          title={<Translation keyValue="general.help" />}
          to="/help"
        /> */}
      </ul>
    </nav>
  ) : null
})
