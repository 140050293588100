// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Backdrop_root_QhIub{position:fixed;top:0;bottom:0;left:0;right:0;background:rgba(0,0,0,0.05);backdrop-filter:blur(2px);-webkit-backdrop-filter:blur(2px);opacity:0;visibility:hidden;transition:opacity .3s linear,visibility 0s linear .3s}.Backdrop_root_QhIub.Backdrop_visible_r_Av1{opacity:1;visibility:visible;transition:opacity .3s linear}\n", ""]);
// Exports
exports.locals = {
	"root": "Backdrop_root_QhIub",
	"visible": "Backdrop_visible_r_Av1"
};
module.exports = exports;
