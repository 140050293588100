// core
import React, { memo, useCallback, useContext, useRef } from 'react'

// libraries
import classnames from 'classnames'

// formik
import { Formik } from 'formik'

// components
import { Backdrop } from 'components/basic/Backdrop'

// utils
import { runCallback } from 'utils/functions'

// partials
import { SearchForm } from './Partials/SearchForm'
import { SearchContext } from './useSearch'

// styles
import * as css from './Search.scss'

// translate

export interface ISearchProps {
  /**
   * Callback to fun on focus
   */
  onFocus?: () => any
  /**
   * Callback to fun on blur
   */
  onBlur?: () => any
}

export const Search = memo(function Search({ onFocus, onBlur }: ISearchProps) {
  const inputRef = useRef<HTMLInputElement>(null)
  const searchApi = useContext(SearchContext)

  const handleBlur = useCallback(() => {
    searchApi.setFocus(false)
    runCallback(onBlur)
  }, [searchApi, onBlur])

  const handleSearch = useCallback(
    (values, { setSubmitting }) => {
      const value = values.value.trim()
      searchApi.setValue(value && value !== '' ? value : null)
      setSubmitting(false)
      inputRef.current?.focus()
    },
    [searchApi]
  )

  // temporary hide global search
  return searchApi.global ? null : (
    <>
      <Backdrop
        visible={searchApi.focused}
        zIndex={100}
        onClick={handleBlur} // same as Header
      />

      <div
        className={classnames(
          css.root,
          searchApi.focused ? css.focused : '',
          searchApi.focused || searchApi.value ? css.hasValue : ''
        )}
      >
        <Formik
          enableReinitialize
          initialValues={{
            type: 'global',
            value: searchApi.value || '',
          }}
          onSubmit={handleSearch}
        >
          <SearchForm inputRef={inputRef} onBlur={handleBlur} onFocus={onFocus} />
        </Formik>

        {searchApi.global ? (
          <div className={css.resultsWrap}>
            {searchApi.value ? (
              <ul>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        support
                      </span>
                      <span>in Invoices</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        design
                      </span>
                      <span>in Project Documentation</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        Expo
                      </span>
                      <span>in Events</span>
                    </div>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <div>
                      <span>
                        <strong>Web</strong>
                        site Dataweb
                      </span>
                      <span>in Projects</span>
                    </div>
                  </a>
                </li>
              </ul>
            ) : null}
          </div>
        ) : null}
      </div>
    </>
  )
})
