// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Header_root_VGnP8{width:100%;height:50px;padding:0 25px;display:flex;align-items:center;color:#bfbfbf;position:relative;z-index:100;transition:z-index 0s linear .15s}.Header_root_VGnP8.Header_searchFocused_2GkYQ{z-index:101}.Header_root_VGnP8 .Header_searchWrap_Jt2R3{flex:1;display:flex;align-items:flex-start;justify-content:center;margin-left:30px;height:44px}.Header_root_VGnP8 .Header_rightIcons_PTsSz{display:flex;align-items:center}.Header_root_VGnP8 .Header_rightIcons_PTsSz>*:not(:last-child){margin-right:10px}.Header_root_VGnP8 .Header_rightIcons_PTsSz .Header_notificationButton_3jMF1{margin-right:6px}@media (max-width: 767.98px){.Header_root_VGnP8{padding-right:15px;justify-content:flex-end}.Header_root_VGnP8 .Header_searchWrap_Jt2R3{position:fixed;top:3px;left:60px;right:60px;margin-left:0;z-index:100;transition:left .3s ease,right .3s ease,top .3s ease}.Header_root_VGnP8.Header_searchFocused_2GkYQ{transition:z-index 0s}.Header_root_VGnP8.Header_searchFocused_2GkYQ .Header_searchWrap_Jt2R3{top:8px;left:15px;right:15px}}\n", ""]);
// Exports
exports.locals = {
	"root": "Header_root_VGnP8",
	"searchFocused": "Header_searchFocused_2GkYQ",
	"searchWrap": "Header_searchWrap_Jt2R3",
	"rightIcons": "Header_rightIcons_PTsSz",
	"notificationButton": "Header_notificationButton_3jMF1"
};
module.exports = exports;
