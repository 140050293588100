import { Location } from 'history'
import ReactGA from 'react-ga'

const hasAnalytics = !!process.env.REACT_APP_GOOGLE_ANALYTICS_KEY

export const init = () => {
  if (hasAnalytics) {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_KEY || '', {
      // debug: true,
      // testMode: true,
    })
    if (process.env.REACT_APP_BUILD_NUMBER) {
      ReactGA.set({ dimension1: process.env.REACT_APP_BUILD_NUMBER })
    }
  }
}

export const sendPageView = (location: Location) => {
  if (hasAnalytics) {
    ReactGA.pageview(location.pathname + location.search)
  }
}

export const setUser = (userId: string | null) => {
  if (hasAnalytics) {
    ReactGA.set({ userId })
  }
}
