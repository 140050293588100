import { gql } from '@apollo/client'

export const UsersFragments = {
  BANK_INFO_FORM: gql`
    fragment UserBankInfoFormFragment on SysUser {
      id
      address
    }
  `,

  BASIC_INFO_FORM: gql`
    fragment UserBasicInfoFormFragment on SysUser {
      id
      bio
      educationTitle
      name
      surname
    }
  `,

  BILLING_DETAILS_FORM: gql`
    fragment UserBillingDetailsFormFragment on SysUser {
      id
      billingAddressStreet
      billingAddressCity
      billingAddressZip
      billingAddressState
      billingAddressCountry
      billingAddressCountryId
    }
  `,

  COMMUNICATION_FORM: gql`
    fragment UserCommunicationFormFragment on SysUser {
      id
      receiveMessageSummaryEmail
      receiveDocumentsSummaryEmail
      receiveSummaryEmail
      receiveProfileFulfillmentEmail
      receiveWelcomeEmail
      canReceiveWelcomeEmails
      hasFirebaseToken(firebaseToken: $firebaseToken)
    }
  `,
  COMPANY_FORM: gql`
    fragment UserCompanyFormFragment on SysUser {
      id
    }
  `,

  CONSENTS_FORM: gql`
    fragment UserConsentsFormFragment on SysUser {
      id
    }
  `,

  CONTACTS_FORM: gql`
    fragment UserContactsFormFragment on SysUser {
      id
      contacts {
        id
        ...UserProfileContactFragment
      }
    }
  `,

  CREDENTIALS_FORM: gql`
    fragment UserCredentialsFormFragment on SysUser {
      id
    }
  `,

  DOCUMENTS_FORM: gql`
    fragment UserDocumentsFormFragment on SysUser {
      id
    }
  `,

  FLAGS_FORM: gql`
    fragment UserFlagFormFragment on Flag {
      id
      assignedToSysUser(sysUserId: $sysUserId)
      description
      title
    }
  `,

  POSITION_FORM: gql`
    fragment UserPositionFormFragment on SysUser {
      id
      supervisor {
        id
      }
      contractType {
        id
      }
    }
  `,

  POWER_ATTRIBUTES: gql`
    fragment PowerAttributesFragment on SysUser {
      id
    }
  `,

  PROFILE_PICTURE_FORM: gql`
    fragment UserProfilePictureFormFragment on SysUser {
      id
      profilePictureSysFile {
        id
        fileUrl
      }
      verificationStatus
    }
  `,

  RATING: gql`
    fragment UserRatingFragment on PpeRating {
      id
      rating
      review
      fromSysUser {
        id
        fullName
        emailPrimary
        profilePictureSysFile {
          id
          fileUrl
        }
      }
      toPpePost {
        id
        title
        type
        category {
          id
          color
          title
        }
      }
    }
  `,

  SECURITY_FORM: gql`
    fragment UserSecurityFormFragment on SysUser {
      id
      emailPrimary
      emailAlternative
      phonePrimary
      sysAccessGroup {
        id
      }
    }
  `,

  USER_FOR_VERIFICATION: gql`
    fragment UserForVerificationFragment on SysUser {
      id
      verificationStatus
      profilePictureSysFile {
        id
        fileUrl
      }
      verification {
        id
        documentType
      }
    }
  `,

  USER_INFORMATION: gql`
    fragment UserInformationFragment on SysUser {
      id
      slug
      acquiredRatings {
        totalCount
      }
      fullName
      name
      surname
      profilePictureSysFile {
        id
        fileUrl
      }
      sysAccessGroup {
        id
        title
      }
      secondStageVerificationStatus
      verificationStatus
      verification {
        id
        documentType
      }
    }
  `,

  USER_LIST_ITEM: gql`
    fragment UserListItemFragment on SysUser {
      id
      fullName
      emailPrimary
      flags {
        id
      }
      phonePrimary
      profilePictureSysFile {
        id
        fileUrl
      }
      slug
      status
      subscriptions {
        id
        status
        type {
          id
        }
      }
      sysAccessGroup {
        id
        numberOfSysUsers
        title
      }
    }
  `,

  USER_PROFILE: gql`
    fragment UserProfileFragment on SysUser {
      id
      fullName
      name
      surname
      profilePictureSysFile {
        id
        fileUrl
      }
      emailPrimary
      phonePrimary
      bio
      address
      contractType {
        id
        title
      }
      lastLoginAt
      createdAt
      secondStageVerificationStatus
      verificationStatus
      verification {
        id
        documentType
      }
      subscriptionStatus
      slug
      sysAccessGroup {
        id
        title
      }
      contacts {
        id
        ...UserProfileContactFragment
      }
      ratingScore
      acquiredRatings(first: 9999) {
        edges {
          node {
            id
            fromSysUser {
              id
              fullName
              emailPrimary
              profilePictureSysFile {
                id
                fileUrl
              }
            }
            rating
            review
          }
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
      flags {
        id
        title
      }
    }
  `,

  USER_PROFILE_CONTACT: gql`
    fragment UserProfileContactFragment on SysUserContact {
      id
      value
      contactType {
        id
        title
      }
    }
  `,

  WAREHOUSE: gql`
    fragment UserWarehouseFragment on Warehouse {
      id
      category
      coordinates {
        lat
        lng
      }
      country {
        id
        name
      }
      city
      files {
        id
        fileLink
      }
      state
      street
      verificationStatus
      zip
    }
  `,
}
