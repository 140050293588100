// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon, IconSize } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { FancyIcon, FancyIconNames } from 'components/basic/FancyIcon'
import { Image } from 'components/basic/Image'

// styles
import * as css from './Avatar.scss'

export type AvatarSize =
  | 'pico'
  | 'nano'
  | 'micro'
  | 'mini'
  | 'small'
  | 'medium'
  | 'large'
  | 'huge'
  | 'extraHuge'

export interface IAvatarProps extends IDotappComponentProps {
  /**
   * Avatar image url. It will overwrite fancyIcon, icon and children props
   */
  url?: string | null
  /**
   * Use icon as avatar. It will overwrite children prop
   */
  icon?: string | null
  /**
   * Avatar size
   */
  size?: AvatarSize
  /**
   * Pass children to avatar
   */
  children?: ReactNode
  /**
   * use fancy icon as avatar. It will overwrite icon and children props
   */
  fancyIcon?: FancyIconNames
  /**
   * HTML alt attribute for image avatar
   */
  alt?: string
}

/**
 * Avatar with profile picture, icon or text inside
 */
export const Avatar = ({
  alt = 'Avatar',
  url,
  icon,
  fancyIcon,
  size = 'medium',
  children,
  className,
}: IAvatarProps) => {
  const iconSizeMap: { [size in AvatarSize]: IconSize } = {
    extraHuge: 'extraLarge',
    huge: 'extraLarge',
    large: 'large',
    medium: 'medium',
    micro: 'mini',
    mini: 'small',
    nano: 'mini',
    pico: 'mini',
    small: 'small',
  }

  const content = url ? (
    <Image alt={alt} src={url} />
  ) : fancyIcon ? (
    <FancyIcon className={css.fancyIcon} name={fancyIcon} />
  ) : icon ? (
    <Icon color="neutral" name={icon} size={size ? iconSizeMap[size] : undefined} />
  ) : (
    children || null
  )

  return (
    content && (
      <div className={classnames(css.root, css[size], className)} data-testid="root">
        {content}
      </div>
    )
  )
}
