// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Callout_root_14SxT{margin:60px 0;flex-shrink:0}.Callout_root_14SxT .Callout_icon_2vWMb{margin:0 auto 15px auto}.Callout_root_14SxT h4{font-size:24px;font-weight:300;text-align:center;margin-bottom:20px;padding:0 30px}.Callout_root_14SxT .Callout_divider_2gEea{height:2px;width:30px;background-color:#0091ff;margin:0 auto 20px auto}.Callout_root_14SxT .Callout_description_2lzpx{padding:0 30px;margin:0 auto 30px auto;max-width:500px;text-align:center;font-size:14px;line-height:20px;font-weight:300;color:#656565}.Callout_root_14SxT .Callout_description_2lzpx p{text-align:center}\n", ""]);
// Exports
exports.locals = {
	"root": "Callout_root_14SxT",
	"icon": "Callout_icon_2vWMb",
	"divider": "Callout_divider_2gEea",
	"description": "Callout_description_2lzpx"
};
module.exports = exports;
