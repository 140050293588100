// core
import React, { memo } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Divider.scss'

export interface IDividerProps extends IDotappComponentProps {
  /**
   * Color of divider
   */
  color?: 'light' | 'default' | 'dark'
  /**
   * no margin
   */
  withoutMargin?: boolean
}

export const Divider = memo(function Divider({
  withoutMargin,
  color = 'default',
  className,
}: IDividerProps) {
  return (
    <hr
      className={classnames(
        css.root,
        { [css.margin]: !withoutMargin },
        { [css[color]]: color !== 'default' },
        className
      )}
    />
  )
})
