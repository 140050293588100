// core
import React, { memo, MouseEvent, ReactElement, useCallback } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { ExpandableBox } from 'components/basic/ExpandableBox'
import { Link } from 'components/basic/Link/Link'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from '../MainMenuItem.scss'

export interface IItemProps {
  /**
   * Icon for menu item
   */
  icon?: string
  /**
   * Title to display in menu
   */
  title: ReactElement<ITranslationProps> | string
  /**
   * The target address of the item.
   */
  to?: string | null
  /**
   * Currently active path
   */
  activePath?: string
  /**
   * Additional className(s) for custom styling.
   */
  className?: string
  /**
   * Submenu items
   */
  children?:
    | ReactElement<Omit<IItemProps, 'icon'>>
    | (ReactElement<Omit<IItemProps, 'icon'>> | null)[]
    | null
  /**
   * Callback to run on item click
   */
  onClick?: () => any
}

export const Item = memo(function Item({
  icon,
  title,
  to,
  activePath,
  className,
  children,
  onClick,
}: IItemProps) {
  const [showChildren, setShowChildren] = React.useState<boolean>(false)

  const handleItemClick = useCallback((event: MouseEvent<HTMLElement>) => {
    event.currentTarget.blur()

    if (children) {
      event.preventDefault()
      setShowChildren((showChildren) => !showChildren)
    } else if (typeof onClick === 'function') {
      onClick()
    }
  }, [])

  const isActive = activePath && to && activePath.startsWith(to)

  return (
    <li
      className={classnames(
        css.root,
        showChildren ? css.expanded : '',
        isActive ? css.active : '',
        className
      )}
    >
      <Link className={css.item} to={to || undefined} onClick={handleItemClick}>
        {icon ? <Icon className={css.icon} name={icon} /> : null}

        <div className={css.title}>{title}</div>

        {children ? (
          <Icon className={css.expandIcon} name={showChildren ? 'remove' : 'keyboard_arrow_down'} />
        ) : null}
      </Link>

      {children ? (
        <ExpandableBox expanded={showChildren}>
          <ul className={css.children}>{children}</ul>
        </ExpandableBox>
      ) : null}
    </li>
  )
})
