// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".UserAvatar_root_1wgod{color:#656565;text-decoration:none !important}.UserAvatar_root_1wgod>div{box-sizing:content-box}.UserAvatar_root_1wgod .UserAvatar_noPicture_2Jike{background-color:#f7f7f7}.UserAvatar_root_1wgod .UserAvatar_fancyIcon_2h9dz{width:80%;height:80%}\n", ""]);
// Exports
exports.locals = {
	"root": "UserAvatar_root_1wgod",
	"noPicture": "UserAvatar_noPicture_2Jike",
	"fancyIcon": "UserAvatar_fancyIcon_2h9dz"
};
module.exports = exports;
