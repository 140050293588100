// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Avatar_root_3TPeu{overflow:hidden;border:1px solid white;border-radius:50%;display:flex;align-items:center;justify-content:center;background-color:#e6e6e6;color:#a6a6a6}.Avatar_root_3TPeu.Avatar_pico_3wTMB{width:15px;height:15px;min-width:15px;min-height:15px;font-size:8px;line-height:8px}.Avatar_root_3TPeu.Avatar_nano_2Di1T{width:20px;height:20px;min-width:20px;min-height:20px;font-size:10px;line-height:10px}.Avatar_root_3TPeu.Avatar_micro_2DRgl{width:24px;height:24px;min-width:24px;min-height:24px;font-size:12px;line-height:12px}.Avatar_root_3TPeu.Avatar_mini_3BZPx{width:32px;height:32px;min-width:32px;min-height:32px;font-size:14px;line-height:14px}.Avatar_root_3TPeu.Avatar_small_SFZt5{width:36px;height:36px;min-width:36px;min-height:36px;font-size:16px;line-height:16px}.Avatar_root_3TPeu.Avatar_medium_24od-{width:50px;height:50px;min-width:50px;min-height:50px;font-size:22px;line-height:22px}.Avatar_root_3TPeu.Avatar_large_2_MqT{width:60px;height:60px;min-width:60px;min-height:60px;font-size:26px;line-height:26px}.Avatar_root_3TPeu.Avatar_huge_2ObtL{width:70px;height:70px;min-width:70px;min-height:70px;font-size:28px;line-height:28px}.Avatar_root_3TPeu.Avatar_extraHuge_2deZm{width:80px;height:80px;min-width:80px;min-height:80px;font-size:32px;line-height:32px}.Avatar_root_3TPeu .Avatar_fancyIcon_1uhry{width:80%;height:80%}\n", ""]);
// Exports
exports.locals = {
	"root": "Avatar_root_3TPeu",
	"pico": "Avatar_pico_3wTMB",
	"nano": "Avatar_nano_2Di1T",
	"micro": "Avatar_micro_2DRgl",
	"mini": "Avatar_mini_3BZPx",
	"small": "Avatar_small_SFZt5",
	"medium": "Avatar_medium_24od-",
	"large": "Avatar_large_2_MqT",
	"huge": "Avatar_huge_2ObtL",
	"extraHuge": "Avatar_extraHuge_2deZm",
	"fancyIcon": "Avatar_fancyIcon_1uhry"
};
module.exports = exports;
