// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Translation_root_2u9Rc{color:inherit;font-weight:inherit;font-size:inherit;text-decoration:inherit}.Translation_root_2u9Rc.Translation_exists_2t2Cw{border-bottom:2px solid #28ca42}.Translation_root_2u9Rc.Translation_notExists_36b-v{border-bottom:2px solid #ff6059}\n", ""]);
// Exports
exports.locals = {
	"root": "Translation_root_2u9Rc",
	"exists": "Translation_exists_2t2Cw",
	"notExists": "Translation_notExists_36b-v"
};
module.exports = exports;
