// core
import React, { ReactElement } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from './FormFieldHelperText.scss'

export interface IFormFieldHelperTextProps extends IDotappComponentProps {
  /**
   * Whether to render input in one line with order components
   */
  inline?: boolean
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Pass translated string to component
   */
  children: ReactElement<ITranslationProps>
}

/**
 * Render helper text bellow any form field
 */
export const FormFieldHelperText = ({
  inline,
  inverted,
  className,
  children,
}: IFormFieldHelperTextProps) => {
  return (
    <div
      className={classnames(
        css.root,
        {
          [css.inverted]: inverted,
          [css.inline]: inline,
        },
        className
      )}
    >
      {children}
    </div>
  )
}
