// core
import React from 'react'

// libraries
import classnames from 'classnames'

// apollo
// import { useQuery } from '@apollo/client'
// import { SubscriptionsQueries } from 'queries/Subscriptions/SubscriptionsQueries'
// data types
import { UserSubscriptionStatus } from 'generated/global-types'

// import { GetSubscriptionsLaunched } from 'queries/Subscriptions/types/GetSubscriptionsLaunched'
// components
// import { BlurredText } from 'components/basic/BlurredText'
import { Chip, IChipProps } from 'components/complex/Chip/Chip'

// import { Translation } from 'components/complex/Translation'
// styles
import * as css from './SubscriptionStatus.scss'

export enum Status {
  NONE = 'NONE',
  ADMIN = 'ADMIN',
}

export interface ISubscriptionStatusProps extends Omit<IChipProps, 'children'> {
  /**
   * User verification status
   */
  status: UserSubscriptionStatus | Status | null
  /**
   * User access group id to find out if it is admin
   */
  accessGroupId?: string
  /**
   * Name of the access group
   */
  accessGroupTitle?: string
}

export const SubscriptionStatus = ({
  status,
  accessGroupId,
  accessGroupTitle,
  className,
  ...passingProps
}: ISubscriptionStatusProps) => {
  //   const subscriptionsLaunchedQuery = useQuery<GetSubscriptionsLaunched>(
  //     SubscriptionsQueries.GET_SUBSCRIPTIONS_LAUNCHED
  //   )

  //   const areSubscriptionsLaunched = subscriptionsLaunchedQuery.data?.sysSettingsOptionItem.value

  //   const subscriptionStatusColorMap: {
  //     [key in UserSubscriptionStatus | Status]: IChipProps['color']
  //   } = {
  //     ADMIN: 'danger',
  //     MEMBER: 'primary',
  //     NONE: 'neutral',
  //     RESTRICTED: 'neutral',
  //     TRIAL: 'warning',
  //   }

  //   const subscriptionTranslationMap: { [key in UserSubscriptionStatus]: string } = {
  //     MEMBER: 'member',
  //     NONE: 'entry',
  //     RESTRICTED: 'entry',
  //     TRIAL: areSubscriptionsLaunched ? 'trial' : 'entry',
  //   }

  const isAdmin = accessGroupId === '1'

  return !(status === Status.NONE && !isAdmin) ? (
    <Chip
      className={classnames(css.root, className)}
      //   color={subscriptionStatusColorMap[!status ? Status.NONE : isAdmin ? 'ADMIN' : status]}
      color="primary"
      contained={false}
      {...passingProps}
    >
      {accessGroupTitle}
      {/* {status ? (
        <Translation
          keyValue={
            isAdmin ? 'general.admin' : `subscriptions.${subscriptionTranslationMap[status]}`
          }
        />
      ) : (
        <BlurredText>Staus</BlurredText>
      )} */}
    </Chip>
  ) : null
}
