// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Stick_root_tzynY{border-radius:1.5px;width:3px;height:14px;background-color:#e6e6e6}.Stick_root_tzynY.Stick_inverted_3-yNS{background-color:#656565}\n", ""]);
// Exports
exports.locals = {
	"root": "Stick_root_tzynY",
	"inverted": "Stick_inverted_3-yNS"
};
module.exports = exports;
