// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from './Coin.scss'

export type CoinColor = 'transparent' | 'neutral' | 'primary' | 'success' | 'warning' | 'danger'

export type CoinSize = 'small' | 'medium' | 'large'

export interface ICoinProps extends IDotappWrapperComponentProps {
  /**
   * Styling of the coin, default theme color will be used if none specified.
   */
  color?: CoinColor
  /**
   * Coin size
   */
  size?: CoinSize
}

/**
 * Simple circle with background and text inside
 */
export const Coin = ({ children, color = 'primary', className, size = 'medium' }: ICoinProps) => {
  return <div className={classnames(css.root, css[color], css[size], className)}>{children}</div>
}
