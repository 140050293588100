import { gql } from '@apollo/client'
import { UsersFragments } from 'queries/Users/UsersFragments'

import { LayoutFragments } from './LayoutFragments'

export const LayoutQueries = {
  GET_CONTENT_FOR_FULL_SCREEN_CONFIRMATION: gql`
    query GetContentForFullScreenConfirmation($id: ID!) {
      masterArticle(id: $id) {
        id
        title
        content
      }
    }
  `,

  GET_LOGGED_IN_USER: gql`
    query GetLoggedInUser($id: ID!) {
      sysUser(id: $id) {
        id
        slug
        dismissedWelcomeWidget
        hasFirebaseToken
        subscriptions {
          id
          activeUntil
        }
        subscriptionStatus
        sysAccessGroup {
          id
          enabledProperties {
            id
            token
            sysModule {
              id
              token
            }
          }
        }
        verificationStatus
      }
    }
  `,

  GET_MENU: gql`
    query GetMenu {
      sysMenu {
        id
        ...MenuItemFragment
        subMenu {
          id
          ...MenuItemFragment
          subMenu {
            id
            ...MenuItemFragment
          }
        }
      }
    }
    ${LayoutFragments.MENU_ITEM}
  `,

  GET_POST_BY_SLUG: gql`
    query GetPostBySlug($slug: String!) {
      ppePostBySlug(slug: $slug) {
        id
      }
    }
  `,

  GET_SETTINGS: gql`
    query GetSettings {
      sysSettingsOptions {
        id
        alias
        sysSettingsOptionItems {
          id
          alias
          value
        }
      }
    }
  `,

  GET_TRANSLATIONS: gql`
    query GetTranslations($languageId: ID!) {
      selectedLanguageId @client @export(as: "languageId")

      sysLanguageKeys(first: 9999, orderBy: null, orderByDirection: ASC, search: null, tags: null)
        @connection(
          key: "sysLanguageKeys"
          filter: ["orderBy", "orderByDirection", "search", "tags"]
        ) {
        edges {
          node {
            id
            languageKey
            translationForSysLanguage(sysLanguageId: $languageId) {
              id
              translation
            }
          }
          cursor
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
  `,

  GET_USER_BY_SLUG: gql`
    query GetUserBySlug($slug: String!) {
      sysUserBySlug(slug: $slug) {
        id
      }
    }
  `,
  // TODO: prepare separate query for number of unseen notifications
  GET_USER_MENU_DATA: gql`
    query GetUserMenuData($id: ID!) {
      selectedLanguageId @client

      sysUser(id: $id) {
        id
        ...UserInfoHeaderFragment
        ...UserInformationFragment
      }

      sysLanguages {
        id
        codeShort
      }

      unseenNotifications: notifications(
        first: 9999
        after: null
        targetSysUserId: $id
        isSeen: false
      ) @connection(key: "notifications", filter: ["targetSysUserId", "isSeen"]) {
        edges {
          cursor
        }
        pageInfo {
          endCursor
        }
        totalCount
      }
    }
    ${LayoutFragments.USER}
    ${UsersFragments.USER_INFORMATION}
  `,
}
