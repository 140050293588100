// core
import React, { memo, ReactElement } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { FancyIcon, FancyIconNames } from 'components/basic/FancyIcon'
import { IButtonProps } from 'components/complex/Button'
import { ButtonGroup } from 'components/complex/ButtonGroup/ButtonGroup'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from './Callout.scss'

export interface ICalloutProps extends IDotappComponentProps {
  /**
   * The icon to be shown on the left side
   */
  icon: FancyIconNames
  /**
   * Callout title
   */
  title: ReactElement<ITranslationProps> | string
  /**
   * Callout description
   */
  description?: ReactElement<HTMLParagraphElement>
  /**
   * Buttons to render above the card
   */
  buttons?: ReactElement<IButtonProps>[]
}

export const Callout = memo(function Callout({
  buttons,
  classes = {},
  className,
  description,
  icon,
  title,
}: ICalloutProps) {
  return (
    <div className={classnames(css.root, className)}>
      <FancyIcon className={classnames(css.icon, classes.icon)} name={icon} />

      <h4 className={classes.title}>{title}</h4>

      {description ? (
        <>
          <hr className={classnames(css.divider, classes.divider)} />

          <div className={classnames(css.description, classes.description)}>{description}</div>
        </>
      ) : null}

      {buttons ? <ButtonGroup>{buttons}</ButtonGroup> : null}
    </div>
  )
})
