// core
import React, { isValidElement, ReactElement, ReactNode, useCallback, useState } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon, IconSize } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { BreakpointNames, useWindowWidth } from 'components/basic/Hidden/useWindowWidth'
import { Popover } from 'components/complex/Popover'
import { ITranslationProps, Translation } from 'components/complex/Translation'

// styles
import * as css from './Popup.scss'

export type PopupColor = 'neutral' | 'primary' | 'success' | 'warning' | 'danger'

export type PopupSize = 'small' | 'medium' | 'large'

export type PopupSide = 'left' | 'right'

export interface IPopupProps extends IDotappComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether popup should be disabled
   */
  disabled?: boolean
  /**
   * Color of popup heading and icon
   */
  color?: PopupColor
  /**
   * Popup heading
   */
  heading: string | ReactElement<ITranslationProps> // TODO: remove string
  /**
   * Popup content bellow heading
   */
  content?: ReactNode
  /**
   * Width and margins of popup
   */
  size?: PopupSize
  /**
   * Icon next to heading
   */
  icon?: string
  /**
   * Children component - usually Button
   */
  children: ReactElement<any>
  /**
   * From which side to expand popup
   */
  side?: PopupSide
}

/**
 * Display text popups over any child component
 */
export function Popup({
  inverted = false,
  disabled,
  color = 'neutral',
  heading,
  content,
  className,
  children,
  side = 'right',
  size = 'medium',
  icon,
  classes = {},
}: IPopupProps) {
  const { isSmallerThan } = useWindowWidth()
  const isMobile = isSmallerThan(BreakpointNames.sm)

  const [open, setOpen] = useState<boolean>(false)

  const widthMap: { [key in PopupSize]: number } = {
    large: 300,
    medium: 200,
    small: 160,
  }

  const iconSizeMap: { [key in PopupSize]: IconSize } = {
    large: 'small',
    medium: 'small',
    small: 'mini',
  }

  const handleHover = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      setOpen(true)
    },
    [setOpen]
  )

  const handleBlur = useCallback(
    (event?: any) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      setOpen(false)
    },
    [setOpen]
  )

  const contentComponent =
    typeof content === 'string' ||
    (content && isValidElement(content) && content.type === Translation) ? (
      <p className={classes.content}>{content}</p>
    ) : (
      content
    )

  return (
    <div
      className={classnames(css.root, className, classes.root)}
      data-testid="root"
      onBlur={isMobile ? undefined : handleBlur}
      onFocus={isMobile ? undefined : handleHover}
      onMouseEnter={isMobile ? undefined : handleHover}
      onMouseLeave={isMobile ? undefined : handleBlur}
    >
      {React.cloneElement(children, {
        onClick: disabled ? undefined : handleHover,
      })}

      <Popover
        backdrop={isMobile}
        className={classes.popover}
        classes={{
          content: css.content,
          paper: classnames(css.paper, css[size], classes.paper),
          paperSideLeft: css.left,
          paperSideRight: css.right,
          popover: classnames(
            css.popoverComponent,
            css[color],
            { [css.inverted]: inverted },
            classes.popoverComponent
          ),
        }}
        expandDirection="bottomToTop"
        inverted={inverted}
        open={open}
        side={side}
        width={widthMap[size]}
        onBlur={handleBlur}
      >
        <div className={css.headingWrap}>
          {icon && (
            <Icon
              className={classnames(css.icon, classes.icon)}
              color={inverted && color === 'neutral' ? 'white' : color}
              name={icon}
              size={iconSizeMap[size]}
            />
          )}

          <h4 className={classes.heading}>{heading}</h4>
        </div>

        {size !== 'small' && contentComponent}
      </Popover>
    </div>
  )
}
