import React, { useContext, useEffect } from 'react'

// libraries
import { useLocation } from 'react-router-dom'

// apollo
import { useQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import { GetSettings } from 'queries/Layout/types/GetSettings'
import { GetTranslations, GetTranslationsVariables } from 'queries/Layout/types/GetTranslations'

// components
import { LoadingWrapper } from 'components/complex/LoadingWrapper/LoadingWrapper'

// layouts
import { Layout, LayoutAuth } from 'layouts'
import { Content } from 'layouts/Content/Content'
// utils
import { sendPageView } from 'utils/analytics'

import { LoggedInUserContext } from './index'

function AppWithAnalytics({ loggedInUser }: any) {
  const location = useLocation()

  useEffect(() => {
    sendPageView(location)
  }, [])

  return !loggedInUser ? <LayoutAuth /> : <Layout />
}

export default function App() {
  const { loggedInUser } = useContext(LoggedInUserContext)

  const { loading: settingsLoading } = useQuery<GetSettings>(LayoutQueries.GET_SETTINGS)

  const { loading: translationsLoading } = useQuery<GetTranslations, GetTranslationsVariables>(
    LayoutQueries.GET_TRANSLATIONS
  )

  if (typeof loggedInUser === 'undefined' || settingsLoading || translationsLoading) {
    return (
      <Content>
        <LoadingWrapper loading />
      </Content>
    )
  }

  return <AppWithAnalytics loggedInUser={loggedInUser} />
}
