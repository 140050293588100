// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Loading_root_3GhwQ{border-right-color:transparent !important}.Loading_root_3GhwQ.Loading_primaryColor_QfuXi{border-color:#0091ff}.Loading_root_3GhwQ.Loading_large_2aFcM{width:30px;height:30px;border-width:2px}.Loading_root_3GhwQ.Loading_default_3ikpz{width:20px;height:20px;border-width:1px}.Loading_root_3GhwQ.Loading_small_2c1Yt{width:15px;height:15px;border-width:1px}\n", ""]);
// Exports
exports.locals = {
	"root": "Loading_root_3GhwQ",
	"primaryColor": "Loading_primaryColor_QfuXi",
	"large": "Loading_large_2aFcM",
	"default": "Loading_default_3ikpz",
	"small": "Loading_small_2c1Yt"
};
module.exports = exports;
