// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// icons
import { ReactComponent as Ai } from './icons/Ai.svg'
import { ReactComponent as Analysis } from './icons/Analysis.svg'
import { ReactComponent as Architecture } from './icons/Architecture.svg'
import { ReactComponent as Bag } from './icons/Bag.svg'
import { ReactComponent as Barrier } from './icons/Barrier.svg'
import { ReactComponent as Car } from './icons/Car.svg'
import { ReactComponent as CreditCard } from './icons/CreditCard.svg'
import { ReactComponent as Cube } from './icons/Cube.svg'
import { ReactComponent as Database } from './icons/Database.svg'
import { ReactComponent as Discussion } from './icons/Discussion.svg'
import { ReactComponent as Document } from './icons/Document.svg'
import { ReactComponent as Dynamic } from './icons/Dynamic.svg'
import { ReactComponent as ECommerce } from './icons/ECommerce.svg'
import { ReactComponent as Feather } from './icons/Feather.svg'
import { ReactComponent as Handshake } from './icons/Handshake.svg'
import { ReactComponent as Image } from './icons/Image.svg'
import { ReactComponent as Insert } from './icons/Insert.svg'
import { ReactComponent as Interaction } from './icons/Interaction.svg'
import { ReactComponent as Launch } from './icons/Launch.svg'
import { ReactComponent as Layers } from './icons/Layers.svg'
import { ReactComponent as Layout } from './icons/Layout.svg'
import { ReactComponent as Lock } from './icons/Lock.svg'
import { ReactComponent as Logistic } from './icons/Logistic.svg'
import { ReactComponent as Mailbox } from './icons/Mailbox.svg'
import { ReactComponent as Map } from './icons/Map.svg'
import { ReactComponent as NoImage } from './icons/NoImage.svg'
import { ReactComponent as NoUser } from './icons/NoUser.svg'
import { ReactComponent as OnlinePayment } from './icons/OnlinePayment.svg'
import { ReactComponent as PaintBucket } from './icons/PaintBucket.svg'
import { ReactComponent as PaintRoller } from './icons/PaintRoller.svg'
import { ReactComponent as Pantone } from './icons/Pantone.svg'
import { ReactComponent as Pending } from './icons/Pending.svg'
import { ReactComponent as Planning } from './icons/Planning.svg'
import { ReactComponent as Puzzle } from './icons/Puzzle.svg'
import { ReactComponent as Router } from './icons/Router.svg'
import { ReactComponent as Satellite } from './icons/Satellite.svg'
import { ReactComponent as Server } from './icons/Server.svg'
import { ReactComponent as Tag } from './icons/Tag.svg'
import { ReactComponent as TrafficLight } from './icons/TrafficLight.svg'
import { ReactComponent as Trash } from './icons/Trash.svg'
import { ReactComponent as User } from './icons/User.svg'
import { ReactComponent as Vector } from './icons/Vector.svg'
import { ReactComponent as Verification } from './icons/Verification.svg'
import { ReactComponent as Warning } from './icons/Warning.svg'
import { ReactComponent as Webdata } from './icons/Webdata.svg'
import { ReactComponent as World } from './icons/World.svg'
import { ReactComponent as Writing } from './icons/Writing.svg'

// styles
import * as css from './FancyIcon.scss'

export const iconMap = {
  ai: Ai,
  analysis: Analysis,
  architecture: Architecture,
  bag: Bag,
  barrier: Barrier,
  car: Car,
  creditCard: CreditCard,
  cube: Cube,
  database: Database,
  discussion: Discussion,
  document: Document,
  dynamic: Dynamic,
  eCommerce: ECommerce,
  feather: Feather,
  handshake: Handshake,
  image: Image,
  insert: Insert,
  interaction: Interaction,
  launch: Launch,
  layers: Layers,
  layout: Layout,
  lock: Lock,
  logistic: Logistic,
  mailbox: Mailbox,
  map: Map,
  noImage: NoImage,
  noUser: NoUser,
  onlinePayment: OnlinePayment,
  paintBucket: PaintBucket,
  paintRoller: PaintRoller,
  pantone: Pantone,
  pending: Pending,
  planning: Planning,
  puzzle: Puzzle,
  router: Router,
  satellite: Satellite,
  server: Server,
  tag: Tag,
  trafficLight: TrafficLight,
  trash: Trash,
  user: User,
  vector: Vector,
  verification: Verification,
  warning: Warning,
  webdata: Webdata,
  world: World,
  writing: Writing,
}

export type FancyIconNames = keyof typeof iconMap

export interface IFancyIconProps extends IDotappComponentProps {
  /**
   * Whether to invert colors
   */
  inverted?: boolean
  /**
   * Icon name to render
   */
  name: FancyIconNames
}

/**
 * Fancy SVG icons
 */
export function FancyIcon({ inverted = false, name, className, style }: IFancyIconProps) {
  return Object.hasOwnProperty.call(iconMap, name)
    ? React.createElement(iconMap[name], {
        className: classnames(css.root, { [css.inverted]: inverted }, className),
        'data-testid': 'root',
        style,
        viewBox: '5 5 30 30',
      })
    : null
}
