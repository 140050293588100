import { gql } from '@apollo/client'

import { SubscriptionsFragments } from './SubscriptionsFragments'

export const SubscriptionsQueries = {
  GET_PAYMENT_METHODS: gql`
    query GetPaymentMethods($sysUserId: ID!) {
      sysUser(id: $sysUserId) {
        id
        stripeCustomerId
        paymentMethods {
          id
          ...PaymentMethodFragment
        }
      }
    }
    ${SubscriptionsFragments.PAYMENT_METHOD}
  `,

  GET_SUBSCRIPTIONS_LAUNCHED: gql`
    query GetSubscriptionsLaunched {
      sysSettingsOptionItem(id: "36") {
        id
        alias
        value
      }
    }
  `,

  GET_SUBSCRIPTION_TYPES: gql`
    query GetSubscriptionTypes {
      subscriptionTypes {
        id
        ...SubscriptionTypeFragment
      }
    }
    ${SubscriptionsFragments.SUBSCRIPTION_TYPE}
  `,

  GET_USER_SUBSCRIPTION_DATA: gql`
    query GetUserSubscriptionData($id: ID!) {
      sysUser(id: $id) {
        id
        subscriptionStatus
        ...UserSubscriptionDataFragment
      }
    }
    ${SubscriptionsFragments.USER_SUBSCRIPTION_DATA}
  `,

  GET_USER_SUBSCRIPTION_DETAILS: gql`
    query GetUserSubscriptionDetails($id: ID!) {
      sysUser(id: $id) {
        id
        ...UserSubscriptionDetailsFragment
      }
    }
    ${SubscriptionsFragments.USER_SUBSCRIPTION_DETAILS}
  `,
}
