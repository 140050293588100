// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Image.scss'

// default img
const defaultImage = require('assets/images/defaultImage.jpg')

export type ImageVariant = 'original' | 'square' | 'circle'

export interface IImageProps extends IDotappComponentProps {
  /**
   * Image source URL. If missing we will use default placeholder image
   */
  src?: string | null
  /**
   * HTML alt attribute for image
   */
  alt?: string
  /**
   * Variant of image shape
   */
  variant?: ImageVariant
  /**
   * Width of the image. Default is "100%"
   */
  width?: number
  /**
   * Height of the image. It is ignored for 'square' and 'circle' variant.
   */
  height?: number
}

/**
 * Image in diffetent variants with a placeholder if it is missing.
 */
export const Image = ({
  src,
  alt,
  variant = 'original',
  className,
  width,
  height,
}: IImageProps) => {
  const imgSource = src || defaultImage

  return (
    <img
      alt={alt || `Image: ${imgSource}`}
      className={classnames(css.image, css[variant], { [css.placeholder]: !src }, className)}
      data-testid="root"
      src={imgSource}
      style={{
        height:
          height || (variant !== 'original' && width)
            ? `${variant === 'original' ? height : width}px`
            : undefined,
        width: width && `${width}px`,
      }}
    />
  )
}
