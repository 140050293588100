// core
import React, { forwardRef, Ref } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from './Paper.scss'

export type PaperElevation = 1 | 2

export interface IPaperProps extends IDotappWrapperComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Paper elevation
   */
  elevation?: PaperElevation
}

/**
 * Simple div with background
 */
export const Paper = forwardRef(
  ({ inverted, elevation = 1, children, className, style }: IPaperProps, ref: Ref<any>) => {
    return (
      <div
        ref={ref}
        className={classnames(
          css.root,
          css[`elevation${elevation}`],
          {
            [css.inverted]: inverted,
          },
          className
        )}
        style={style}
      >
        {children}
      </div>
    )
  }
)
