// core
import React, { MouseEvent, ReactElement, useRef } from 'react'

import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { Button } from 'components/complex/Button'
import { PopupMenu } from 'components/complex/PopupMenu/PopupMenu'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from './Tabs.scss'

export interface ITab {
  /**
   * Title to display
   */
  title: ReactElement<ITranslationProps> | string
  /**
   * Tab key for identification
   */
  key: string
}

export interface ITabsProps extends IDotappComponentProps {
  /**
   * Tab variant
   */
  variant?: 'horizontal' | 'vertical' | 'buttonGroup'
  /**
   * Currently opened tab
   */
  activeTab: string
  /**
   * List of all tabs
   */
  tabs: ITab[]
  /**
   * Event on tab click
   */
  onTabSelect: (tab: ITab) => void
  /**
   * Disable tabs
   */
  disabled?: boolean
}

export const Tabs = ({
  variant = 'horizontal',
  activeTab,
  tabs,
  disabled = false,
  onTabSelect,
  className,
  classes = {},
}: ITabsProps) => {
  const tabsWrapRef = useRef<HTMLDivElement>(null)

  const handleClick = (tab: ITab) => (event: MouseEvent<HTMLButtonElement>) => {
    event.currentTarget.blur()

    onTabSelect(tab)
  }

  const handleMenuClick = (tab: ITab) => (_: any, close: () => void) => {
    onTabSelect(tab)
    close()
  }

  // useEffect(() => {
  //   // scroll to see clicked tab
  //   if (tabsWrapRef.current && tabs.length > 3) {
  //     const wrap = tabsWrapRef.current

  //     const activeButton = wrap.getElementsByClassName('active')[0]

  //     if (activeButton) {
  //       activeButton.scrollIntoView()
  //     }
  //   }
  // }, [activeTab])

  return (
    <div className={classnames(css.root, css[variant], className, classes.root)}>
      {(!disabled && variant === 'horizontal' && tabs.length > 3) || variant === 'vertical' ? (
        <PopupMenu
          className={classnames(
            css.menuIcon,
            variant === 'vertical' || tabs.length <= 5 ? css.onlyMobile : '',
            classes.menuIcon
          )}
          options={tabs.map((tab) => ({
            onClick: handleMenuClick(tab),
            title: tab.title,
            value: tab.key,
          }))}
          side="left"
        >
          <Button contained color="transparent" iconLeft="more_vert" variant="icon" />
        </PopupMenu>
      ) : null}

      <div
        ref={tabsWrapRef}
        className={classnames(
          css.tabsWrap,
          classes.tabsWrap,
          tabs.length > 3 ? css.scroll : '',
          classes.tabsWrap
        )}
      >
        {tabs.map((tab) => (
          <Button
            key={`tabSelector_${tab.key}`}
            className={classnames(
              css.tab,
              tab.key === activeTab
                ? classnames(
                    css.active,
                    'active' /* to be able to find it with getElementsByClassName() metheod above */
                  )
                : '',
              classes.tab
            )}
            color="transparent"
            disabled={disabled}
            variant="text"
            onClick={handleClick(tab)}
          >
            {tab.title}
          </Button>
        ))}
      </div>
    </div>
  )
}
