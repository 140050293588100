// core
import React, { createContext, useEffect, useState } from 'react'

// libraries
import { createPortal } from 'react-dom'

// components
import { Backdrop } from 'components/basic/Backdrop'
import { useModalContainer } from 'components/basic/Modal/useModalContainer'

// partials
import { IModalComponentProps, ModalComponent } from './Partials/ModalComponent'

export const ModalContext = createContext<HTMLDivElement | null>(null)

export interface IModalProps extends Omit<IModalComponentProps, 'x' | 'y'> {
  /**
   * Whether to use backdrop
   */
  backdrop?: boolean
  /**
   * Callback to run on backdrop click
   */
  onBlur?: () => any
  /**
   * X coordinate on page. Modal will be placed on the center of the screen if ommited.
   */
  x?: number
  /**
   * Y coordinate on page. Modal will be placed 100px from the top of the screen if ommited.
   */
  y?: number
}

/**
 * Simple component to place anything absolutely on the screen above anything else.
 * Renders only simple rectangle on fixed position with fixed width.
 * Height is variable but there is max-height set to prevent screen overlap
 * X-coord can be modified to prevent horizontal screen overlap (width stays unchanged)
 */
export const Modal = ({
  backdrop = true,
  open: requestedOpen,
  x: requestedX,
  y: requestedY,
  zIndex = 500,
  width: requestedWidth,
  onBlur,
  classes = {},
  ...passingProps
}: IModalProps) => {
  // we need this state for initial open animation in css
  const [open, setOpen] = useState<boolean>(false)
  const {
    container: modalContainer,
    width: containerWidth,
    height: containerHeight,
  } = useModalContainer()

  useEffect(() => {
    setOpen(requestedOpen)
  }, [requestedOpen])

  // do no throw error if modalContainer is not defined (not placed anywhere on the screen)
  if (!modalContainer) {
    return null
  }

  const width = Math.min(requestedWidth, containerWidth)
  let x = typeof requestedX === 'number' ? requestedX : (containerWidth - width) / 2
  let y = typeof requestedY === 'number' ? requestedY : 100

  // check boudaries
  x = Math.min(Math.max(0, x), containerWidth - width)
  y = Math.min(Math.max(0, y), containerHeight)

  return createPortal(
    <>
      {backdrop && (
        <Backdrop className={classes.backdrop} visible={open} zIndex={zIndex} onClick={onBlur} />
      )}

      <ModalComponent open={open} width={width} x={x} y={y} zIndex={zIndex} {...passingProps} />
    </>,
    modalContainer
  )
}
