// core
import React, { forwardRef, Ref } from 'react'

// styles
import * as css from './Modal.scss'

/**
 * Container to display all modals inside.
 * It should be places only oncer and on the somewhere top of dom hierarchy.
 */
export const ModalContainer = forwardRef((_, ref: Ref<HTMLDivElement>) => {
  return <div ref={ref} className={css.container} />
})
