// must be imported before App
// eslint-disable-next-line simple-import-sort/sort
import './styles/without_modules/index.scss'

// core
import React, {
  createContext,
  useState,
  useEffect,
  Fragment,
  ComponentType,
  useCallback,
} from 'react'
import ReactDOM from 'react-dom'
import { Helmet } from 'react-helmet'
import { Router, useHistory, useLocation } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import { init as initApollo } from 'api/Api'

// bugsnag
import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'

import { ApolloProvider } from '@apollo/client'

// Google Analytics
import { init as initAnalytics, sendPageView, setUser } from 'utils/analytics'

// utils
import { logout, parsePayload, refreshToken } from 'utils/auth'
import { configure as configureYup } from 'utils/yup'

// stripe
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

import App from './App'

import './styles/variables-export.scss'

// moment locales for support of all locales
import 'moment/min/locales'

// include firebase service worker to build
// eslint-disable-next-line import/extensions
// import 'firebase-messaging-sw.js'

// include some helper functions to support older browsers
import 'core-js/stable'
import 'core-js/es/promise'
import 'regenerator-runtime/runtime'

const stripeLoader = process.env.REACT_APP_STRIPE_API_KEY
  ? loadStripe(process.env.REACT_APP_STRIPE_API_KEY)
  : null

const hasBugsnag = !!process.env.REACT_APP_BUGSNAG_API_KEY
let ErrorBoundary: ComponentType<any> = Fragment

if (hasBugsnag) {
  Bugsnag.start({
    apiKey: process.env.REACT_APP_BUGSNAG_API_KEY || '',
    appType: 'client',
    appVersion: process.env.REACT_APP_BUILD_NUMBER || '0',
    enabledBreadcrumbTypes: [
      'navigation',
      // 'request', // leave custom breadcrubms in Apollo link
      'process',
      'log',
      'user',
      'state',
      'error',
      'manual',
    ],
    plugins: [new BugsnagPluginReact()],
    redactedKeys: ['password', 'authorization'],
  })
  const bugsnagReactPlugin = Bugsnag.getPlugin('react')
  if (bugsnagReactPlugin) {
    ErrorBoundary = bugsnagReactPlugin.createErrorBoundary(React)
  }
}

initAnalytics()

export type LoggedInUserType = {
  id: string
  name: string
  surname: string
  fullName: string
  emailPrimary: string
  slug: string
  accessGroupId: number
  dismissedWelcomeWidget: boolean
}

export const LoggedInUserContext = createContext<{
  loggedInUser: LoggedInUserType | null | undefined
  setLoggedInUser: (data: LoggedInUserType | null) => void
}>({
  loggedInUser: null,
  setLoggedInUser: () => {
    // do nothing
  },
})

initApollo().then((client) => {
  const history = createBrowserHistory()

  history.listen((location) => {
    sendPageView(location)
  })

  function AppRoot() {
    const location = useLocation()
    const history = useHistory()

    const [loggedInUser, setLoggedInUserState] = useState<LoggedInUserType | null | undefined>(
      undefined
    )

    const setLoggedInUser = useCallback(
      (user: LoggedInUserType | null) => {
        setLoggedInUserState(user)

        if (user) {
          setUser(user.id || null)
        } else {
          logout(true).then(() => {
            // keep user set until firebase token is unregistered
            setUser(null)

            history.push('/login', {
              prevPath: location.pathname + location.search,
            })
          })
        }
      },
      [setLoggedInUserState, location, history, client]
    )

    useEffect(() => {
      // set translations for yup
      configureYup()

      // add custom error callback to apollo client
      // @ts-ignore
      // eslint-disable-next-line no-param-reassign
      client.onError = () => {
        setLoggedInUser(null)

        return Promise.resolve()
      }

      // check stored auth token
      const token = localStorage.getItem('token')

      if (token) {
        const payload = parsePayload(token)

        // check if token expired
        if (payload && typeof payload === 'object' && payload.exp < Date.now() / 1000) {
          setLoggedInUser(null)
        }
        // try to refresh token
        else {
          refreshToken().then((token) => {
            const payload = parsePayload(token)
            setLoggedInUser(typeof payload === 'object' ? (payload as LoggedInUserType) : null)
          })
        }

        // send logged in user to Bugsnag
        if (hasBugsnag && payload && typeof payload === 'object') {
          const handleError = (event: any) => {
            event.setUser(payload.id, payload.emailPrimary, payload.fullName)
          }
          Bugsnag.addOnError(handleError)

          return () => {
            Bugsnag.removeOnError(handleError)
          }
        }
      } else {
        // we should not redirect user because maybe we are in set-new-password route or any other public route
        setLoggedInUserState(null)
        logout(true)
      }

      return undefined
    }, [])

    return (
      <LoggedInUserContext.Provider value={{ loggedInUser, setLoggedInUser }}>
        <App />
      </LoggedInUserContext.Provider>
    )
  }

  function AppSetup() {
    return (
      <ErrorBoundary>
        {process.env.REACT_APP_GOOGLE_ANALYTICS_STREAM_KEY && (
          <Helmet>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-WQ57MKPHP6" />
            <script>
              {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${process.env.REACT_APP_GOOGLE_ANALYTICS_STREAM_KEY}');
            `}
            </script>
          </Helmet>
        )}

        <ApolloProvider client={client}>
          <Elements stripe={stripeLoader}>
            <Router history={history}>
              <AppRoot />
            </Router>
          </Elements>
        </ApolloProvider>
      </ErrorBoundary>
    )
  }

  ReactDOM.render(<AppSetup />, document.getElementById('root'))
})
