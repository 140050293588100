// core
import React, { cloneElement, FocusEvent, ReactElement, useCallback, useRef, useState } from 'react'

// libraries
import classnames from 'classnames'
import { v4 as uuidv4 } from 'uuid'

// formik
import { FieldInputProps } from 'formik'

// components
import { IDotappComponentProps } from 'components'
import { InfoPopup } from 'components/complex/InfoPopup'
import { ITranslationProps } from 'components/complex/Translation'
import { FormFieldHelperText } from 'components/form/_partials/FormFieldHelperText/FormFieldHelperText'
import { IFormFieldLabelProps } from 'components/form/_partials/FormFieldLabel/FormFieldLabel'
import { FormFieldSideRender } from 'components/form/_partials/FormFieldSideRender/FormFieldSideRender'
import { FormFieldValidationLabel } from 'components/form/_partials/FormFieldValidationLabel/FormFieldValidationLabel'
import { FormFieldWrap } from 'components/form/_partials/FormFieldWrap/FormFieldWrap'

// styles
import * as css from './FormFieldBase.scss'

export interface IFormFieldBaseProps extends IDotappComponentProps {
  /**
   * Whether the input is disabled and cannot be changed
   */
  disabled?: boolean
  /**
   * Whether to render input in one line with order components
   */
  inline?: boolean
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether the input is focused
   */
  focused?: boolean
  /**
   * Validation for field
   * undefinded: disabled validation
   * true: valid
   * false: invald
   */
  valid?: boolean
  /**
   * Display validation icon
   */
  displayValidationIcon?: boolean
  /**
   * Error message to display in popup
   */
  errorMessage?: string
  /**
   * Whether to pin label to top. Label will pin automatically if `focused` is true
   */
  pinLabel?: boolean
  /**
   * Helper text under field
   */
  helperText?: ReactElement<ITranslationProps>
  /**
   * Info headiing which is displayed in popup
   */
  infoHeading?: ReactElement<ITranslationProps>
  /**
   * Info text which is displayed in popup
   */
  infoContent?: string
  /**
   * What should render on the left side?
   */
  leftRender?: ReactElement<any> | null
  /**
   * What should render on the right side?
   */
  rightRender?: ReactElement<any> | null
  /*
   * Form element to wrap
   */
  children: ReactElement<any>
  /*
   * Label element
   */
  label?: ReactElement<IFormFieldLabelProps> | null
}

/**
 * Base struncture for form field with validation, side renders, info and helper text
 */
export const FormFieldBase = ({
  inline,
  inverted = false,
  focused,
  pinLabel,
  valid,
  displayValidationIcon = true,
  errorMessage,
  helperText,
  infoHeading,
  infoContent,
  leftRender,
  rightRender,
  disabled,
  className,
  classes = {},
  label,
  children,
}: IFormFieldBaseProps) => {
  return (
    <FormFieldWrap
      className={classnames(
        css.root,
        {
          [css.disabled]: disabled,
          [css.inverted]: inverted,
        },
        classes.root,
        className
      )}
      inline={inline}
    >
      <div className={classnames(css.wrap, classes.wrap)}>
        {leftRender ? (
          <FormFieldSideRender inverted={inverted}>{leftRender}</FormFieldSideRender>
        ) : null}

        <div
          className={classnames(
            css.inputWrap,
            classes.inputWrap,
            !disabled && displayValidationIcon && typeof valid !== 'undefined'
              ? valid
                ? '' /* css.valid */
                : css.invalid
              : '',
            {
              [css.noBorderRight]: rightRender,
              [css.noBorderLeft]: leftRender,
              [css.focused]: focused,
              [classes.focused]: focused,
              [css.pinLabel]: pinLabel || focused,
            }
          )}
        >
          {label
            ? cloneElement(label, { className: classnames(css.label, label.props.className) })
            : null}

          {cloneElement(children, {
            className: classnames(css.input, children.props.className, classes.input),
          })}

          {!disabled && displayValidationIcon && typeof valid !== 'undefined' ? (
            <FormFieldValidationLabel
              className={classes.validationLabel}
              errorMessage={errorMessage}
              inverted={inverted}
              valid={valid}
            />
          ) : null}
        </div>

        {rightRender ? (
          <FormFieldSideRender inverted={inverted}>{rightRender}</FormFieldSideRender>
        ) : null}

        {infoHeading && infoContent ? (
          <InfoPopup
            className={classnames(css.infoIcon, classes.infoIcon)}
            content={infoContent}
            disabled={disabled}
            heading={infoHeading}
            inverted={inverted}
            size="small"
          />
        ) : null}
      </div>

      {helperText ? (
        <FormFieldHelperText className={classes.helperText} inline={inline} inverted={inverted}>
          {helperText}
        </FormFieldHelperText>
      ) : null}
    </FormFieldWrap>
  )
}

/**
 * Helper hook to create field id
 */
export const useId = (name: string) => {
  const { current: id } = useRef(`${name}${uuidv4()}`)

  return id
}

/**
 * Helper hook to add focus to field
 */
export const useFocus = <T extends { [key: string]: any }>(
  field: FieldInputProps<any>
): [boolean, (e: FocusEvent<T>) => void, (e: FocusEvent<T>) => void] => {
  const [focused, setFocused] = useState<boolean>(false)

  const handleFocus = useCallback(() => {
    setFocused(true)
  }, [])

  const handleBlur = useCallback(
    (e: FocusEvent<T>) => {
      setFocused(false)
      field.onBlur(e)
    },
    [field.onBlur]
  )

  return [focused, handleFocus, handleBlur]
}
