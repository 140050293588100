import { getClient } from 'api/Api'
import * as jwt from 'jsonwebtoken'

// apollo
import { AuthMutations } from 'queries/Auth/AuthMutations'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// utils
// import { disableNotifications } from 'utils/firebase'

export const parsePayload = (token: string) => {
  return jwt.decode(token)
}

/**
 * Save token to local storage
 */
export const setNewToken = (token: string) => {
  localStorage.setItem('token', token)
}

/**
 * Login user and store token and email
 */
export const login = (email: string, password: string): Promise<string> => {
  return getClient()
    .mutate({ mutation: AuthMutations.LOGIN, variables: { email, password } })
    .then(({ data }: { data: { login: string } }) => {
      const token = data.login

      // store data
      setNewToken(token)
      localStorage.setItem('lastAuthEmail', email)

      return token
    })
}

/**
 * Refresh token and store it
 */
export const refreshToken = () => {
  return getClient()
    .mutate({ mutation: AuthMutations.REFRESH_TOKEN })
    .then(({ data }: { data: { refreshToken: string } }) => {
      const token = data.refreshToken

      setNewToken(token)

      return token
    })
}

/**
 * Remove token and email from local storage
 */
export const cleanLocalStorage = (rememberEmail = false) => {
  localStorage.removeItem('token')

  if (!rememberEmail) {
    localStorage.removeItem('lastAuthEmail')
  }

  Object.keys(localStorage)
    .filter(
      (key) =>
        key.startsWith('sort-') ||
        key.startsWith('filter-') ||
        key.startsWith('quickFilter-') ||
        key.startsWith('search-')
    )
    .forEach((key) => localStorage.removeItem(key))
}

/**
 * Remove token and all data from local storage
 */
export const logout = (rememberEmail = false): Promise<any> => {
  //   const disableNotificationsPromise = localStorage.getItem('token')
  //     ? disableNotifications()
  //     : Promise.resolve()

  //   return disableNotificationsPromise.then(() => {
  return Promise.resolve().then(() => {
    cleanLocalStorage(rememberEmail)

    const client = getClient()

    if (!client) {
      return Promise.resolve()
    }

    let settings: any
    let translations: any

    try {
      settings = client.readQuery({ query: LayoutQueries.GET_SETTINGS })
      translations = client.readQuery({ query: LayoutQueries.GET_TRANSLATIONS })
    } catch {
      // do nothing
    }

    return client.clearStore().then(() => {
      client.writeQuery({ data: settings, query: LayoutQueries.GET_SETTINGS })
      client.writeQuery({ data: translations, query: LayoutQueries.GET_TRANSLATIONS })
      client.reFetchObservableQueries()
    })
  })
}
