// core
import React, { ReactElement } from 'react'

// components
import { NoResultsMessage } from 'components/complex/NoResultsMessage/NoResultsMessage'
import { Translation } from 'components/complex/Translation'

export interface IDataFilterProps<T> {
  /**
   * Data to filter
   */
  data: T[]
  /**
   * Conditions for filttering. [data_item_property]: filter_value
   */
  conditions: { [property: string]: string } | null
  /**
   * Children function with filtered data as argument
   */
  children: (data: T[]) => any
}

export function DataFilter<T extends { [key: string]: any }>({
  data,
  conditions,
  children,
}: IDataFilterProps<T>): ReactElement<IDataFilterProps<T>> {
  // do not filter if we dont have coonditions
  if (!conditions) {
    return children(data)
  }

  // change condition values to lower case
  const conditionProperties = Object.keys(conditions)
  const conditionValues: { [property: string]: string } = {}
  conditionProperties.forEach((property) => {
    conditionValues[property] = conditions[property].toLowerCase()
  })

  const filtered = data.filter((item) =>
    conditionProperties.find((property) => {
      if (Object.hasOwnProperty.call(item, property)) {
        const regex = new RegExp(`(^|\\s)${conditionValues[property]}`)
        return regex.test(item[property].toLowerCase())
      }
      return false
    })
  )

  if (filtered.length === 0) {
    return (
      <NoResultsMessage>
        <Translation keyValue="general.no_results.filter" />
      </NoResultsMessage>
    )
  }

  return children(filtered)
}
