// core
import React, { useRef } from 'react'

// libraries
import classnames from 'classnames'

// components
import { Modal } from 'components/basic/Modal'

// partials
import { IPopoverComponentProps, PopoverComponent } from './Partials/PopoverComponent'

// styles
import * as css from './Popover.scss'

export interface IPopoverProps extends IPopoverComponentProps {
  /**
   * Whether to use backdrop
   */
  backdrop?: boolean
  /**
   * Callback to run on blur
   */
  onBlur?: () => any
  /**
   * Css z-index value
   */
  zIndex?: number
}

/**
 * General component to display some content in popover container.
 * Modal will be placed relatively to parent container.
 * There is expand animation for both width and height.
 * Component will change expand direction when it is near the screen edge.
 */
export function Popover({
  open,
  backdrop = true,
  side = 'left',
  onBlur,
  expandDirection = 'topToBottom',
  zIndex = 700,
  className,
  classes = {},
  width = 400,
  inverted = false,
  ...passingProps
}: IPopoverProps) {
  const popoverPositionRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)

  const spaceNearEdge = 5

  const { x, y, width: refWidth } = popoverPositionRef.current
    ? popoverPositionRef.current.getBoundingClientRect()
    : { width, x: undefined, y: undefined }

  // get height of content
  const parent = contentRef.current?.parentNode as HTMLElement
  const height = (parent && parent.scrollHeight) || 0

  // change direction if necessary
  const horizontalDirection = x
    ? side === 'left'
      ? // if we exceeding screen limits from right
        x + width + spaceNearEdge > innerWidth
        ? 'right'
        : 'left'
      : // if we exceeding screen limits from top
      x - width - spaceNearEdge < 0
      ? 'left'
      : 'right'
    : side
  const verticalDirection = y
    ? expandDirection === 'topToBottom'
      ? // if we exceeding screen limits from bottom
        y + height + spaceNearEdge > innerHeight
        ? 'bottomToTop'
        : 'topToBottom'
      : // if we exceeding screen limits from top
      y - height - spaceNearEdge < 0
      ? 'topToBottom'
      : 'bottomToTop'
    : expandDirection

  // place origin relative to reference element
  const finalXCoord = horizontalDirection === 'left' ? x : x && x + refWidth - width

  return (
    <>
      <div
        ref={popoverPositionRef}
        className={classnames(
          css.refDiv,
          css[horizontalDirection],
          css[verticalDirection],
          classes.refDiv,
          className
        )}
        data-testid="refDiv"
      />

      <Modal
        backdrop={backdrop}
        className={classes.modal}
        open={open}
        width={width}
        x={finalXCoord}
        y={y}
        zIndex={zIndex}
        onBlur={onBlur}
      >
        <PopoverComponent
          ref={contentRef}
          className={classnames(css.root, classes.popover)}
          classes={{
            ...classes,
            paper: classnames(classes.paper, {
              [classes.paperSideLeft]: horizontalDirection === 'left',
              [classes.paperSideRight]: horizontalDirection === 'right',
            }),
          }}
          expandDirection={verticalDirection}
          inverted={inverted}
          open={open}
          side={horizontalDirection}
          width={width}
          {...passingProps}
        />
      </Modal>
    </>
  )
}
