// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscriptionStatus_root_1Sst6{font-weight:500 !important;margin-top:0;margin-bottom:0;text-transform:capitalize}.SubscriptionStatus_root_1Sst6.SubscriptionStatus_small_4rhT4{margin-right:8px}.SubscriptionStatus_root_1Sst6.SubscriptionStatus_normal_3fDxt{margin-right:12px;height:22px;font-size:12px}\n", ""]);
// Exports
exports.locals = {
	"root": "SubscriptionStatus_root_1Sst6",
	"small": "SubscriptionStatus_small_4rhT4",
	"normal": "SubscriptionStatus_normal_3fDxt"
};
module.exports = exports;
