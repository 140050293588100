// core
import React, { ReactElement } from 'react'

import classnames from 'classnames'

// components
import { AlignPosition, IDotappComponentProps } from 'components'
import { IButtonProps } from 'components/complex/Button'

// style
import * as css from './ButtonGroup.scss'

export interface IButtonGroupProps extends IDotappComponentProps {
  /**
   * List of items
   */
  children: (ReactElement<IButtonProps> | null)[]
  /**
   * Where to align buttons. Default: center
   */
  align?: AlignPosition
}

export function ButtonGroup({ align = 'center', children, className }: IButtonGroupProps) {
  return <div className={classnames(css.root, css[align], className)}>{children}</div>
}
