// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Divider_root_1yyRq{border-top:1px solid #bfbfbf}.Divider_root_1yyRq .Divider_margin_2DKoV{margin:-2px 15px 0 15px}.Divider_root_1yyRq.Divider_light_1MfDR{border-color:#e6e6e6}.Divider_root_1yyRq.Divider_dark_1DdRP{border-color:#656565}\n", ""]);
// Exports
exports.locals = {
	"root": "Divider_root_1yyRq",
	"margin": "Divider_margin_2DKoV",
	"light": "Divider_light_1MfDR",
	"dark": "Divider_dark_1DdRP"
};
module.exports = exports;
