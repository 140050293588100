// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ButtonGroup_root_37wNw{display:flex;align-items:center}.ButtonGroup_root_37wNw.ButtonGroup_left_2zAk_{justify-content:flex-start}.ButtonGroup_root_37wNw.ButtonGroup_left_2zAk_>*:first-child{padding-left:0}.ButtonGroup_root_37wNw.ButtonGroup_center_16d15{justify-content:center}.ButtonGroup_root_37wNw.ButtonGroup_right_1nxDa{justify-content:flex-end}.ButtonGroup_root_37wNw.ButtonGroup_right_1nxDa>*:last-child{padding-right:0}.ButtonGroup_root_37wNw :not(:last-child){padding-right:7px}\n", ""]);
// Exports
exports.locals = {
	"root": "ButtonGroup_root_37wNw",
	"left": "ButtonGroup_left_2zAk_",
	"center": "ButtonGroup_center_16d15",
	"right": "ButtonGroup_right_1nxDa"
};
module.exports = exports;
