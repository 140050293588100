// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'
import { Chip } from 'components/basic/Chip'
import { Coin, CoinSize, ICoinProps } from 'components/basic/Coin'

// styles
import * as css from './Badge.scss'

export type BadgeSize = CoinSize

export interface IBadgeProps extends IDotappWrapperComponentProps, ICoinProps {
  /**
   * Display content in badge
   */
  content: null | number | string
  /**
   * Choose side to render badge
   */
  side?: 'left' | 'right'
  /**
   * Whether badge should display only fir char and expand on hover
   */
  expandable?: boolean
}

/**
 * Count badge displayed at the top corner for any component which it wraps
 */
export const Badge = ({
  content,
  side = 'right',
  color = 'primary',
  size = 'medium',
  expandable = false,
  children,
  className,
  classes = {},
}: IBadgeProps) => {
  const stringContent = (content || '').toString()
  const shouldRenderCoin = stringContent.length <= 2
  const isExpandable = !shouldRenderCoin && expandable
  const BadgeComponent = shouldRenderCoin ? Coin : Chip

  return (
    <div className={classnames(css.root, css[side], css[color], className)}>
      {children}

      {content ? (
        <BadgeComponent
          className={classnames(
            css.badge,
            css[size],
            { [css.expandable]: isExpandable },
            classes.badge
          )}
          color={color}
          size={size}
        >
          {isExpandable ? <div className={css.expandableDiv}>{stringContent}</div> : stringContent}
        </BadgeComponent>
      ) : null}
    </div>
  )
}
