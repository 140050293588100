// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'

// styles
import * as css from './Stick.scss'

export interface IStickProps extends IDotappComponentProps {
  /**
   * if color should be inverted
   */
  inverted?: boolean
}

export const Stick = ({ inverted, className }: IStickProps) => {
  return <div className={classnames(css.root, { [css.inverted]: inverted }, className)} />
}
