// core
import React, { FocusEvent, ReactElement, useCallback, useContext, useMemo, useState } from 'react'

// libraries
import classnames from 'classnames'

// formik
import { Formik } from 'formik'

// components
import { IDotappComponentProps } from 'components'
import { Divider } from 'components/basic/Divider/Divider'
import { ExpandableBoxContext } from 'components/basic/ExpandableBox'
import { DataFilter } from 'components/complex/DataFilter/DataFilter'
import { SearchInput } from 'components/complex/SearchInput/SearchInput'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './ListWithSearch.scss'

export interface IListWithSearchProps<T> extends IDotappComponentProps {
  /**
   * Data to filter
   */
  data: T[]
  /**
   * Inverted color scheme of input
   */
  inverted?: boolean
  /**
   * If true, search input is hidden
   */
  isHiddenSearch?: boolean
  /**
   * Property names to search
   */
  searchProperties: string[]
  /**
   * Children function with filtered data as argument
   */
  children: (data: T[]) => any
  /**
   * Event that runs on search input change
   */
  onChange?: (value: string) => any
}

export function ListWithSearch<T>({
  data,
  inverted,
  isHiddenSearch,
  searchProperties = [],
  children,
  onChange,
  className,
}: IListWithSearchProps<T>): ReactElement<IListWithSearchProps<T>> {
  const refresh = useContext(ExpandableBoxContext)

  const [searchString, setSearchString] = useState<string>('')

  const handleChange = useCallback(
    (value: string) => {
      setSearchString(value)
      runCallback(onChange, value)
      setTimeout(refresh, 0)
    },
    [onChange, setSearchString, refresh]
  )

  const handleBlur = useCallback((event: FocusEvent<HTMLInputElement>) => {
    event.stopPropagation()
  }, [])

  const handleSubmit = useCallback(() => {
    // do nothing
  }, [])

  const initialValues = useMemo(() => ({}), [])

  return (
    <div className={classnames(css.root, className)}>
      {data.length > 0 && !isHiddenSearch ? (
        <>
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            <SearchInput
              className={css.wSearchBar}
              inverted={inverted}
              name="search"
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Formik>

          <Divider />
        </>
      ) : null}

      <div className={css.resultsWrap}>
        <DataFilter<T>
          conditions={
            searchString
              ? searchProperties.reduce(
                  (o: { [property: string]: string }, i: string) => ({ ...o, [i]: searchString }),
                  {}
                )
              : null
          }
          data={data}
        >
          {(data) => children(data)}
        </DataFilter>
      </div>
    </div>
  )
}
