// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MenuIcon_root_3PXPj{cursor:pointer;width:22px;margin:8px;position:absolute;z-index:100;top:10px;left:16px;transition:z-index .3s step-end}.MenuIcon_root_3PXPj span{display:block;height:2px;overflow:hidden;background-color:#2b2b2b;margin-bottom:5px;transition:background-color .3s ease .15s,margin-left .3s ease;border-radius:1px}.MenuIcon_root_3PXPj span:first-child{width:50%;margin-left:45%}.MenuIcon_root_3PXPj span:nth-child(2){width:100%}.MenuIcon_root_3PXPj span:last-child{width:60%;margin-left:20%;margin-bottom:0}.MenuIcon_root_3PXPj.MenuIcon_isOpen_15zTX{z-index:201;transition:none}.MenuIcon_root_3PXPj.MenuIcon_isOpen_15zTX span{background-color:white;transition:background-color .3s ease,margin-left .3s ease}.MenuIcon_root_3PXPj.MenuIcon_isOpen_15zTX span:first-child{margin-left:15%}.MenuIcon_root_3PXPj.MenuIcon_isOpen_15zTX span:last-child{margin-left:40%}\n", ""]);
// Exports
exports.locals = {
	"root": "MenuIcon_root_3PXPj",
	"isOpen": "MenuIcon_isOpen_15zTX"
};
module.exports = exports;
