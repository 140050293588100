// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SearchInput_root_3YBpV{margin-bottom:0}.SearchInput_root_3YBpV .SearchInput_icon_36dnB{margin-top:2px;font-size:22px;color:#bfbfbf}.SearchInput_root_3YBpV .SearchInput_inputWrap_i-9F9{background:none !important;box-shadow:none !important}.SearchInput_root_3YBpV .SearchInput_inputWrap_i-9F9 .SearchInput_input_3fWI_{text-indent:4px;padding-left:10px !important;font-size:14px !important}\n", ""]);
// Exports
exports.locals = {
	"root": "SearchInput_root_3YBpV",
	"icon": "SearchInput_icon_36dnB",
	"inputWrap": "SearchInput_inputWrap_i-9F9",
	"input": "SearchInput_input_3fWI_"
};
module.exports = exports;
