// core
import React, { useCallback } from 'react'

// libraries
import classnames from 'classnames'
import moment from 'moment'
import { useHistory } from 'react-router'

// datatypes
import { SubscriptionStatus, UserSubscriptionStatus } from 'generated/global-types'

// components
import { AttributeList } from 'components/basic/AttributeList'
import { Chip, ChipColor } from 'components/basic/Chip'
import { DateString } from 'components/basic/DateString/DateString'
import { Button, ButtonColor } from 'components/complex/Button'
import { Translation } from 'components/complex/Translation'

// styles
import * as css from '../UserMenu.scss'

export interface ISubscriptionsStatusProps {
  /**
   * days left
   */
  activeUntil: Date
  /**
   * status of subscription
   */
  status: SubscriptionStatus
  /**
   * subscription type
   */
  subscription: UserSubscriptionStatus | null
  /**
   * Subscription ID
   */
  subscriptionId?: string
  /**
   * Callback to close side panel
   */
  closePanel: () => void
}

export const SubscriptionStatuses = ({
  activeUntil,
  status,
  subscription,
  closePanel,
}: ISubscriptionsStatusProps) => {
  const history = useHistory()

  const isMember = subscription === UserSubscriptionStatus.MEMBER
  const isPendingCancel = status === SubscriptionStatus.PENDING_CANCEL

  const subscriptionColorMap: { [key in UserSubscriptionStatus]: ButtonColor } = {
    MEMBER: isPendingCancel ? 'warning' : 'success',
    NONE: 'danger',
    RESTRICTED: 'danger',
    TRIAL: 'warning',
  }

  const SubscriptionStatusToColor: {
    [key in SubscriptionStatus]: ChipColor
  } = {
    ACTIVE: subscription === UserSubscriptionStatus.TRIAL ? 'neutral' : 'success',
    CANCELED: 'neutral',
    PENDING_CANCEL: 'warning',
    UNPAID: 'danger',
  }

  const subscriptionButtonTextMap: { [key in UserSubscriptionStatus]: string } = {
    MEMBER: isPendingCancel ? 'reactivate' : 'manage',
    NONE: 'subscribe',
    RESTRICTED: 'subscribe',
    TRIAL: 'subscribe',
  }

  const titleMap: { [key in UserSubscriptionStatus]: string } = {
    MEMBER: 'pro_plan',
    NONE: 'subscription_expired',
    RESTRICTED: 'subscription_expired',
    TRIAL: 'trial',
  }

  const handleButtonClick = useCallback(() => {
    history.push('/my-profile/subscription')
    closePanel()
  }, [history, closePanel])

  const ended = activeUntil < new Date()
  const a = moment(activeUntil)
  const b = moment()

  const difference = (time: 'days' | 'months') => {
    return Math.abs(a.diff(b, time))
  }

  return subscription ? (
    <div
      className={classnames(css.statusRoot, css[subscription.toLowerCase()], {
        [css.pendingCancel]: isPendingCancel,
      })}
    >
      {' '}
      <p className={css.subscriptionText}>
        <Translation keyValue={`subscriptions.${titleMap[subscription]}`} />
      </p>
      <div className={css.bottomFlex}>
        <div className={css.chipWrap}>
          <Chip
            className={css.userStatus}
            color={
              subscription === UserSubscriptionStatus.RESTRICTED
                ? 'danger'
                : SubscriptionStatusToColor[status]
            }
            variant="light"
          >
            <Translation
              keyValue={`subscriptions.${
                subscription === UserSubscriptionStatus.RESTRICTED ? 'entry' : status.toLowerCase()
              }`}
            />
          </Chip>

          {isMember ? (
            <AttributeList>
              <Translation
                keyValue={isPendingCancel ? 'general.actions.ends' : 'subscriptions.next_billing'}
              />
              <DateString value={activeUntil} />
            </AttributeList>
          ) : subscription === UserSubscriptionStatus.TRIAL ? (
            difference('days') < 1 ? (
              <Translation keyValue="subscriptions.expires_today" />
            ) : difference('days') < 31 ? (
              <Translation
                days={difference('days')}
                keyValue={
                  difference('days') === 1
                    ? ended
                      ? 'general.day_ago'
                      : 'general.day_left'
                    : ended
                    ? 'general.days_ago'
                    : 'general.days_left'
                }
              />
            ) : (
              <Translation
                keyValue={
                  difference('days') === 1
                    ? ended
                      ? 'general.month_ago'
                      : 'general.month_left'
                    : ended
                    ? 'general.months_ago'
                    : 'general.months_left'
                }
                months={difference('months')}
              />
            )
          ) : null}
        </div>

        <Button
          contained
          color={subscriptionColorMap[subscription]}
          size="medium"
          onClick={handleButtonClick}
        >
          <Translation keyValue={`general.actions.${subscriptionButtonTextMap[subscription]}`} />
        </Button>
      </div>
    </div>
  ) : null
}
