// core
import React, { memo } from 'react'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

import { AttributeList } from 'components/basic/AttributeList'
// components
import { getTranslation, Translation } from 'components/complex/Translation'

// styles
import * as css from './Footer.scss'

// export interface IFooterProps {}

export const Footer = memo(function Footer(/* {  }: IFooterProps */) {
  return (
    <div className={css.root}>
      <AttributeList>
        <>
          <Icon className="d-inline-block align-middle mr-1" name="help_outline" size="mini" />

          <a
            className="align-middle"
            href="mailto:support@ambitas.org "
            rel="noreferrer noopener"
            title={getTranslation('general.contact_support')}
          >
            <Translation keyValue="general.help" />
          </a>
        </>
      </AttributeList>

      <hr />
      <a href="https://www.ambitas.org" rel="noopener noreferrer" target="_blank">
        <Translation keyValue="general.developed_by_ambitas" />
      </a>
      <span>
        <br /> &copy; {new Date().getFullYear()} All rights reserved.
      </span>
    </div>
  )
})
