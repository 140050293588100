// core
import React, { lazy, memo } from 'react'

// components
import { Redirect, Route, Switch } from 'react-router-dom'
import { IRoutes } from 'routes/index'
import { LoggedRoutes } from 'routes/LoggedRoutes'

// data types
import { MenuItemFragment } from 'queries/Layout/types/MenuItemFragment'

// routes
const Dashboard = lazy(() => import('pages/Dashboard/Dashboard'))
const RedirectToProfile = lazy(() => import('pages/UserManagement/RedirectToProfile'))
const Dev = lazy(() => import('pages/Dev/Dev'))
const ButtonElements = lazy(() => import('pages/Dev/Button'))
const FormElements = lazy(() => import('pages/Dev/FormElements'))
const SpecialElements = lazy(() => import('pages/Dev/SpecialElements'))
const Chips = lazy(() => import('pages/Dev/Chips'))
const PopupMenus = lazy(() => import('pages/Dev/PopupMenus'))
const DevTabs = lazy(() => import('pages/Dev/Tabs'))
const Wysiwyg = lazy(() => import('pages/Dev/Wysiwyg'))
const CreateBeaconZone = lazy(() => import('pages/Smartcity/CreateBeaconZone'))
const BeaconZone = lazy(() => import('pages/Smartcity/BeaconZone'))
const CreateNewUser = lazy(() => import('pages/UserManagement/CreateNewUser'))
const UserProfile = lazy(() => import('pages/UserManagement/UserProfile'))
const SystemMenu = lazy(() => import('pages/SystemMenu/SystemMenu'))
const IotApp = lazy(() => import('pages/IotApps/IotApp'))
const Gift = lazy(() => import('pages/Gifts/Gift'))
const News = lazy(() => import('pages/News/NewsDetail'))
const Banners = lazy(() => import('pages/Banners/Banners'))
const Page404 = lazy(() => import('pages/Dev/Page404'))
const CreatePpePost = lazy(() => import('pages/PpePosts/CreatePpePost'))
const EditPpePost = lazy(() => import('pages/PpePosts/EditPpePost'))
const PpePost = lazy(() => import('pages/PpePosts/PpePost'))
const PpeDetailGateway = lazy(() => import('pages/PpeDetailGateway/PpeDetailGateway'))
const NewUserBell = lazy(() => import('pages/NewUserBell/NewUserBell'))
const Support = lazy(() => import('pages/Support/Support'))
const ScheduleCall = lazy(() => import('pages/ScheduleCall/ScheduleCall'))
const ReportDetail = lazy(() => import('pages/Reports/ReportDetail'))
const Orders = lazy(() => import('pages/Orders/Orders'))
const OrderDetail = lazy(() => import('pages/Orders/OrderDetail'))
const Verifications = lazy(() => import('pages/Verifications/Verifications'))
const VerificationDetail = lazy(() => import('pages/Verifications/VerificationDetail'))
const SecondStageVerifications = lazy(
  () => import('pages/SecondStageVerifications/SecondStageVerifications')
)
const SecondStageVerificationDetail = lazy(
  () => import('pages/SecondStageVerifications/SecondStageVerificationDetail')
)
const PolVerificationDetail = lazy(() => import('pages/PolVerifications/PolVerificationDetail'))
const WarehouseVerifications = lazy(
  () => import('pages/WarehouseVerifications/WarehouseVerifications')
)
const WarehouseVerificationDetail = lazy(
  () => import('pages/WarehouseVerifications/WarehouseVerificationDetail')
)
const MailPathResolver = lazy(() => import('pages/MailPathResolver/MailPathResolver'))
const CompanyDetail = lazy(() => import('pages/Companies/CompanyDetail'))
const KioskMenuItems = lazy(() => import('pages/KioskMenus/KioskMenuItems'))
const ProductDetail = lazy(() => import('pages/ProducManagement/ProductDetail'))
const ProductItems = lazy(() => import('pages/ProductItems/ProductItems'))
const ProductPrints = lazy(() => import('pages/ProductPrints/ProductPrints'))
const DeliveryManagement = lazy(() => import('pages/Delivery/DeliveryManagement'))
const DeliveryCountries = lazy(() => import('pages/Delivery/DeliveryCountries'))

const Help = () => (
  <div>
    <h1>Help</h1>
  </div>
)

export interface IRouterProps {
  /**
   * List of routes
   */
  routes: (MenuItemFragment & { subMenu: any[] })[]
}

export const Router = memo(function Router({ routes }: IRouterProps) {
  const renderRoutes = (items: (MenuItemFragment & { subMenu: any[] })[]): any => {
    return items
      .map((item) => {
        if (item.subMenu && item.subMenu.length > 0) {
          return renderRoutes(item.subMenu)
        }

        if (item.url) {
          const itemUrl = `/${item.url}`
          const route: IRoutes | undefined = LoggedRoutes.find((r) => r.path === itemUrl)

          return route ? (
            <Route
              key={itemUrl}
              exact
              path={itemUrl}
              render={(props) => {
                const Component = route.component

                // @ts-ignore
                return <Component {...props} />
              }}
            />
          ) : null
        }

        return null
      })
      .reduce((routes, item) => routes.concat(Array.isArray(item) ? item : [item]), [])
  }

  return (
    <Switch>
      {renderRoutes(routes)}

      <Route exact component={Dashboard} path="/" />
      <Route exact component={RedirectToProfile} path="/my-profile/:tab?" />
      <Route exact path="/set-widgets">
        <Redirect to="/?manageMode" />
      </Route>
      <Route exact component={PpeDetailGateway} path={['/p/:id', '/d/:id', '/u/:id']} />
      <Route exact component={CreateNewUser} path="/new-user" />
      <Route exact component={UserProfile} path="/user/:slug" />
      <Route exact component={Gift} path="/gift/:id" />
      <Route exact component={CreateBeaconZone} path="/new-beacon-zone" />
      <Route exact component={BeaconZone} path="/beacon-zone/:id" />
      <Route exact component={IotApp} path="/iot-application/:id" />
      <Route exact component={SystemMenu} path="/menu" />
      <Route exact component={Help} path="/help" />
      <Route exact component={Dev} path="/dev/dev" />
      <Route exact component={ButtonElements} path="/dev/button" />
      <Route exact component={FormElements} path="/dev/form-elements" />
      <Route exact component={SpecialElements} path="/dev/special-elements" />
      <Route exact component={Chips} path="/dev/chips" />
      <Route exact component={PopupMenus} path="/dev/popup-menus" />
      <Route exact component={DevTabs} path="/dev/tabs" />
      <Route exact component={Wysiwyg} path="/dev/wysiwyg" />
      <Route exact component={News} path="/news/:id" />
      <Route exact component={Banners} path="/banners" />
      <Route exact component={Page404} path="/dev/page404" />
      <Route exact component={CreatePpePost} path="/new-deal" />
      <Route exact component={EditPpePost} path="/edit-deal/:id" />
      <Route exact component={PpePost} path="/deal/:id" />
      <Route exact component={NewUserBell} path="/ppe-stats" />
      <Route exact component={Support} path="/feedback" />
      <Route exact component={Support} path="/support" />
      <Route exact component={ScheduleCall} path="/schedule-call" />
      <Route exact component={Verifications} path="/verifications" />
      <Route exact component={VerificationDetail} path="/verification/:id" />
      <Route exact component={SecondStageVerifications} path="/second-stage-verifications" />
      <Route
        exact
        component={SecondStageVerificationDetail}
        path="/second-stage-verification/:id"
      />
      <Route exact component={PolVerificationDetail} path="/pol-verification/:id" />
      <Route exact component={ReportDetail} path="/report/:id" />
      <Route exact component={WarehouseVerifications} path="/warehouse-verifications" />
      <Route exact component={WarehouseVerificationDetail} path="/warehouse-verification/:id" />
      <Route exact component={MailPathResolver} path="/mail/:hash" />
      <Route exact component={CompanyDetail} path="/company/:slug" />
      <Route exact component={KioskMenuItems} path="/kiosk-menu/:id" />
      <Route exact component={ProductItems} path="/product/:productId/product-items" />
      <Route exact component={ProductPrints} path="/product/:productId/product-prints" />
      <Route exact component={ProductDetail} path="/product/:id" />
      <Route exact component={Orders} path="/orders" />
      <Route exact component={OrderDetail} path="/order/:id" />
      <Route exact component={DeliveryManagement} path="/deliveries" />
      <Route exact component={DeliveryCountries} path="/delivery/:id" />
    </Switch>
  )
})
