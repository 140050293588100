// core
import React, { useCallback } from 'react'

// libraries
import classnames from 'classnames'

// components
import { Backdrop } from 'components/basic/Backdrop'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './SidePanel.scss'

export interface ISidePanelProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether the SidePanel is active/visible/opened or not.
   */
  open: boolean
  /**
   * On which side of the screen should the menu roll out from,
   * if none set, default 'left' is being used.
   */
  side?: 'left' | 'right'
  /**
   * The width of the SidePanel Wrapper in px (200 by default).
   */
  width?: number
  /**
   * Toggles the visibility of the side menu.
   */
  onRequestClose?: () => void
  /**
   * Pass children to sidemenu
   */
  children?: any
  /**
   * Additional className(s) for wrapper custom styling.
   */
  className?: string
}

export function SidePanel({
  inverted,
  open,
  side = 'left',
  width = 200,
  onRequestClose,
  children,
  className,
}: ISidePanelProps) {
  const styles = {
    marginLeft: side === 'left' ? (open ? '0px' : `${-width}px`) : 'auto',
    marginRight: side === 'right' ? (open ? '0px' : `${-width}px`) : 'auto',
    width: `${width}px`,
  }

  const handleClick = useCallback(() => runCallback(onRequestClose), [onRequestClose])

  return (
    <>
      <Backdrop className={css.backdrop} visible={open} zIndex={200} onClick={handleClick} />

      <aside
        className={classnames(
          css.root,
          side === 'left' ? css.left : css.right,
          { [css.open]: open, [css.inverted]: inverted },
          className
        )}
        style={styles}
      >
        {children}
      </aside>
    </>
  )
}
