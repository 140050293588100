import { InMemoryCache, InMemoryCacheConfig } from '@apollo/client'
import { relayStylePagination } from '@apollo/client/utilities'

export const cacheConfig: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        banners: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'activeSince',
          'activeUntil',
        ]),

        beaconZones: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'status',
          'companyId',
          'defaultLanguage',
          'estimoteDeviceIdentifier',
        ]),

        deliveries: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'alias',
          'production',
        ]),

        deliveryCountries: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'deliveryId',
          'production',
        ]),

        gifts: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'activeSince',
          'activeUntil',
        ]),

        iotApps: relayStylePagination(['orderBy', 'orderByDirection']),

        loggedInUser: (existingData, { toReference, readField }) =>
          existingData ||
          toReference({
            __typename: 'SysUser',
            id: readField('loggedInUserId', toReference({ __typename: 'Query' })),
          }),

        loggedInUserId: (existingData) => existingData || null,

        newsArticles: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'publishedAt',
        ]),

        notifications: relayStylePagination(['targetSysUserId', 'isSeen']),

        orderItems: relayStylePagination(['orderBy', 'orderByDirection', 'search', 'orderId']),

        orders: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'createdAt',
          'stripeStatus',
        ]),

        polVerifications: relayStylePagination(['orderBy', 'orderByDirection', 'status']),

        ppePosts: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'allowUnpublished',
          'type',
          'ownerId',
          'isFavorited',
          'state',
          'expirationDate',
          'hasImage',
          'verifiedOwners',
          'verifiedOwnersType',
          'categoryIds',
          'createdAtFrom',
          'createdAtTo',
          'watchdog',
        ]),

        secondStageVerifications: relayStylePagination(['orderBy', 'orderByDirection', 'status']),

        selectedLanguageId: (existingData) =>
          existingData || localStorage.getItem('languageId') || '1',

        settings: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysSettingsOption', alias: args ? args.alias : '' }),

        sysLanguageKeys: relayStylePagination(['orderBy', 'orderByDirection', 'search', 'tags']),

        sysUserVerifications: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'status',
          'documentType',
        ]),

        sysUsers: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'search',
          'statuses',
          'sysAccessGroups',
          'supervisor',
          'createdAt',
          'lastLoginAt',
        ]),

        translation: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysLanguageKey', languageKey: args ? args.key : '' }),

        violationReports: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'reportingCategoryId',
        ]),

        vouchers: relayStylePagination(['orderBy', 'orderByDirection', 'search']),

        warehouseVerifications: relayStylePagination([
          'orderBy',
          'orderByDirection',
          'status',
          'verificationType',
        ]),
      },
    },
    // Subscription: {
    //   fields: {
    //     messageRead: {
    //       merge: (_, incoming) => {
    //         return incoming
    //       },
    //     },
    //   },
    // },
    SysLanguageKey: {
      keyFields: ['languageKey'],
    },
    SysLanguageKeySysLanguage: {
      keyFields: false,
    },
    SysSettingsOption: {
      fields: {
        property: (existingData, { args, toReference }) =>
          existingData ||
          toReference({ __typename: 'SysSettingsOptionItem', alias: args ? args.alias : '' }),
      },
      keyFields: ['alias'],
    },
    SysSettingsOptionItem: {
      keyFields: ['alias'],
    },
  },
}

export const cache = new InMemoryCache(cacheConfig)
