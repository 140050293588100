// core
import React, {
  Children,
  cloneElement,
  MouseEvent,
  ReactElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappComponentProps } from 'components'
import { ExpandableBox } from 'components/basic/ExpandableBox'
import { Stick } from 'components/basic/Stick/Stick'
import { Button } from 'components/complex/Button'
import { List, ListOptionValueType } from 'components/complex/List/List'
import { IListOptionProps } from 'components/complex/List/ListOption'
import { ListWithSearch } from 'components/complex/ListWithSearch/ListWithSearch'
import { Popover } from 'components/complex/Popover'
import { ITranslationProps } from 'components/complex/Translation'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './PopupMenu.scss'

export interface PopupMenuOption extends IPopupMenuListType {
  onClick?: (value: ListOptionValueType, close: () => void) => void
}

export interface IPopupMenuListType extends Omit<IListOptionProps, 'onClick' | 'options'> {
  onClick?: (value: ListOptionValueType, close: () => void) => any
  options?: PopupMenuOption[]
}

export interface IPopupMenuProps extends IDotappComponentProps {
  /**
   * Control popup menu from outsided
   */
  open?: boolean
  /**
   * Callback to run on popup menu close
   */
  onPopupMenuOpen?: () => void
  /**
   * Callback to run on popup menu close
   */
  onPopupMenuClose?: () => void
  /**
   * Invert colors
   */
  inverted?: boolean
  /**
   * Disabled popup
   */
  disabled?: boolean
  /**
   * If true, menu will containe sesrch input which filter the items
   */
  searchable?: boolean
  /**
   * From which side to open menu
   */
  side?: 'left' | 'right'
  /**
   * Menu title
   */
  title?: ReactElement<ITranslationProps> | string
  /**
   * Array of child options to be rendered.
   */
  options: IPopupMenuListType[]
  /**
   * Callback to run on option click
   */
  onOptionClick?: (value: string, close: () => void) => any
  /**
   * Children component - usually Button
   */
  children?: ReactNode
  /**
   * Additional content rendered after menu
   */
  additionalContent?: ReactNode
  /**
   * Width on popover
   */
  width?: number
}

export function PopupMenu({
  open: openProp,
  onPopupMenuOpen,
  onPopupMenuClose,
  inverted,
  disabled,
  searchable,
  side = 'left',
  title,
  options,
  onOptionClick,
  children,
  className,
  additionalContent,
  width = 230,
  classes = {},
}: IPopupMenuProps) {
  const [open, setOpen] = useState<boolean>(!!openProp)
  const [expandedOption, setExpandedOption] = useState<string | null>(null)

  useEffect(() => {
    setOpen(!!openProp)
  }, [!!openProp])

  const handleClick = () => {
    if (!disabled) {
      if (typeof onPopupMenuOpen === 'function') {
        onPopupMenuOpen()
      } else {
        setOpen(true)
      }
    }
  }

  const handleBlur = useCallback(
    (event?: MouseEvent) => {
      if (event) {
        event.preventDefault()
        event.stopPropagation()
      }

      if (typeof onPopupMenuClose === 'function') {
        onPopupMenuClose()
      } else {
        setOpen(false)
      }
    },
    [onPopupMenuClose, setOpen]
  )

  const handleOptionClick = (option: IPopupMenuListType) => (o: ListOptionValueType) => {
    if (option.options && option.options.length > 0) {
      setExpandedOption((expandedOption) => (expandedOption === option.value ? null : option.value))
    }
    runCallback(option.onClick, o, () => setOpen(false))
    runCallback(onOptionClick, option.value, () => setOpen(false))
  }

  const assignOnClickEvent = (options: IPopupMenuListType[]): IListOptionProps[] => {
    return options.map((option) => {
      const hasSubOptions = option.options && option.options.length > 0
      const isExpanded = expandedOption === option.value

      return {
        contentBellow: hasSubOptions && (
          <ExpandableBox expanded={isExpanded}>
            <List
              hoverEffect="simple"
              inverted={inverted}
              options={assignOnClickEvent(option.options || [])}
              size="small"
            />
          </ExpandableBox>
        ),
        ...option,
        disabled: option.disabled,
        iconRight: hasSubOptions
          ? isExpanded
            ? 'remove'
            : 'keyboard_arrow_down'
          : option.iconRight,
        onClick:
          onOptionClick || option.onClick || hasSubOptions ? handleOptionClick(option) : undefined,
      }
    })
  }

  const optionsWithEvent = useMemo(() => (options ? assignOnClickEvent(options) : []), [
    options,
    expandedOption,
    setExpandedOption,
    onOptionClick,
  ])

  return (
    <div className={classnames(css.root, className)} onClick={handleClick}>
      {Children.map(children, (child: any) => cloneElement(child, { onClick: handleClick }))}

      {!disabled ? (
        <Popover
          className={classes.popover}
          classes={classes}
          inverted={inverted}
          open={open}
          side={side}
          width={width}
          onBlur={handleBlur}
        >
          <div className={classnames(css.menuWrap, { [css.inverted]: inverted }, css[side])}>
            {title ? (
              <div className={css.header}>
                {side === 'left' && <Stick className={css.stick} inverted={inverted} />}

                <h4>{title}</h4>

                {side === 'right' && <Stick className={css.stick} inverted={inverted} />}

                <Button
                  contained
                  className={css.closeIcon}
                  color="transparent"
                  iconLeft="close"
                  size="small"
                  variant="icon"
                  onClick={handleBlur}
                />
              </div>
            ) : null}

            {searchable ? (
              <ListWithSearch
                className={css.search}
                data={optionsWithEvent}
                searchProperties={['title']}
              >
                {(filteredOptions) => (
                  <div className={css.items}>
                    <List
                      hoverEffect="simple"
                      inverted={inverted}
                      options={filteredOptions}
                      size="small"
                    />
                  </div>
                )}
              </ListWithSearch>
            ) : (
              <div className={css.items}>
                <List
                  hoverEffect="simple"
                  inverted={inverted}
                  options={optionsWithEvent}
                  size="small"
                />
              </div>
            )}
          </div>

          {additionalContent}
        </Popover>
      ) : null}
    </div>
  )
}
