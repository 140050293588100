// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SidePanel_backdrop_1Iad_{z-index:100}.SidePanel_root_ExU4t{background-color:#fff;overflow-y:auto;position:fixed;top:0;bottom:0;left:0;display:flex;flex-direction:column;transition:margin-left .3s ease,margin-right .3s ease;max-width:100%;z-index:200}.SidePanel_root_ExU4t.SidePanel_left_2CujD{left:0;border-radius:0 8px 8px 0}.SidePanel_root_ExU4t.SidePanel_left_2CujD.SidePanel_open_12WbF{margin-left:0}.SidePanel_root_ExU4t.SidePanel_right_3ElZB{right:0;border-radius:8px 0 0 8px}.SidePanel_root_ExU4t.SidePanel_right_3ElZB.SidePanel_open_12WbF{margin-right:0}.SidePanel_root_ExU4t.SidePanel_inverted_2zia_{background-color:#2b2b2b}.SidePanel_sidePanelHeader_juq5M{display:flex;align-items:center;height:50px;margin:10px 15px 10px 30px;color:#bfbfbf;flex-shrink:0}.SidePanel_sidePanelHeader_juq5M h4{flex:1;font-size:16px;font-weight:500;line-height:20px;color:#2b2b2b;margin:0}.SidePanel_sidePanelHeader_juq5M .SidePanel_closeButton_13i6_{margin-right:10px}\n", ""]);
// Exports
exports.locals = {
	"backdrop": "SidePanel_backdrop_1Iad_",
	"root": "SidePanel_root_ExU4t",
	"left": "SidePanel_left_2CujD",
	"open": "SidePanel_open_12WbF",
	"right": "SidePanel_right_3ElZB",
	"inverted": "SidePanel_inverted_2zia_",
	"sidePanelHeader": "SidePanel_sidePanelHeader_juq5M",
	"closeButton": "SidePanel_closeButton_13i6_"
};
module.exports = exports;
