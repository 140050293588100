// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Chip_root_3LlL3{display:inline-block;vertical-align:middle}.Chip_root_3LlL3.Chip_small_3Ue_e{padding:0 6px;width:auto}.Chip_root_3LlL3.Chip_medium_34eoy{padding:0 10px;width:auto}.Chip_root_3LlL3.Chip_large_1aOpK{padding:0 14px;width:auto}.Chip_root_3LlL3.Chip_lightVariant_2LMgm.Chip_neutral_37eyX{background-color:rgba(166,166,166,0.15);color:#a6a6a6}.Chip_root_3LlL3.Chip_lightVariant_2LMgm.Chip_primary_GqGsz{background-color:rgba(0,145,255,0.15);color:#0091ff}.Chip_root_3LlL3.Chip_lightVariant_2LMgm.Chip_success_2TNRQ{background-color:rgba(40,202,66,0.15);color:#28ca42}.Chip_root_3LlL3.Chip_lightVariant_2LMgm.Chip_warning_21eO4{background-color:rgba(255,189,46,0.15);color:#ffbd2e}.Chip_root_3LlL3.Chip_lightVariant_2LMgm.Chip_danger_3314O{background-color:rgba(255,96,89,0.15);color:#ff6059}\n", ""]);
// Exports
exports.locals = {
	"root": "Chip_root_3LlL3",
	"small": "Chip_small_3Ue_e",
	"medium": "Chip_medium_34eoy",
	"large": "Chip_large_1aOpK",
	"lightVariant": "Chip_lightVariant_2LMgm",
	"neutral": "Chip_neutral_37eyX",
	"primary": "Chip_primary_GqGsz",
	"success": "Chip_success_2TNRQ",
	"warning": "Chip_warning_21eO4",
	"danger": "Chip_danger_3314O"
};
module.exports = exports;
