// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Image_image_1o536{width:100%;object-fit:cover;object-position:center}.Image_image_1o536.Image_original_kXoMC{border-radius:8px}.Image_image_1o536.Image_square_xk4mS{border-radius:8px}.Image_image_1o536.Image_circle_B4Uea{border-radius:50%}.Image_image_1o536.Image_placeholder_1Krev{object-fit:contain;background-color:#c4c4c4}\n", ""]);
// Exports
exports.locals = {
	"image": "Image_image_1o536",
	"original": "Image_original_kXoMC",
	"square": "Image_square_xk4mS",
	"circle": "Image_circle_B4Uea",
	"placeholder": "Image_placeholder_1Krev"
};
module.exports = exports;
