// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Link_root_1Hphj.Link_primary_3QcGA{color:#0091ff}.Link_root_1Hphj.Link_white_1hxao{color:#ffffff}.Link_root_1Hphj.Link_light_ZIPHz{color:#b4b4b4}.Link_root_1Hphj.Link_neutral_kBBdt{color:#a6a6a6}.Link_root_1Hphj.Link_success_2cWHO{color:#28ca42}.Link_root_1Hphj.Link_warning_3_IEa{color:#ffbd2e}.Link_root_1Hphj.Link_danger_1Grc2{color:#ff6059}\n", ""]);
// Exports
exports.locals = {
	"root": "Link_root_1Hphj",
	"primary": "Link_primary_3QcGA",
	"white": "Link_white_1hxao",
	"light": "Link_light_ZIPHz",
	"neutral": "Link_neutral_kBBdt",
	"success": "Link_success_2cWHO",
	"warning": "Link_warning_3_IEa",
	"danger": "Link_danger_1Grc2"
};
module.exports = exports;
