// core
import React, { useCallback } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { IDotappWrapperComponentProps } from 'components'
import { Button } from 'components/complex/Button'

// utils
import { isFunction, runCallback } from 'utils/functions'

// styles
import * as css from './Chip.scss'

export interface IChipProps extends IDotappWrapperComponentProps {
  /**
   * Chip size
   */
  size?: 'small' | 'normal' | 'large' | 'extraLarge'
  /**
   * Chip color
   */
  color?:
    | 'default'
    | 'white'
    | 'neutral'
    | 'dark'
    | 'primary'
    | 'black'
    | 'success'
    | 'warning'
    | 'danger'
  /**
   * The icon to be shown on the left side
   */
  icon?: string | null
  /**
   * Callback to run when user click close button.
   * There will be close button if this function is provided
   */
  onRequestClose?: () => any
  /**
   * colors are contained
   */
  contained?: boolean
}

export const Chip = ({
  size = 'normal',
  color = 'default',
  icon,
  onRequestClose,
  children,
  className,
  classes = {},
  contained = true,
  ...passingProps
}: IChipProps) => {
  const handleRequestClose = useCallback(() => {
    runCallback(onRequestClose)
  }, [onRequestClose])

  return (
    <div
      className={classnames(
        css.root,
        css[size],
        color && color !== 'default' ? css[color] : '',
        className,
        { [css.contained]: contained }
      )}
      {...passingProps}
    >
      {icon && <Icon className={classnames(css.icon, classes.icon)} name={icon} />}

      <div className={classnames(css.content, classes.content)}>{children}</div>

      {isFunction(onRequestClose) && (
        <Button
          contained
          className={classnames(css.closeButton, classes.closeButton)}
          color="transparent"
          iconLeft="close"
          size="small"
          variant="icon"
          onClick={handleRequestClose}
        />
      )}
    </div>
  )
}
