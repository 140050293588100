// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Popover_refDiv_2Ae9D{position:absolute}.Popover_refDiv_2Ae9D.Popover_left_e8Key{left:0}.Popover_refDiv_2Ae9D.Popover_right_mvVuF{right:0}.Popover_refDiv_2Ae9D.Popover_topToBottom_1x0Je{top:0}.Popover_refDiv_2Ae9D.Popover_bottomToTop_1PhV3{bottom:0}.Popover_root_3zeQb{transition:width .3s ease-in;width:0;position:relative}.Popover_root_3zeQb .Popover_paper_uwnwT{overflow:hidden;border-radius:8px;width:100%}.Popover_root_3zeQb .Popover_paper_uwnwT .Popover_content_D9WzJ{height:100%;max-height:500px;overflow-y:auto}.Popover_root_3zeQb .Popover_paper_uwnwT.Popover_bottomToTop_1PhV3{position:absolute;bottom:0;left:0;right:0}.Popover_root_3zeQb.Popover_open_2PnMd{transition:width .3s ease-out}.Popover_root_3zeQb.Popover_left_e8Key{float:left}.Popover_root_3zeQb.Popover_left_e8Key .Popover_paper_uwnwT{border-top-left-radius:4px}.Popover_root_3zeQb.Popover_right_mvVuF{float:right}.Popover_root_3zeQb.Popover_right_mvVuF .Popover_paper_uwnwT{border-top-right-radius:4px}@media (max-width: 575.98px){.Popover_root_3zeQb{width:100% !important;position:fixed;bottom:0 !important;left:0 !important;right:0 !important;transition:none}.Popover_root_3zeQb.Popover_left_e8Key .Popover_paper_uwnwT,.Popover_root_3zeQb.Popover_right_mvVuF .Popover_paper_uwnwT{border-radius:8px 8px 0 0}.Popover_root_3zeQb .Popover_content_D9WzJ{width:100% !important;max-width:100%}}\n", ""]);
// Exports
exports.locals = {
	"refDiv": "Popover_refDiv_2Ae9D",
	"left": "Popover_left_e8Key",
	"right": "Popover_right_mvVuF",
	"topToBottom": "Popover_topToBottom_1x0Je",
	"bottomToTop": "Popover_bottomToTop_1PhV3",
	"root": "Popover_root_3zeQb",
	"paper": "Popover_paper_uwnwT",
	"content": "Popover_content_D9WzJ",
	"open": "Popover_open_2PnMd"
};
module.exports = exports;
