// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Coin_root_3BMbm{text-align:center;color:white;overflow:hidden;flex-shrink:0}.Coin_root_3BMbm.Coin_small_1rzF9{line-height:12px;height:12px;width:12px;border-radius:6px;font-size:9px}.Coin_root_3BMbm.Coin_medium_1jL_U{line-height:20px;height:20px;width:20px;border-radius:10px;font-size:10px}.Coin_root_3BMbm.Coin_large_CV0tl{line-height:24px;height:24px;width:24px;border-radius:12px;font-size:12px}.Coin_root_3BMbm.Coin_transparent_2FSL1{background-color:none;color:#656565}.Coin_root_3BMbm.Coin_neutral_31yoD{background-color:#a6a6a6}.Coin_root_3BMbm.Coin_primary_3lqai{background-color:#0091ff}.Coin_root_3BMbm.Coin_success_39850{background-color:#28ca42}.Coin_root_3BMbm.Coin_warning_vuJk6{background-color:#ffbd2e}.Coin_root_3BMbm.Coin_danger_3d9hR{background-color:#ff6059}\n", ""]);
// Exports
exports.locals = {
	"root": "Coin_root_3BMbm",
	"small": "Coin_small_1rzF9",
	"medium": "Coin_medium_1jL_U",
	"large": "Coin_large_CV0tl",
	"transparent": "Coin_transparent_2FSL1",
	"neutral": "Coin_neutral_31yoD",
	"primary": "Coin_primary_3lqai",
	"success": "Coin_success_39850",
	"warning": "Coin_warning_vuJk6",
	"danger": "Coin_danger_3d9hR"
};
module.exports = exports;
