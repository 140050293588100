// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".AttributeList_root_1hV1Q{display:inline-flex;align-items:center;max-width:100%;min-width:0;overflow:hidden;text-overflow:ellipsis;white-space:nowrap;align-items:center;color:#a6a6a6;line-height:16px;font-style:italic}.AttributeList_root_1hV1Q.AttributeList_default_hNotp{border:none}.AttributeList_root_1hV1Q.AttributeList_bordered_38rrz{border:1px solid #e6e6e6;border-radius:2px;padding:5px 10px}.AttributeList_root_1hV1Q.AttributeList_small_26ikz{font-size:10px}.AttributeList_root_1hV1Q.AttributeList_medium_2Ug3_{font-size:12px}.AttributeList_root_1hV1Q.AttributeList_large_1h5j2{font-size:14px}.AttributeList_root_1hV1Q .AttributeList_child_1E_YM{vertical-align:middle}.AttributeList_root_1hV1Q .AttributeList_child_1E_YM:not(:first-child){margin-left:4px}.AttributeList_root_1hV1Q .AttributeList_child_1E_YM:not(:first-child):before{content:'·';display:inline;color:#a6a6a6;margin-right:4px}\n", ""]);
// Exports
exports.locals = {
	"root": "AttributeList_root_1hV1Q",
	"default": "AttributeList_default_hNotp",
	"bordered": "AttributeList_bordered_38rrz",
	"small": "AttributeList_small_26ikz",
	"medium": "AttributeList_medium_2Ug3_",
	"large": "AttributeList_large_1h5j2",
	"child": "AttributeList_child_1E_YM"
};
module.exports = exports;
