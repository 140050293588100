// core
import React, {
  createContext,
  ReactElement,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useReducer,
} from 'react'

// componets
import { IDotappWrapperComponentProps } from 'components'

// partials
import { Toast } from './Partials/Toast'

// styles
import * as css from './Toast.scss'

export type ReducerActionType = {
  type: 'add' | 'remove' | 'clearAll' | 'show' | 'hide'
  toast?: ToastProps
  id?: string
}

function reducer(toasts: ToastProps[], action: ReducerActionType) {
  switch (action.type) {
    case 'add':
      return [...toasts, { ...action.toast, status: 'hide' }]

    case 'remove': {
      const copiedToasts = [...toasts]
      const listItemIndex = copiedToasts.findIndex((e: any) => e.id === action.id)
      copiedToasts.splice(listItemIndex, 1)
      return copiedToasts
    }
    case 'clearAll':
      return []
    case 'show': {
      const index = toasts.findIndex(({ id }) => id === action.id)

      if (index > -1) {
        const newToasts = [...toasts]
        newToasts[index].status = 'show'
        return newToasts
      }

      return toasts
    }
    case 'hide': {
      const index = toasts.findIndex(({ id }) => id === action.id)

      if (index > -1) {
        const newToasts = [...toasts]
        newToasts[index].status = 'hide'
        return newToasts
      }

      return toasts
    }
    default:
      throw new Error('Wrong type')
  }
}

export const ToastContext = createContext<{
  show: (toast: ToastProps) => void
  remove: (id: string) => void
  toasts: (ToastProps | undefined)[]
}>({
  remove: () => {
    // do nothing
  },
  show: () => {
    // do nothing
  },
  toasts: [],
})

export const ToastContextProvider = ({ children }: IDotappWrapperComponentProps) => {
  const [toasts, dispatch] = useReducer(reducer, [])
  const timeouts: NodeJS.Timeout[] = []

  const remove = useCallback((id: string) => {
    dispatch({ id, type: 'hide' })

    setTimeout(() => {
      dispatch({ id, type: 'remove' })
    }, 1000)
  }, [])

  const show = useCallback(
    (toast: ToastProps) => {
      dispatch({ toast, type: 'add' })

      // let css animation to run
      timeouts.push(
        setTimeout(() => {
          dispatch({ id: toast.id, type: 'show' })
        }, 0)
      )

      // autohide
      timeouts.push(
        setTimeout(() => {
          remove(toast.id)
        }, 5000)
      )
    },
    [remove]
  )

  // clear all timeouts on unmount
  useEffect(() => {
    return () => {
      timeouts.forEach((timeout) => clearTimeout(timeout))
    }
  }, [])

  return (
    <ToastContext.Provider
      value={{
        remove,
        show,
        // @ts-ignore
        toasts,
      }}
    >
      {children}
    </ToastContext.Provider>
  )
}

export interface ToastProps {
  /**
   * color of toast
   */
  color?: 'transparent' | 'success' | 'danger' | 'warning' | 'primary'
  /**
   * title of toast
   */
  title: ReactNode | string
  /**
   *  text to display under title
   */
  description?: string | ReactNode
  /**
   *  identificator for toast
   */
  id: string
  /**
   *  which icon should be shown
   */
  icon: string
  /**
   *  color of icon
   */
  iconColor?: 'success' | 'danger' | 'warning' | 'primary' | 'white' | 'txt'
  /**
   * automatic prop status for animation purposes
   */
  status?: 'hide' | 'show'
  /**
   * if toast should be in center
   */
  center?: boolean
}

export interface IToastsProps {
  /**
   * toasts that should be displayed
   */
  toasts: ToastProps[]
}

export const ToastRenderer = () => {
  const { toasts, remove } = useContext(ToastContext)
  const toastsCopy = [...toasts]
  const step = 60
  let bottom = 20
  const toastList: ReactElement<IToastsProps>[] = []

  toastsCopy.reverse()
  toastsCopy.forEach((toast: ToastProps) => {
    toastList.push(
      <Toast
        key={toast.id}
        className={css.toast}
        style={{
          bottom: toast.center ? 'calc(50vh - 100px)' : toast.status === 'show' ? bottom : -step,
        }}
        onClose={remove}
        {...toast}
      />
    )

    if (toast.status === 'show' && !toast.center) {
      bottom += step
    }
  })

  return <div className={css.toastContainer}>{toastList}</div>
}
