// core
import React from 'react'

// libraries
import classnames from 'classnames'

// data types
import {
  SecondStageVerificationStatus,
  SysUserVerificationDocumentType,
  VerificationStatus,
} from 'generated/global-types'

// components
import { IDotappComponentProps } from 'components'
import { Link } from 'components/basic/Link/Link'
import { SubscriptionStatus } from 'components/basic/SubscriptionStatus/SubscriptionStatus'
import { Avatar } from 'components/complex/Avatar'
import { IAvatarWithProgressProps } from 'components/complex/AvatarWithProgress'

// styles
import * as css from './User.scss'

export interface IUserProps
  extends Omit<IAvatarWithProgressProps, 'size' | 'percentage'>,
    IDotappComponentProps {
  /**
   * verification document type
   */
  documentType?: SysUserVerificationDocumentType | null
  /**
   * size of component
   */
  size?: 'small' | 'medium' | 'large' | 'huge'
  /**
   * User's email
   */
  email?: string
  /**
   * full name of user
   */
  fullName?: string | null
  /**
   * sysAccessGroup id to find out if it is admin
   */
  sysAccessGroupId?: string
  /**
   * sysAccessGroup title the user posseses
   */
  sysAccessGroupTitle?: string
  /**
   * total count of ratings
   */
  totalCount?: number | null
  /**
   * profile picture
   */
  profilePicture?: string | null
  /**
   * verification status of user
   */
  verificationStatus: VerificationStatus | null
  /**
   * verification status of user
   */
  secondStageVerificationStatus: SecondStageVerificationStatus | null
  /**
   * on which side should be popup opened
   */
  side?: 'left' | 'right'
  /**
   * slug of displayed user
   */
  slug?: string | null
  /**
   * If is user's name clickable
   */
  nameIsClickable?: boolean
  /**
   * Callback to happen on badge click
   */
  onBadgeClick?: () => void
}

export const User = ({
  size = 'medium',
  profilePicture,
  email,
  fullName,
  totalCount,
  sysAccessGroupId,
  sysAccessGroupTitle,
  nameIsClickable = false,
  documentType,
  side = 'right',
  verificationStatus,
  className,
  slug,
  secondStageVerificationStatus,
  onBadgeClick,
  ...passingProps
}: IUserProps) => {
  return (
    <div className={classnames(css.root, css[size], className)}>
      <Avatar
        {...passingProps}
        className="flex-shrink-0"
        fancyIcon="noUser"
        size={size}
        url={profilePicture}
      />

      <div className={classnames(css.rightSide, { [css.withBadge]: onBadgeClick })}>
        <div className={css.titleWrap}>
          {nameIsClickable ? (
            <Link className={css.title} to={`/user/${slug}`}>
              {fullName}
            </Link>
          ) : (
            <div className={css.title}>{fullName}</div>
          )}
        </div>

        <div className={css.wEmail}>{email}</div>

        <div className={css.middleDiv}>
          <SubscriptionStatus
            accessGroupId={sysAccessGroupId}
            accessGroupTitle={sysAccessGroupTitle}
            className="mr-2"
            size={size === 'large' ? 'normal' : 'small'}
            status={null}
          />
        </div>
      </div>
    </div>
  )
}
