// core
import { useContext } from 'react'

// partials
import { ModalContext } from './Modal'

/**
 * Hook to user modal container context
 */
export const useModalContainer = () => {
  const container = useContext(ModalContext)

  const { width, height } = container?.parentElement
    ? container.getBoundingClientRect()
    : { height: Infinity, width: Infinity }

  return { container, height, width }
}
