import React from 'react'

import moment from 'moment-timezone'

export interface IDateProps {
  /**
   * ClassName
   */
  className?: string
  /**
   * Custom moment-compatible formatting string
   */
  customFormat?: string
  /**
   * boolean if should include time format
   */
  includeTime?: boolean
  /**
   * Date value to format
   */
  value: Date | number
}

export const formatDate = (
  value: Date | number,
  options: {
    includeTime?: boolean
  },
  customFormat?: string
): string => {
  let format = 'L'
  if (options.includeTime) {
    format = 'L LT'
  }

  moment.locale(window?.navigator?.language || 'en')

  return moment.tz(value, moment.tz.guess()).format(customFormat || format)
}

export function DateString({ className, customFormat, includeTime, value }: IDateProps) {
  const date = formatDate(value, { includeTime }, customFormat)

  return className ? <span className={className}>{date}</span> : <>{date}</>
}
