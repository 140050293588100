// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".InfoPopup_root_ufoDe{color:#bfbfbf}.InfoPopup_root_ufoDe.InfoPopup_disabled_YXRY1{color:#e6e6e6}.InfoPopup_root_ufoDe.InfoPopup_disabled_YXRY1.InfoPopup_inverted_11RfT{color:#656565}.InfoPopup_icon_3cG7L.InfoPopup_mini_3nd1M{font-size:14px !important}.InfoPopup_icon_3cG7L.InfoPopup_small_xfWHs{font-size:18px !important}.InfoPopup_icon_3cG7L.InfoPopup_medium_3WMQ3{font-size:20px !important}.InfoPopup_icon_3cG7L.InfoPopup_large_Ga5ae{font-size:24px !important}.InfoPopup_invertedContent_2PHOs{background-color:black !important}\n", ""]);
// Exports
exports.locals = {
	"root": "InfoPopup_root_ufoDe",
	"disabled": "InfoPopup_disabled_YXRY1",
	"inverted": "InfoPopup_inverted_11RfT",
	"icon": "InfoPopup_icon_3cG7L",
	"mini": "InfoPopup_mini_3nd1M",
	"small": "InfoPopup_small_xfWHs",
	"medium": "InfoPopup_medium_3WMQ3",
	"large": "InfoPopup_large_Ga5ae",
	"invertedContent": "InfoPopup_invertedContent_2PHOs"
};
module.exports = exports;
