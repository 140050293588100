// core
import React from 'react'

// libraries
import { Redirect } from 'react-router'
import { Route, Switch, useLocation } from 'react-router-dom'
import { IRoutes } from 'routes'
// routes
import { AuthRoutes } from 'routes/AuthRoutes'

// layouts
import { Content } from './Content/Content'

// styles
import * as css from './Layout.scss'

export function LayoutAuth() {
  const { pathname, search } = useLocation()

  const renderRoutes = (routes: IRoutes[]) => {
    return routes.map((route) =>
      route.path && route.component ? (
        <Route key={route.path} exact component={route.component} path={route.path} />
      ) : null
    )
  }

  const routes: any[] = renderRoutes(AuthRoutes)

  return (
    <div className={css.authLayout}>
      <Content>
        <Switch>
          {routes}

          <Route
            key="redirect"
            exact={false}
            path="/"
            render={() => (
              <Redirect
                to={{
                  pathname: '/login',
                  state: { prevPath: pathname + search },
                }}
              />
            )}
          />
        </Switch>
      </Content>
    </div>
  )
}
