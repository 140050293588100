// core
import React, { ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// components
import { ILoadingProps, Loading } from 'components/basic/Loading/Loading'

import { Callout } from '../Callout/Callout'
import { Translation } from '../Translation'

// styles
import * as css from './LoadingWrapper.scss'

export interface ILoadingWrapperProps extends ILoadingProps {
  /**
   * Whether display loading indicator instead of children
   */
  loading: boolean
  /**
   * Children to render if not loading
   */
  children?: ReactNode | null
  /**
   * Has error?
   */
  error?: boolean | string
}

export const LoadingWrapper = ({
  error,
  loading,
  children = null,
  className,
  ...passingProps
}: ILoadingWrapperProps) => {
  return (
    <>
      {loading ? (
        <div className={classnames(css.root, className)}>
          <Loading {...passingProps} />
        </div>
      ) : error ? (
        <Callout
          icon="warning"
          title={
            typeof error === 'string' ? (
              error
            ) : (
              <Translation keyValue="general.error_loading_data" />
            )
          }
        />
      ) : (
        children
      )}
    </>
  )
}
