// core
import React, { forwardRef, InputHTMLAttributes, ReactElement, Ref } from 'react'

// libraries
import classnames from 'classnames'

// formik
import { useField, useFormikContext } from 'formik'

// components
import { ITranslationProps } from 'components/complex/Translation'
import {
  FormFieldBase,
  IFormFieldBaseProps,
  useFocus,
  useId,
} from 'components/form/_partials/FormFieldBase/FormFieldBase'
import { FormFieldLabel } from 'components/form/_partials/FormFieldLabel/FormFieldLabel'

// styles
import * as css from './Input.scss'

export interface IInputProps
  extends Omit<IFormFieldBaseProps, 'label' | 'children' | 'valid' | 'errorMessage'>,
    InputHTMLAttributes<HTMLInputElement> {
  /**
   * Callback for formatting value
   */
  formatValue?: (val: any) => string
  /**
   * Field name
   */
  name: string
  /**
   * Explicit label for the input,
   * has higher priority than usePlaceholderAsLabel.
   */
  label?: ReactElement<ITranslationProps> | string
  /**
   * Add className to input wrap - usefull for Checkbox
   */
  inputWrapClassName?: string
}

/**
 * Basic text field for form
 */
export const Input = forwardRef(
  (
    {
      inline,
      inverted,
      helperText,
      infoHeading,
      infoContent,
      label,
      leftRender,
      displayValidationIcon,
      rightRender,
      disabled: disabledProp,
      placeholder,
      className,
      classes = {},
      inputWrapClassName,
      formatValue,
      ...passingProps
    }: IInputProps,
    ref: Ref<any>
  ) => {
    const { defaultValue, value, ...additionalProps } = passingProps

    if (defaultValue || value) {
      console.warn(`Don't use defaultValue & value, because formik doesn't recognise them`)
    }

    const { isSubmitting } = useFormikContext()
    const [field, meta] = useField(additionalProps)
    const [focused, handleFocus, handleBlur] = useFocus<HTMLInputElement>(field)
    const id = useId(field.name)
    const hasValue = (field.value || field.value === 0) && field.value.toString() !== ''
    const disabled = isSubmitting || disabledProp

    return (
      <FormFieldBase
        className={classnames(css.root, className)}
        classes={{
          ...classes,
          inputWrap: classnames(inputWrapClassName, classes.inputWrap),
        }}
        disabled={disabled}
        displayValidationIcon={displayValidationIcon}
        errorMessage={meta.error}
        focused={focused}
        helperText={helperText}
        infoContent={infoContent}
        infoHeading={infoHeading}
        inline={inline}
        inverted={inverted}
        label={label ? <FormFieldLabel htmlFor={id}>{label}</FormFieldLabel> : null}
        leftRender={leftRender}
        pinLabel={hasValue}
        rightRender={rightRender}
        valid={meta.touched ? !meta.error : undefined}
      >
        <input
          ref={ref}
          disabled={disabled}
          id={id}
          placeholder={!label || (focused && !hasValue) ? placeholder : undefined}
          {...field}
          className={classes.input}
          value={formatValue ? formatValue(field.value) : field.value}
          onBlur={handleBlur}
          onClick={(e) => {
            // #NOTE: for whatever reason the input was losing focus upon being clicked, this solves that issue
            e.preventDefault()
            e.stopPropagation()
          }}
          onFocus={handleFocus}
          {...additionalProps}
        />
      </FormFieldBase>
    )
  }
)
