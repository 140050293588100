// core
import React, { ReactElement, ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { Button, ButtonSize } from 'components/complex/Button'
import { IPopupProps, Popup } from 'components/complex/Popup'
import { ITranslationProps } from 'components/complex/Translation'

// styles
import * as css from './InfoPopup.scss'

export interface IInfoPopupProps extends IDotappComponentProps {
  /**
   * Whether to invert colours
   */
  inverted?: boolean
  /**
   * Whether popup is disabled
   */
  disabled?: boolean
  /**
   * Popup heading
   */
  heading: ReactElement<ITranslationProps>
  /**
   * Popup content
   */
  content?: ReactNode
  /**
   * Size of icon
   */
  size?: ButtonSize
  /**
   * Icon to display inside popup
   */
  popupIcon?: string
  /**
   * Icon to display outside popup
   */
  icon?: string
  /**
   * Color of text inside popup
   */
  color?: IPopupProps['color']
}

/**
 * Info button with popup which contains addition informations for the user
 */
export const InfoPopup = ({
  inverted = false,
  disabled = false,
  size = 'medium',
  heading,
  icon = 'info_outlined',
  popupIcon = 'info_outlined',
  color = 'primary',
  content,
  className,
}: IInfoPopupProps) => {
  return !disabled ? (
    <Popup
      className={classnames(
        css.root,
        {
          [css.inverted]: inverted,
        },
        className
      )}
      classes={
        inverted
          ? {
              paper: css.invertedContent,
            }
          : {}
      }
      color={color}
      content={content}
      heading={heading}
      icon={popupIcon}
      inverted={inverted}
      size={content ? 'large' : 'medium'}
    >
      <Button contained color="transparent" iconLeft={icon} size={size} variant="icon" />
    </Popup>
  ) : (
    <Icon
      className={classnames(
        css.root,
        css.icon,
        css.disabled,
        css[size],
        {
          [css.inverted]: inverted,
        },
        className
      )}
      name={icon}
    />
  )
}
