// core
import React, { useContext, useEffect, useState } from 'react'

// libraries
import classnames from 'classnames'
import { useHistory } from 'react-router'

import { LoggedInUserContext } from 'index'

// components
import { Link } from 'components/basic/Link/Link'
import { Translation } from 'components/complex/Translation'

// styles
import * as css from './SubscribeBanner.scss'

export interface ISubscribeBannerProps {
  /**
   * Trial ending
   */
  daysLeft: number
  /**
   * type
   */
  type?: 'trial' | 'restricted'
}

export const SubscribeBanner = ({ type = 'trial', daysLeft }: ISubscribeBannerProps) => {
  const { loggedInUser } = useContext(LoggedInUserContext)

  const [display, setDisplay] = useState<boolean>(false)

  const history = useHistory()

  history.listen(() => {
    setDisplay(false)

    setTimeout(() => {
      setDisplay(true)
    }, 0)
  })

  useEffect(() => {
    setTimeout(() => setDisplay(true), 1000)
  }, [])

  return (
    <div className={classnames(css.root, css[type], { [css.animation]: display })}>
      <div className={css.content}>
        {type === 'restricted' ? (
          <Translation keyValue="subscriptions.expired_badge_description" />
        ) : daysLeft <= 1 ? (
          <Translation
            keyValue={`subscriptions.${
              daysLeft === 1 ? 'trial_badge_expires_in_one_day' : 'trial_expires_today'
            }`}
          />
        ) : (
          <Translation daysLeft={daysLeft} keyValue="subscriptions.trial_badge_description" />
        )}

        <Link
          className={css.link}
          color="white"
          to={`/user/${loggedInUser?.slug}?secondColContent=subscription`}
        >
          <Translation keyValue="subscriptions.choose_subscription" />
        </Link>
      </div>
    </div>
  )
}
