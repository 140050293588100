// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Footer_root_kmtrB{margin:20px 0 44px 40px}.Footer_root_kmtrB a,.Footer_root_kmtrB span{color:#656565;font-size:12px}.Footer_root_kmtrB hr{border-color:#656565;margin-top:8px}@media (max-width: 767.98px){.Footer_root_kmtrB{margin-bottom:20px}}\n", ""]);
// Exports
exports.locals = {
	"root": "Footer_root_kmtrB"
};
module.exports = exports;
