import { lazy } from 'react'

import { IRoutes } from './index'

const AccessRights = lazy(() => import('pages/AccessRights/AccessRights'))
const Companies = lazy(() => import('pages/Companies/CompanyManagement'))
const Translations = lazy(() => import('pages/Languages/Translations'))
const LanguageKeys = lazy(() => import('pages/Languages/LanguageKeys'))
const Languages = lazy(() => import('pages/Languages/Languages'))
const Settings = lazy(() => import('pages/Settings/Settings'))
const Projects = lazy(() => import('pages/Projects/Projects'))
const Office = lazy(() => import('pages/Office/Office'))
const Milestones = lazy(() => import('pages/Milestones/Milestones'))
const UserManagement = lazy(() => import('pages/UserManagement/UserManagement'))
const Articles = lazy(() => import('pages/Articles/Articles'))
const Templates = lazy(() => import('pages/Templates/Templates'))
const SystemMenu = lazy(() => import('pages/SystemMenu/SystemMenu'))
const Smartcity = lazy(() => import('pages/Smartcity/Smartcity'))
const IotApps = lazy(() => import('pages/IotApps/IotApps'))
const Gifts = lazy(() => import('pages/Gifts/Gifts'))
const News = lazy(() => import('pages/News/News'))
const PpePosts = lazy(() => import('pages/PpePosts/PpePosts'))
const PolVerifications = lazy(() => import('pages/PolVerifications/PolVerifications'))
const Reports = lazy(() => import('pages/Reports/Reports'))
const Kiosks = lazy(() => import('pages/Kiosks/Kiosks'))
const KioskMenus = lazy(() => import('pages/KioskMenus/KioskMenus'))
const ProductManagement = lazy(() => import('pages/ProducManagement/ProductManagement'))

const routes: IRoutes[] = [
  {
    component: AccessRights,
    path: '/access-rights',
  },
  {
    component: Companies,
    path: '/companies',
  },
  {
    component: Languages,
    path: '/languages',
  },
  {
    component: Reports,
    path: '/reports',
  },
  {
    component: LanguageKeys,
    path: '/languages/language-keys',
  },
  {
    component: Translations,
    path: '/languages/translations',
  },
  {
    component: Settings,
    path: '/system-settings',
  },
  {
    component: Projects,
    path: '/projects',
  },
  {
    component: PolVerifications,
    path: '/pol-verifications',
  },
  {
    component: Office,
    path: '/office',
  },
  {
    component: Milestones,
    path: '/milestones',
  },
  {
    component: UserManagement,
    path: '/users',
  },
  {
    component: Articles,
    path: '/articles',
  },
  {
    component: Templates,
    path: '/templates',
  },
  {
    component: SystemMenu,
    path: '/system-menu',
  },
  {
    component: Smartcity,
    path: '/beacon-zones',
  },
  {
    component: IotApps,
    path: '/iot-applications',
  },
  {
    component: Gifts,
    path: '/gifts',
  },
  {
    component: News,
    path: '/news',
  },
  {
    component: PpePosts,
    path: '/offers',
  },
  {
    component: PpePosts,
    path: '/demands',
  },
  {
    component: PpePosts,
    path: '/my-deals',
  },
  {
    component: Kiosks,
    path: '/kiosks',
  },
  {
    component: KioskMenus,
    path: '/kiosk-menus',
  },
  {
    component: ProductManagement,
    path: '/products',
  },
]

export const LoggedRoutes = routes
