// core
import React, { forwardRef, ReactElement, ReactNode } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { Avatar } from 'components/complex/Avatar'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './List.scss'

/**
 * Allowed datatypes as list option value.
 */
export type ListOptionValueType = string

export type ListOptionSize = 'micro' | 'mini' | 'small' | 'medium'

export interface IListOptionProps extends IDotappComponentProps {
  /**
   * The title of the list option, should be used as the major information (containing the most important information).
   */
  title: ReactNode
  /**
   * The value of the list option, will be passed in the onClick callback.
   */
  value: ListOptionValueType
  /**
   * Left icon name.
   */
  iconLeft?: string | null
  /**
   * Right icon name.
   */
  iconRight?: string | null
  /**
   * Image source (url).
   */
  image?: string
  /**
   * The subtitle of the option, should contain less important information.
   */
  subtitle?: string | ReactElement
  /**
   * Custom element which will be rendered on the left side of the item.
   */
  startRender?: ReactNode
  /**
   * Custom element which will be rendered on the right side of the item.
   */
  endRender?: ReactNode
  /**
   * Custom element which will be rendered bellow item
   */
  contentBellow?: ReactNode | null
  /**
   * Callback to run on option click
   */
  onClick?: (value: ListOptionValueType) => any
  /**
   * Boolean if option is disabled
   */
  disabled?: boolean
  /**
   * Option size
   */
  size?: ListOptionSize
}

export const ListOption = forwardRef(function ListOption(
  {
    startRender,
    image,
    iconLeft,
    disabled,
    title,
    subtitle,
    iconRight,
    endRender,
    value,
    contentBellow,
    onClick,
    className,
    size = 'medium',
    classes = {},
  }: IListOptionProps,
  ref: any
) {
  const content = (
    <div className={classnames(css.item, classes.item)}>
      {startRender}

      {image || iconLeft ? (
        <Avatar className={css.avatar} icon={iconLeft} size={size} url={image} />
      ) : null}

      <div className={classnames(css.content, { [css.hasSubtitle]: subtitle }, classes.content)}>
        <div className={classnames(css.title, classes.title)}>{title}</div>
        {subtitle ? (
          <div className={classnames(css.subtitle, classes.subtitle)}>{subtitle}</div>
        ) : null}
      </div>

      {iconRight ? <Icon className={css.iconRight} name={iconRight} /> : null}

      {endRender}
    </div>
  )

  return (
    <li ref={ref} className={classnames(className, { [css.disabled]: disabled })}>
      {onClick && !disabled ? (
        <a
          className={classnames(css.itemWrap, classes.itemWrap)}
          href="#"
          onClick={(event) => {
            event.preventDefault()
            event.stopPropagation()
            event.currentTarget.blur()

            runCallback(onClick, value)
          }}
        >
          {content}
        </a>
      ) : (
        <div className={css.itemWrap}>{content}</div>
      )}

      {contentBellow ? <div className={css.contentBellow}>{contentBellow}</div> : null}
    </li>
  )
})
