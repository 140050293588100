// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Paper_root_2Yxl4{border-radius:4px;background-color:#fff;color:#656565}.Paper_root_2Yxl4.Paper_elevation1_2AB6f{box-shadow:0 10px 20px 0 rgba(0,0,0,0.05)}.Paper_root_2Yxl4.Paper_elevation2_1L_qS{box-shadow:0 5px 20px 0 rgba(0,0,0,0.15)}.Paper_root_2Yxl4.Paper_inverted_2EClq{background-color:#2b2b2b;color:white}\n", ""]);
// Exports
exports.locals = {
	"root": "Paper_root_2Yxl4",
	"elevation1": "Paper_elevation1_2AB6f",
	"elevation2": "Paper_elevation2_1L_qS",
	"inverted": "Paper_inverted_2EClq"
};
module.exports = exports;
