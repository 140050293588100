// core
import React from 'react'

// libraries
import classnames from 'classnames'

// components
import { Coin, CoinColor, ICoinProps } from 'components/basic/Coin'

// styles
import * as css from './Chip.scss'

export type ChipVariant = 'default' | 'light'

export type ChipColor = Exclude<CoinColor, 'transparent'>

export interface IChipProps extends Omit<ICoinProps, 'color'> {
  /**
   * Chip variant
   */
  variant?: ChipVariant
  /**
   * Chip color
   */
  color?: ChipColor
}

/**
 * Simple chip which is mainly used to list some attributes
 */
export const Chip = ({
  variant = 'default',
  color = 'primary',
  size = 'medium',
  className,
  ...passingProps
}: IChipProps) => {
  return (
    <Coin
      className={classnames(
        css.root,
        { [css.lightVariant]: variant === 'light' },
        css[color],
        css[size],
        className
      )}
      color={color}
      size={size}
      {...passingProps}
    />
  )
}
