// core
import React, { memo, MouseEvent, useCallback, useContext, useState } from 'react'

// libraries
import classnames from 'classnames'
import moment from 'moment'
import { useHistory } from 'react-router-dom'

// apollo
import { gql, useApolloClient, useQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import {
  SubscriptionStatus,
  UserSubscriptionStatus,
  //   VerificationStatus,
} from 'generated/global-types'
import {
  GetUserMenuData,
  GetUserMenuData_sysUser_subscriptions as GetUserMenuDataSysUserSubscriptions,
  GetUserMenuDataVariables,
} from 'queries/Layout/types/GetUserMenuData'

// components
import { Avatar } from 'components/complex/Avatar'
import { Button } from 'components/complex/Button'
import { ExpandableList } from 'components/complex/ExpandableList/ExpandableList'
import { Translation } from 'components/complex/Translation'
import { User } from 'components/complex/User/User'

// import { VerificationsWarning } from 'layouts/Notifications/Partials/VerificationsWarning'
// assets
import { ReactComponent as FlagEn } from 'assets/images/flag-en.svg'
import { ReactComponent as FlagSk } from 'assets/images/flag-sk.svg'

import { LoggedInUserContext } from '../../index'
import { ISidePanelTypes } from '../Layout'

// partials
// import { ScheduleCallBanner } from './Partials/ScheduleCallBanner'
import { SubscriptionStatuses } from './Partials/SubscriptionStatus'

// import { NotificationButton } from '../NotificationButton/NotificationButton'
// styles
import * as css from './UserMenu.scss'

export interface IUserMenuProps {
  /**
   * Currently logged in user
   */
  loggedInUser: {
    id: string
  }
  /**
   * Function to open diferent SideMenu
   */
  openSidePanel: (id: ISidePanelTypes | null) => any
}

export const UserMenu = memo(function UserMenu({ loggedInUser, openSidePanel }: IUserMenuProps) {
  const history = useHistory()
  const client = useApolloClient()

  const { setLoggedInUser } = useContext(LoggedInUserContext)

  const [confirmLogout, setConfirmLogout] = useState<boolean>(false)

  // const activePath = '/access-right'
  const { data } = useQuery<GetUserMenuData, GetUserMenuDataVariables>(
    LayoutQueries.GET_USER_MENU_DATA,
    {
      variables: { id: loggedInUser.id },
    }
  )

  // const handleNotificationClick = () => {
  //   openSidePanel('notifications')
  // }

  const handleItemClick = (action: string) => {
    if (action === 'logout') {
      setConfirmLogout(true)
    }
  }

  const handleLanguageChange = (languageId: string) => (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()

    localStorage.setItem('languageId', languageId)

    localStorage.setItem('selectedLanguageId', languageId)

    client.writeQuery({
      data: { selectedLanguageId: languageId },
      query: gql`
        query {
          selectedLanguageId
        }
      `,
    })
  }

  const handleLogout = () => {
    setLoggedInUser(null)
  }

  const handleManageAccountClick = () => {
    if (data?.sysUser) {
      history.push(`/user/${data.sysUser.id}`)
    }
    openSidePanel(null)
  }

  const handleLogoutCancel = () => {
    setConfirmLogout(false)
  }

  //   const handleVerificationClick = useCallback(() => {
  //     history.replace(`/user/${data?.sysUser?.slug}?secondColContent=verification`)
  //     openSidePanel(null)
  //   }, [data?.sysUser?.slug, history, openSidePanel])

  let latestSubscription: GetUserMenuDataSysUserSubscriptions[] | null = null

  if (data?.sysUser?.subscriptions.length) {
    latestSubscription = [data.sysUser.subscriptions[0]]

    if (data?.sysUser) {
      data.sysUser.subscriptions.forEach((subscription) => {
        if (latestSubscription && subscription.activeUntil > latestSubscription[0].activeUntil) {
          latestSubscription = [subscription]
        }
      })
    }
  }

  const activeSubscription = data?.sysUser?.subscriptions.filter(
    ({ activeUntil, status }) =>
      (activeUntil > new Date() && status !== SubscriptionStatus.UNPAID) ||
      status === SubscriptionStatus.ACTIVE
  )

  const currentSubscription = activeSubscription?.length
    ? activeSubscription[0]
    : latestSubscription?.length
    ? latestSubscription[0]
    : null

  const currentSubscriptionActiveUntil = moment(currentSubscription?.activeSince)
    .add(currentSubscription?.type.monthsAmount, 'months')
    .toDate()

  //   const handleScheduleCallClick = useCallback(() => {
  //     history.push(`/schedule-call`)
  //     openSidePanel(null)
  //   }, [history, openSidePanel])

  const handleCloseSidePanel = useCallback(() => {
    openSidePanel(null)
  }, [openSidePanel])

  return (
    <div className={classnames(css.root)}>
      {data?.sysUser &&
      data.sysUser.subscriptionStatus !== UserSubscriptionStatus.NONE &&
      data.sysUser.subscriptions.length > 0 &&
      currentSubscription ? (
        <SubscriptionStatuses
          activeUntil={currentSubscription.activeUntil || currentSubscriptionActiveUntil}
          closePanel={handleCloseSidePanel}
          status={currentSubscription.status}
          subscription={data.sysUser.subscriptionStatus}
        />
      ) : null}
      {/* {data?.sysUser?.verificationStatus === VerificationStatus.UNVERIFIED && (
        <VerificationsWarning handleVerificationClick={handleVerificationClick} />
      )} */}
      {data?.sysUser && (
        <User
          className={css.user}
          side="right"
          size="huge"
          {...data.sysUser}
          documentType={data.sysUser.verification?.documentType}
          email={data.sysUser.emailPrimary}
          profilePicture={data.sysUser.profilePictureSysFile?.fileUrl}
          sysAccessGroupId={data.sysUser.sysAccessGroup.id}
          sysAccessGroupTitle={data.sysUser.sysAccessGroup.title}
          totalCount={data.sysUser.acquiredRatings?.totalCount}
        />
      )}

      <ExpandableList
        className={css.menu}
        options={[
          {
            onClick: handleManageAccountClick,
            title: <Translation keyValue="general.manage_account" />,
            value: 'profile',
          },
          {
            contentBellow: data && (
              <div className={css.flags}>
                {data.sysLanguages
                  .filter((lang) => lang.codeShort !== 'CZ')
                  .map((language) => (
                    <a
                      key={language.id}
                      className={
                        data.selectedLanguageId.toString() === language.id ? css.active : ''
                      }
                      href="#"
                      onClick={handleLanguageChange(language.id)}
                    >
                      {language.codeShort === 'EN' ? (
                        <FlagEn />
                      ) : language.codeShort === 'SK' ? (
                        <FlagSk />
                      ) : (
                        <Avatar size="small">{language.codeShort}</Avatar>
                      )}
                    </a>
                  ))}
              </div>
            ),
            title: <Translation keyValue="general.language_settings" />,
            value: 'language',
          },
          {
            className: confirmLogout ? css.logoutHidden : css.logout,
            iconRight: 'input',
            title: <Translation keyValue="general.actions.logout" />,
            value: 'logout',
          },
        ]}
        onClick={handleItemClick}
      />

      {confirmLogout ? (
        <div className={css.confirmLogout}>
          <div>
            <Translation keyValue="general.confirm_logout" />
          </div>

          <Button
            color="success"
            iconLeft="check"
            size="small"
            variant="icon"
            onClick={handleLogout}
          />

          <Button
            contained
            className={css.logoutButton}
            color="danger"
            iconLeft="close"
            size="small"
            variant="icon"
            onClick={handleLogoutCancel}
          />
        </div>
      ) : null}

      {/* {data?.sysUser?.subscriptionStatus !== UserSubscriptionStatus.RESTRICTED && (
        <ScheduleCallBanner handleScheduleCallClick={handleScheduleCallClick} />
      )} */}
    </div>
  )
})
