// core
import React, { useRef } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'

// styles
import * as css from '../Modal.scss'

export interface IModalComponentProps extends IDotappWrapperComponentProps {
  /**
   * Whether to show content or not
   */
  open: boolean
  /**
   * Width of element
   */
  width: number
  /**
   * X coordinate on page
   */
  x: number
  /**
   * Y coordinate on page
   */
  y: number
  /**
   * Css z-index value
   */
  zIndex?: number
}

export const ModalComponent = ({
  x = 0,
  y = 0,
  zIndex = 500,
  width,
  open,
  className,
  children,
}: IModalComponentProps) => {
  const rootRef = useRef<HTMLDivElement>(null)

  return (
    <div
      ref={rootRef}
      className={classnames(
        css.root,
        {
          [css.open]: open,
        },
        className
      )}
      style={{
        left: x,
        // do not overlap screen
        maxHeight: innerHeight - y,
        top: y,
        width,
        zIndex,
      }}
    >
      {children}
    </div>
  )
}
