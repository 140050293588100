import { gql } from '@apollo/client'
import { UsersFragments } from 'queries/Users/UsersFragments'

export const SubscriptionsFragments = {
  PAYMENT_METHOD: gql`
    fragment PaymentMethodFragment on PaymentMethod {
      id
      cardBrand
      cardLast4
      cardExpMonth
      cardExpYear
      billingName
    }
  `,

  SUBSCRIPTION_TYPE: gql`
    fragment SubscriptionTypeFragment on SubscriptionType {
      id
      name
      stripePriceId
      price
      monthsAmount
    }
  `,

  USER_SUBSCRIPTION_DATA: gql`
    fragment UserSubscriptionDataFragment on SysUser {
      id
      emailPrimary
      ...UserBillingDetailsFormFragment
      ...UserCompanyFormFragment
    }
    ${UsersFragments.BILLING_DETAILS_FORM}
    ${UsersFragments.COMPANY_FORM}
  `,

  USER_SUBSCRIPTION_DETAILS: gql`
    fragment UserSubscriptionDetailsFragment on SysUser {
      id
      verificationStatus
      subscriptionStatus
      subscriptions {
        id
        status
        activeSince
        activeUntil
        type {
          id
          monthsAmount
          name
        }
      }
      paymentMethods {
        id
        cardBrand
        cardLast4
        cardExpMonth
        cardExpYear
        billingName
      }
      invoices {
        id
        paymentDate
        amount
        pdf
      }
      ...UserBillingDetailsFormFragment
    }
    ${UsersFragments.BILLING_DETAILS_FORM}
  `,
}
