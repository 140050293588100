import React, { useEffect } from 'react'

import { RouteComponentProps } from 'react-router'

import { IRoutes } from './index'

const Login = React.lazy(() => import('pages/Auth/Login'))
const SetNewPassword = React.lazy(() => import('pages/Auth/SetNewPassword'))
const NewUserSetPassword = React.lazy(() => import('pages/Auth/NewUserSetPassword'))
const MailPathResolver = React.lazy(() => import('pages/MailPathResolver/MailPathResolver'))

const Redirecter = ({ match }: RouteComponentProps) => {
  useEffect(() => {
    window.location.replace(
      `https://ppecenter.us/${match.path.includes('/p') ? 'post' : 'deal'}/${
        (match.params as any).id
      }`
    )
  }, [])

  return null
}

const routes: IRoutes[] = [
  {
    component: Login,
    path: '/login',
  },
  {
    component: SetNewPassword,
    path: '/set-new-password',
  },
  {
    component: NewUserSetPassword,
    path: '/new-user-set-password',
  },
  {
    // @ts-ignore
    component: Redirecter,
    path: '/p/:id',
  },
  {
    // @ts-ignore
    component: Redirecter,
    path: '/d/:id',
  },
  {
    // @ts-ignore
    component: Redirecter,
    path: '/u/:id',
  },
  {
    component: MailPathResolver,
    path: '/mail/:hash',
  },
]

export const AuthRoutes = routes
