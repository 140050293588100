/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * Action key type
 */
export enum ActionKeyType {
  CREATE = 'CREATE',
  DELETE = 'DELETE',
  UPDATE = 'UPDATE',
  VIEW = 'VIEW',
}

/**
 * Order list of banners by one of the following
 */
export enum BannerOrderBy {
  ACTIVE_SINCE = 'ACTIVE_SINCE',
  ACTIVE_UNTIL = 'ACTIVE_UNTIL',
  TITLE = 'TITLE',
}

/**
 * Order websites list by one of the following
 */
export enum BeaconZoneOrderBy {
  STATUS = 'STATUS',
}

/**
 * Available beacon zone statuses
 */
export enum BeaconZoneStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

/**
 * Aliases for deal power weights
 */
export enum DealPowerWeightAlias {
  DW_DEMAND_UNDER_INVESTIGATION = 'DW_DEMAND_UNDER_INVESTIGATION',
  DW_OFFER_UNDER_INVESTIGATION = 'DW_OFFER_UNDER_INVESTIGATION',
  DW_PPE_TEAM_RANK = 'DW_PPE_TEAM_RANK',
}

/**
 * Type of Delivery aliases
 */
export enum DeliveryAliasType {
  FHB_COURIERS = 'FHB_COURIERS',
  OTHER = 'OTHER',
  ZASIELKOVNA_BRANCHES = 'ZASIELKOVNA_BRANCHES',
  ZASIELKOVNA_COURIERS = 'ZASIELKOVNA_COURIERS',
}

/**
 * What to order the list by
 */
export enum DeliveryCountryOrderBy {
  NAME = 'NAME',
  PRICE_CZK = 'PRICE_CZK',
  PRICE_EUR = 'PRICE_EUR',
  PRODUCTION = 'PRODUCTION',
  VAT = 'VAT',
}

/**
 * What to order the list by
 */
export enum DeliveryOrderBy {
  ALIAS = 'ALIAS',
  PRODUCTION = 'PRODUCTION',
  TITLE_CZ = 'TITLE_CZ',
  TITLE_EN = 'TITLE_EN',
  TITLE_SK = 'TITLE_SK',
}

/**
 * Type of the feedback
 */
export enum FeedbackType {
  BUG = 'BUG',
  PORTAL_FEATURE = 'PORTAL_FEATURE',
}

/**
 * Order gift list by one of the following
 */
export enum GiftOrderBy {
  ACTIVE_SINCE = 'ACTIVE_SINCE',
  ACTIVE_UNTIL = 'ACTIVE_UNTIL',
  TITLE = 'TITLE',
}

/**
 * Order websites list by one of the following
 */
export enum IotApplicationOrderBy {
  NAME = 'NAME',
}

/**
 * Type of menu item
 */
export enum KioskMenuItemMenuType {
  PRODUCTS = 'PRODUCTS',
  PUZZLE = 'PUZZLE',
  VIDEO = 'VIDEO',
}

/**
 * What to order the entries by
 */
export enum KioskMenuItemOrderBy {
  DESCRIPTION = 'DESCRIPTION',
  KIOSK_MENU_TYPE = 'KIOSK_MENU_TYPE',
  PRODUCTION = 'PRODUCTION',
  TITLE = 'TITLE',
}

/**
 * What to order the entries by
 */
export enum KioskMenuOrderBy {
  TITLE = 'TITLE',
}

/**
 * What to order the entries by
 */
export enum KioskOrderBy {
  ACCESS_ALLOWED = 'ACCESS_ALLOWED',
  ADDRESS = 'ADDRESS',
  CREATED_AT = 'CREATED_AT',
  KIOSK_MENU = 'KIOSK_MENU',
  TITLE = 'TITLE',
}

/**
 * Allowed operations for less than or greater than date range filter
 */
export enum LTGTFilterOps {
  GTE = 'GTE',
  LTE = 'LTE',
}

/**
 * Order list of news articles by one of the following
 */
export enum NewsArticleOrderBy {
  PUBLISHED_AT = 'PUBLISHED_AT',
  TITLE = 'TITLE',
}

/**
 * Notification types
 */
export enum NotificationType {
  MESSAGE = 'MESSAGE',
  RATING = 'RATING',
  SYSTEM = 'SYSTEM',
  WATCHDOG = 'WATCHDOG',
}

/**
 * Option for setting order direction
 */
export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC',
}

/**
 * Currencies of the Order
 */
export enum OrderCurrency {
  CZK = 'CZK',
  EUR = 'EUR',
}

/**
 * What to order the entries by
 */
export enum OrderItemOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/**
 * What to order the entries by
 */
export enum OrderOrderBy {
  CREATED_AT = 'CREATED_AT',
  INVOICE_VS = 'INVOICE_VS',
  NAME = 'NAME',
  PHONE = 'PHONE',
  PRICE = 'PRICE',
  STRIPE_STATUS = 'STRIPE_STATUS',
}

/**
 * Order's payment status
 */
export enum OrderStripeStatus {
  CANCELED = 'CANCELED',
  PROCESSING = 'PROCESSING',
  REQUIRES_ACTION = 'REQUIRES_ACTION',
  REQUIRES_CAPTURE = 'REQUIRES_CAPTURE',
  REQUIRES_CONFIRMATION = 'REQUIRES_CONFIRMATION',
  REQUIRES_PAYMENT_METHOD = 'REQUIRES_PAYMENT_METHOD',
  SUCCEEDED = 'SUCCEEDED',
}

/**
 * Time period from which the Orders are taken from the DB and displayed by the widget in admin
 */
export enum OrderWidgetPeriod {
  ALL = 'ALL',
  LAST_MONTH = 'LAST_MONTH',
  LAST_WEEK = 'LAST_WEEK',
  THIS_MONTH = 'THIS_MONTH',
  THIS_WEEK = 'THIS_WEEK',
  THIS_YEAR = 'THIS_YEAR',
  TODAY = 'TODAY',
}

/**
 * Order list of posts by one of the following
 */
export enum PolVerificationOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/**
 * Type of the owner of the document
 */
export enum PpeDocumentOwnerType {
  POST = 'POST',
  USER = 'USER',
}

/**
 * Possible ppe document pol verification statuses
 */
export enum PpeDocumentPolVerificationStatus {
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  VERIFYING = 'VERIFYING',
}

/**
 * Document types
 */
export enum PpeDocumentType {
  PROOF_OF_LIFE = 'PROOF_OF_LIFE',
}

/**
 * Order list of posts by one of the following
 */
export enum PpePostOrderBy {
  CREATED_AT = 'CREATED_AT',
  EXPIRATION_DATE = 'EXPIRATION_DATE',
  MINIMUM_ORDER_QUANTITY = 'MINIMUM_ORDER_QUANTITY',
  POWER = 'POWER',
  STATE = 'STATE',
  TITLE = 'TITLE',
  UPDATED_AT = 'UPDATED_AT',
}

/**
 * PPE Post states
 */
export enum PpePostState {
  ACTIVE = 'ACTIVE',
  CLOSED = 'CLOSED',
  DRAFT = 'DRAFT',
  HIDDEN = 'HIDDEN',
  INACTIVE = 'INACTIVE',
}

/**
 * PPE Post offer type
 */
export enum PpePostType {
  BUY = 'BUY',
  SELL = 'SELL',
}

/**
 * Status of goods in the warehouse
 */
export enum PpePostWarehouseStatus {
  NONE = 'NONE',
  ON_THE_GROUND = 'ON_THE_GROUND',
  PRODUCTION_LINE = 'PRODUCTION_LINE',
}

/**
 * Order the list by specific columns
 */
export enum ProductItemOrderBy {
  CODE = 'CODE',
  EAN = 'EAN',
  POSITION = 'POSITION',
  PRINT_ON_DEMAND = 'PRINT_ON_DEMAND',
  PRODUCTION = 'PRODUCTION',
  SIZE = 'SIZE',
  SOURCE_TYPE = 'SOURCE_TYPE',
  TEXTILE_PRICE = 'TEXTILE_PRICE',
  WAREHOUSE_STATUS = 'WAREHOUSE_STATUS',
}

/**
 * Enum for sourceType property
 */
export enum ProductItemSourceType {
  FHB = 'FHB',
  MERCHYOU = 'MERCHYOU',
}

/**
 * What to order the entries by
 */
export enum ProductOrderBy {
  POSITION = 'POSITION',
  PRICE_CZK = 'PRICE_CZK',
  PRICE_EUR = 'PRICE_EUR',
  PRODUCTION = 'PRODUCTION',
  TITLE = 'TITLE',
}

/**
 * What to order the entries by
 */
export enum ProductPrintOrderBy {
  BASE_COL = 'BASE_COL',
  COL_SIZE = 'COL_SIZE',
  PANTONE = 'PANTONE',
  PRINT_LOC = 'PRINT_LOC',
  PRINT_QTY = 'PRINT_QTY',
  TECHNIQUE = 'TECHNIQUE',
  TECHNOLOGY = 'TECHNOLOGY',
}

/**
 * Order list of posts by one of the following
 */
export enum SecondStageVerificationOrderBy {
  CREATED_AT = 'CREATED_AT',
  DENIED_AT = 'DENIED_AT',
  SENT_AT = 'SENT_AT',
  SUBMITTED_AT = 'SUBMITTED_AT',
  UPDATED_AT = 'UPDATED_AT',
  VERIFIED_AT = 'VERIFIED_AT',
}

/**
 * Possible statuses of 2nd stage verification
 */
export enum SecondStageVerificationStatus {
  CREATED = 'CREATED',
  DENIED = 'DENIED',
  NONE = 'NONE',
  SENT = 'SENT',
  SUBMITTED = 'SUBMITTED',
  VERIFIED = 'VERIFIED',
}

/**
 * Filter statuses of 2nd stage verification
 */
export enum SecondStageVerificationStatusFilter {
  ANY_SUBMITTED = 'ANY_SUBMITTED',
  CREATED = 'CREATED',
  DENIED = 'DENIED',
  SENT = 'SENT',
  SUBMITTED = 'SUBMITTED',
  VERIFIED = 'VERIFIED',
}

/**
 * Second stage verification update statuses
 */
export enum SecondStageVerificationUpdateStatus {
  DENIED = 'DENIED',
  VERIFIED = 'VERIFIED',
}

/**
 * Subscription status enum definition
 */
export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  PENDING_CANCEL = 'PENDING_CANCEL',
  UNPAID = 'UNPAID',
}

/**
 * Order user list by some property
 */
export enum SysLanguageKeyOrderBy {
  DESCRIPTION = 'DESCRIPTION',
  KEY = 'KEY',
}

/**
 * Order user list by some property
 */
export enum SysUserOrderBy {
  EMAIL = 'EMAIL',
  NAME = 'NAME',
  PHONE = 'PHONE',
  ROLE = 'ROLE',
  STATUS = 'STATUS',
  SYS_ACCESS_GROUP = 'SYS_ACCESS_GROUP',
}

/**
 * User status enum definition
 */
export enum SysUserStatus {
  ACTIVE = 'ACTIVE',
  PENDING = 'PENDING',
  RESTRICTED = 'RESTRICTED',
}

/**
 * Document types
 */
export enum SysUserVerificationDocumentType {
  DRIVING_LICENSE = 'DRIVING_LICENSE',
  ID_CARD = 'ID_CARD',
  PASSPORT = 'PASSPORT',
}

/**
 * Order list of posts by one of the following
 */
export enum SysUserVerificationOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/**
 * User subscription status enum definition
 */
export enum UserSubscriptionStatus {
  MEMBER = 'MEMBER',
  NONE = 'NONE',
  RESTRICTED = 'RESTRICTED',
  TRIAL = 'TRIAL',
}

/**
 * User verification status
 */
export enum VerificationStatus {
  DENIED = 'DENIED',
  UNVERIFIED = 'UNVERIFIED',
  VERIFIED = 'VERIFIED',
  VERIFIED_BY_TEAM = 'VERIFIED_BY_TEAM',
  VERIFYING = 'VERIFYING',
}

/**
 * User verification status filter
 */
export enum VerificationStatusFilter {
  DENIED = 'DENIED',
  VERIFIED = 'VERIFIED',
  VERIFYING = 'VERIFYING',
}

/**
 * Type of verification
 */
export enum VerifiedOwnersType {
  ALL = 'ALL',
  ANY_VERIFIED = 'ANY_VERIFIED',
  TEAM = 'TEAM',
  USER = 'USER',
}

/**
 * Order by keys
 */
export enum ViolationReportOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/**
 * Order voucher list by one of the following
 */
export enum VoucherOrderBy {
  ASSIGNED_AT = 'ASSIGNED_AT',
  VALUE = 'VALUE',
}

/**
 * Category of location
 */
export enum WarehouseCategories {
  FACTORY = 'FACTORY',
  OFFICE = 'OFFICE',
  WAREHOUSE = 'WAREHOUSE',
}

/**
 * Order list of posts by one of the following
 */
export enum WarehouseVerificationOrderBy {
  CREATED_AT = 'CREATED_AT',
}

/**
 * Document types
 */
export enum WarehouseVerificationType {
  GPS = 'GPS',
  PROOF_OF_LIFE = 'PROOF_OF_LIFE',
  UTILITY_BILL = 'UTILITY_BILL',
}

/**
 * Object for creating records
 */
export interface AccessStatisticInputCreate {
  actionKey: ActionKeyType
  identifier?: string | null
  moduleKey: string
  moduleSpecifierKey?: string | null
}

/**
 * Data for creating a new banner
 */
export interface BannerInputCreate {
  title: string
  image: string
  activeSince?: any | null
  activeUntil?: any | null
  action?: string | null
}

/**
 * Data for updating one banner
 */
export interface BannerInputUpdate {
  title?: string | null
  image?: string | null
  activeSince?: any | null
  activeUntil?: any | null
  action?: string | null
}

/**
 * Object for creating records
 */
export interface BeaconZoneInputCreate {
  companyId?: string | null
  estimoteDeviceIdentifier: string
  triggerDistance: number
  image?: string | null
}

/**
 * Object for updating record
 */
export interface BeaconZoneInputUpdate {
  companyId?: string | null
  estimoteDeviceIdentifier?: string | null
  triggerDistance?: number | null
  status?: BeaconZoneStatus | null
  image?: string | null
}

/**
 * Object for creating records
 */
export interface BeaconZoneTranslationInputCreate {
  language: string
  title: string
  description?: string | null
  quickInfoShort?: string | null
  quickInfoMedium?: string | null
  quickInfoLong?: string | null
  content?: string | null
  audioFile?: string | null
}

/**
 * Object for updating record
 */
export interface BeaconZoneTranslationInputUpdate {
  language?: string | null
  title?: string | null
  description?: string | null
  quickInfoShort?: string | null
  quickInfoMedium?: string | null
  quickInfoLong?: string | null
  content?: string | null
  audioFile?: string | null
}

/**
 * Object for creating records
 */
export interface CompanyInputCreate {
  title: string
}

/**
 * Object for updating record
 */
export interface CompanyInputUpdate {
  title?: string | null
  domainUrlKiosk?: string | null
  domainUrlAdmin?: string | null
  contactName?: string | null
  contactEmail?: string | null
  contactPhone?: string | null
  stripeSecretKey?: string | null
  stripePublicKey?: string | null
  stripeWebhookSecretKey?: string | null
  kioskPin?: string | null
  emailTemplate?: string | null
}

/**
 * Object for creating records
 */
export interface ContactTypeInputCreate {
  title: string
}

/**
 * Object for updating record
 */
export interface ContactTypeInputUpdate {
  title: string
}

/**
 * Object for creating records
 */
export interface ContractTypeInputCreate {
  title: string
}

/**
 * Object for updating records
 */
export interface ContractTypeInputUpdate {
  title: string
}

/**
 * Coordinates input
 */
export interface CoordinatesInput {
  lat: number
  lng: number
}

/**
 * Data for creating a new DeliveryCountry
 */
export interface DeliveryCountryInputCreate {
  deliveryId: string
  countryId: string
  courierId?: string | null
  name?: string | null
  priceEur?: number | null
  priceCzk?: number | null
  vat?: number | null
  production?: boolean | null
}

/**
 * Data for updating an DeliveryCountry
 */
export interface DeliveryCountryInputUpdate {
  countryId?: string | null
  name?: string | null
  priceEur?: number | null
  priceCzk?: number | null
  vat?: number | null
  production?: boolean | null
}

/**
 * Data for creating a new Delivery
 */
export interface DeliveryInputCreate {
  titleEn?: string | null
  titleSk?: string | null
  titleCz?: string | null
  alias?: DeliveryAliasType | null
  production?: boolean | null
}

/**
 * Data for updating an Delivery
 */
export interface DeliveryInputUpdate {
  titleEn?: string | null
  titleSk?: string | null
  titleCz?: string | null
  alias?: DeliveryAliasType | null
  production?: boolean | null
}

/**
 * Object for creating records
 */
export interface FeedbackInputCreate {
  message: string
  type: FeedbackType
  userFullName?: string | null
  userEmail?: string | null
}

/**
 * Data for creating a new gift
 */
export interface GiftInputCreate {
  companyId?: string | null
  title: string
  image?: string | null
  shortDescription: string
  longDescription?: string | null
  activeSince: any
  activeUntil: any
}

/**
 * Data for updating one gift
 */
export interface GiftInputUpdate {
  companyId?: string | null
  title?: string | null
  image?: string | null
  shortDescription?: string | null
  longDescription?: string | null
  activeSince?: any | null
  activeUntil?: any | null
}

/**
 * Object for creating records
 */
export interface IotApplicationInputCreate {
  name: string
  companyId?: string | null
}

/**
 * Object for updating record
 */
export interface IotApplicationInputUpdate {
  name: string
  companyId?: string | null
}

/**
 * Data for creating a new kiosk
 */
export interface KioskInputCreate {
  title: string
  uuid: string
  address?: string | null
}

/**
 * Data for updating an existing kiosk menu
 */
export interface KioskInputUpdate {
  kioskMenuId?: string | null
  title?: string | null
  address?: string | null
  accessAllowed?: boolean | null
}

/**
 * Data for creating a new kiosk menu
 */
export interface KioskMenuInputCreate {
  title: string
  logo?: any | null
  backgroundImage?: any | null
  backgroundVideo?: any | null
}

/**
 * Data for updating an existing kiosk menu
 */
export interface KioskMenuInputUpdate {
  companyId?: string | null
  title?: string | null
  logo?: any | null
  backgroundImage?: any | null
  backgroundVideo?: any | null
}

/**
 * Data for creating a new kiosk menu
 */
export interface KioskMenuItemInputCreate {
  title: string
  description?: string | null
  menuType: KioskMenuItemMenuType
  image?: any | null
  puzzle?: any | null
  video?: any | null
  language?: string | null
  production?: boolean | null
}

/**
 * Data for updating an existing kiosk menu
 */
export interface KioskMenuItemInputUpdate {
  kioskMenuId?: string | null
  title?: string | null
  description?: string | null
  menuType?: KioskMenuItemMenuType | null
  image?: any | null
  puzzle?: any | null
  video?: any | null
  language?: string | null
  production?: boolean | null
}

/**
 * Less than or greater than date range filter object definition
 */
export interface LessThanGreaterThanDateFilter {
  op?: LTGTFilterOps | null
  date?: any | null
}

/**
 * Input for marking thread as seen
 */
export interface MarkNotificationThreadAsSeenInput {
  fallbackUrl?: string | null
  moduleKey?: string | null
  moduleSpecifierKey?: string | null
  moduleIdentifier?: string | null
}

/**
 * Object for creating records
 */
export interface MasterArticleInputCreate {
  title: string
  tags: string
  content: string
  version: number
  isGloballyAvailable: boolean
}

/**
 * Object for updating record
 */
export interface MasterArticleInputUpdate {
  title?: string | null
  tags?: string | null
  content?: string | null
  version?: number | null
  isGloballyAvailable?: boolean | null
}

/**
 * Object for creating records
 */
export interface MessageInputCreate {
  message: string
}

/**
 * Data for creating a new article
 */
export interface NewsArticleInputCreate {
  title: string
  topic: string
  image?: string | null
  publishedAt: any
  action?: string | null
  pushNotification?: boolean | null
}

/**
 * Data for updating one article
 */
export interface NewsArticleInputUpdate {
  title?: string | null
  topic?: string | null
  image?: string | null
  publishedAt?: any | null
  action?: string | null
  pushNotification?: boolean | null
}

/**
 * Data for updating an existing Order
 */
export interface OrderInputUpdate {
  billingFirstname?: string | null
  billingLastname?: string | null
  billingEmail?: string | null
  billingAddress?: string | null
  billingHouseNumber?: string | null
  billingCity?: string | null
  billingZip?: string | null
  billingPhone?: string | null
  billingCountry?: string | null
  deliveryId?: string | null
  deliveryCourierId?: string | null
  deliveryCourierDetail?: string | null
  deliveryAlias?: DeliveryAliasType | null
  deliveryTitle?: string | null
  deliveryPrice?: number | null
  deliveryVat?: number | null
  merchyouOrderStatus?: number | null
  merchyouOrderDate?: any | null
  merchyouOrderError?: string | null
  merchyouInvoiceStatus?: number | null
  merchyouInvoiceDate?: any | null
  merchyouInvoiceError?: string | null
  zasielkovnaBarcode?: string | null
  zasielkovnaId?: string | null
  language?: string | null
  currency?: string | null
  handoverStep?: number | null
  stripeStatus?: OrderStripeStatus | null
  paid?: number | null
  datePaid?: any | null
}

/**
 * Data for creating a new order item
 */
export interface OrderItemInputCreate {
  orderId: string
  productId: string
  productItemId: string
  quantity: number
  priceEur?: number | null
  priceCzk?: number | null
  vat?: number | null
}

/**
 * Data for updating an existing order item
 */
export interface OrderItemInputUpdate {
  orderId: string
  productId?: string | null
  productItemId?: string | null
  quantity?: number | null
  priceEur?: number | null
  priceCzk?: number | null
  vat?: number | null
}

/**
 * Object for putting records
 */
export interface PpeDocumentInputPut {
  title?: string | null
  url?: string | null
  type?: PpeDocumentType | null
  sysFileManagerFileId?: string | null
}

/**
 * Object for creating records
 */
export interface PpePostInputCreate {
  content: string
  expirationDate?: any | null
  imageUrl?: string | null
  location?: string | null
  shortDesc?: string | null
  state?: PpePostState | null
  title: string
  type: PpePostType
  unit?: string | null
  unitPrice?: number | null
  ownerId?: string | null
  stockQuantity?: any | null
  minimumOrderQuantity?: any | null
  categoryId?: string | null
  otg?: boolean | null
}

/**
 * Object for updating record
 */
export interface PpePostInputUpdate {
  content?: string | null
  expirationDate?: any | null
  imageUrl?: string | null
  location?: string | null
  shortDesc?: string | null
  state?: PpePostState | null
  title?: string | null
  type?: PpePostType | null
  unit?: string | null
  unitPrice?: number | null
  ownerId?: string | null
  stockQuantity?: any | null
  minimumOrderQuantity?: any | null
  categoryId?: string | null
  otg?: boolean | null
}

/**
 * Object for putting records
 */
export interface PpePostWarehouseInput {
  warehouseId: string
  quantity?: any | null
  status?: PpePostWarehouseStatus | null
}

/**
 * Object for creating records
 */
export interface PpeRatingInputCreate {
  toSysUserId?: string | null
  toPpePostId?: string | null
  review?: string | null
  rating: number
}

/**
 * Object for creating records
 */
export interface ProductInputCreate {
  title: string
}

/**
 * Object for updating record
 */
export interface ProductInputUpdate {
  title?: string | null
  ean?: string | null
  image?: any | null
  priceEur?: number | null
  priceCzk?: number | null
  vat?: number | null
  myDesignCode?: string | null
  myDesignPrice?: number | null
  mySewingPrice?: number | null
  myHangtagPrice?: number | null
  myUnweavePrice?: number | null
  position?: number | null
  production?: boolean | null
}

/**
 * Object for creating records
 */
export interface ProductItemInputCreate {
  code: string
  productId: string
  size: string
  warehouseStatus?: number | null
  printOnDemand: boolean
  textilePrice?: number | null
  sourceType?: ProductItemSourceType | null
  ean?: string | null
  production?: boolean | null
  position?: number | null
}

/**
 * Object for updating record
 */
export interface ProductItemInputUpdate {
  code?: string | null
  size?: string | null
  warehouseStatus?: number | null
  printOnDemand?: boolean | null
  textilePrice?: number | null
  sourceType?: ProductItemSourceType | null
  ean?: string | null
  position?: number | null
  production?: boolean | null
}

/**
 * Object for creating records
 */
export interface ProductPrintInputCreate {
  printLoc: string
  productId: string
  colSize?: string | null
  baseCol?: string | null
  pantone?: string | null
  printQty: number
  technology: string
  technique?: string | null
}

/**
 * Object for updating record
 */
export interface ProductPrintInputUpdate {
  printLoc?: string | null
  colSize?: string | null
  baseCol?: string | null
  pantone?: string | null
  printQty?: number | null
  technology?: string | null
  technique?: string | null
}

/**
 * Object for creating records
 */
export interface ProductsContentInputCreate {
  productId: number
  title?: string | null
  content?: string | null
  language?: string | null
}

/**
 * Object for updating record
 */
export interface ProductsContentInputUpdate {
  title?: string | null
  content?: string | null
  language?: string | null
}

/**
 * Object for creating records
 */
export interface ProductsGalleryItemMassInputCreate {
  images: any[]
  productId: string
}

/**
 * Object for updating record
 */
export interface SecondStageVerificationBusinessRoleInput {
  businessRoleId: string
  specification?: string | null
}

/**
 * Object for updating record
 */
export interface SecondStageVerificationDigitalIdentityInput {
  title: string
  url: string
}

/**
 * Object for creating records
 */
export interface SecondStageVerificationInputCreate {
  sysUserId: string
  tradingGoodsSince?: number | null
  isPpeMain?: boolean | null
  businessAreaDescription?: string | null
  isInternational?: boolean | null
  internationalSince?: number | null
  supplyChainPositionDescription?: string | null
  activeInPpeSince?: number | null
  pandemicInfluenceOpinion?: string | null
  stayAfterPandemic?: boolean | null
  biggestConcernPpe?: string | null
  hasLastBusiness?: boolean | null
  lastBusinessAt?: any | null
  typeOfLastBusiness?: PpePostType | null
  lastBusinessProduct?: string | null
  lastBusinessProductWorth?: number | null
  lastBusinessPartner?: string | null
  lastBusinessPartnerReference?: string | null
  lastBusinessPartnerWouldAgain?: boolean | null
  lastBusinessPartnerWouldAgainDescription?: string | null
  averageAnnualTurnover?: number | null
  averageAnnualTurnoverPpePercentage?: number | null
  averagePpeDealPrice?: number | null
  averageAnnualPpeDealsCount?: number | null
  additionalUserInput?: string | null
  lastShownStep?: number | null
  marketLocations?: SecondStageVerificationMarketLocationInput[] | null
  businessRoles?: SecondStageVerificationBusinessRoleInput[] | null
  digitalIdentities?: SecondStageVerificationDigitalIdentityInput[] | null
}

/**
 * Object for updating record
 */
export interface SecondStageVerificationInputUpdate {
  tradingGoodsSince?: number | null
  isPpeMain?: boolean | null
  businessAreaDescription?: string | null
  isInternational?: boolean | null
  internationalSince?: number | null
  supplyChainPositionDescription?: string | null
  activeInPpeSince?: number | null
  pandemicInfluenceOpinion?: string | null
  stayAfterPandemic?: boolean | null
  biggestConcernPpe?: string | null
  hasLastBusiness?: boolean | null
  lastBusinessAt?: any | null
  typeOfLastBusiness?: PpePostType | null
  lastBusinessProduct?: string | null
  lastBusinessProductWorth?: number | null
  lastBusinessPartner?: string | null
  lastBusinessPartnerReference?: string | null
  lastBusinessPartnerWouldAgain?: boolean | null
  lastBusinessPartnerWouldAgainDescription?: string | null
  averageAnnualTurnover?: number | null
  averageAnnualTurnoverPpePercentage?: number | null
  averagePpeDealPrice?: number | null
  averageAnnualPpeDealsCount?: number | null
  additionalUserInput?: string | null
  lastShownStep?: number | null
  marketLocations?: SecondStageVerificationMarketLocationInput[] | null
  businessRoles?: SecondStageVerificationBusinessRoleInput[] | null
  digitalIdentities?: SecondStageVerificationDigitalIdentityInput[] | null
}

/**
 * Object for updating record
 */
export interface SecondStageVerificationMarketLocationInput {
  marketLocationId: string
  specification?: string | null
}

/**
 * Object for creating new access group
 */
export interface SysAccessGroupInputCreate {
  title: string
  description?: string | null
  superior: string
}

/**
 * Object for updating access group
 */
export interface SysAccessGroupInputUpdate {
  title?: string | null
  description?: string | null
}

/**
 * Object for creating records
 */
export interface SysDashboardWidgetInputCreate {
  row: number
  widgetAlias: string
  startingPosition: number
  width: number
}

/**
 * Object for updating record
 */
export interface SysDashboardWidgetInputUpdate {
  row?: number | null
  widgetAlias?: string | null
  startingPosition?: number | null
  width?: number | null
}

/**
 * Object for creating records
 */
export interface SysFileManagerFileInputCreate {
  id?: string | null
  sysFileManagerFolderId?: string | null
  filename: string
  contentType: string
  size: number
}

/**
 * Object for creating records
 */
export interface SysFileManagerFileInputUpdate {
  filename: string
}

/**
 * Object for creating records
 */
export interface SysFileManagerFolderInputCreate {
  parentSysFileManagerFolderId?: string | null
  name: string
}

/**
 * Object for updating record
 */
export interface SysFileManagerFolderInputUpdate {
  parentSysFileManagerFolderId?: string | null
  name?: string | null
}

/**
 * Object for creating new option
 */
export interface SysLanguageInputCreate {
  codeShort: string
  code: string
  isDefault: boolean
  title: string
}

/**
 * Object for updating option
 */
export interface SysLanguageInputUpdate {
  codeShort?: string | null
  code?: string | null
  title?: string | null
  isDefault?: boolean | null
}

/**
 * Object for creating new record
 */
export interface SysLanguageKeyInputCreate {
  languageKey: string
  description?: string | null
}

/**
 * Object for updating record
 */
export interface SysLanguageKeyInputUpdate {
  languageKey?: string | null
  description?: string | null
}

/**
 * Object for creating new option
 */
export interface SysSettingsOptionInputCreate {
  title: string
  alias: string
  parent?: string | null
}

/**
 * Object for updating option
 */
export interface SysSettingsOptionInputUpdate {
  title?: string | null
  alias?: string | null
  parent?: string | null
}

/**
 * Object for creating new record
 */
export interface SysSettingsOptionItemInputCreate {
  title: string
  alias: string
  validation?: string | null
  dataSource?: string | null
}

/**
 * Object for updating record
 */
export interface SysSettingsOptionItemInputUpdate {
  title?: string | null
  alias?: string | null
  validation?: string | null
  dataSource?: string | null
}

/**
 * Definition to set new option item value
 */
export interface SysSettingsOptionValueInput {
  id: string
  value: string
}

/**
 * Object for creating records
 */
export interface SysTagInputCreate {
  tag: string
  description?: string | null
}

/**
 * Object for updating record
 */
export interface SysTagInputUpdate {
  tag?: string | null
  description?: string | null
}

/**
 * Object for creating records
 */
export interface SysTemplateInputCreate {
  title: string
  description?: string | null
  key: string
}

/**
 * Object for updating record
 */
export interface SysTemplateInputUpdate {
  title?: string | null
  description?: string | null
  key?: string | null
}

/**
 * Object for updating record
 */
export interface SysUserContactInput {
  value: string
  contactTypeId: string
}

/**
 * Object for creating new user
 */
export interface SysUserInputCreate {
  name?: string | null
  middleName?: string | null
  surname?: string | null
  educationTitle?: string | null
  emailPrimary: string
  emailAlternative?: string | null
  phonePrimary?: string | null
  bio?: string | null
  sysAccessGroup: string
  supervisor?: string | null
  address?: string | null
  receiveMessageSummaryEmail?: boolean | null
  receiveDocumentsSummaryEmail?: boolean | null
  receiveSummaryEmail?: boolean | null
  receiveProfileFulfillmentEmail?: boolean | null
  receiveWelcomeEmail?: boolean | null
  contractTypeId?: string | null
}

/**
 * Object for updating user
 */
export interface SysUserInputUpdate {
  name?: string | null
  middleName?: string | null
  surname?: string | null
  educationTitle?: string | null
  emailAlternative?: string | null
  phonePrimary?: string | null
  profilePictureSysFile?: any | null
  bio?: string | null
  supervisor?: string | null
  address?: string | null
  sysAccessGroupId?: string | null
  billingAddressStreet?: string | null
  billingAddressCity?: string | null
  billingAddressZip?: string | null
  billingAddressCountry?: string | null
  billingAddressCountryId?: string | null
  billingAddressState?: string | null
  dismissedWelcomeWidget?: boolean | null
  receiveMessageSummaryEmail?: boolean | null
  receiveDocumentsSummaryEmail?: boolean | null
  receiveSummaryEmail?: boolean | null
  receiveProfileFulfillmentEmail?: boolean | null
  receiveWelcomeEmail?: boolean | null
  contractTypeId?: string | null
}

/**
 * Object for creating records
 */
export interface SysUserVerificationInputCreate {
  verificationDocumentFront: any
  verificationDocumentBack?: any | null
  documentType: SysUserVerificationDocumentType
}

/**
 * Object for creating records - either sysUserId or ppePostId required (provide exactly one)
 */
export interface ViolationReportInputCreate {
  text?: string | null
  sysUserId?: string | null
  ppePostId?: string | null
  reportingCategoryId: string
}

/**
 * Object for updating record
 */
export interface ViolationReportInputUpdate {
  reportingCategoryId?: string | null
  underInvestigation?: boolean | null
}

/**
 * Data for updating voucher details
 */
export interface VoucherInputUpdate {
  giftId?: string | null
  value?: string | null
}

/**
 * Object for putting records
 */
export interface WarehouseFileInput {
  fileLink?: string | null
  sysFileManagerFileId?: string | null
}

/**
 * Object for creating records
 */
export interface WarehouseInputCreate {
  ownerId: string
  countryId: string
  city: string
  state?: string | null
  street: string
  zip?: string | null
  category?: WarehouseCategories | null
}

/**
 * Object for updating record
 */
export interface WarehouseInputUpdate {
  countryId?: string | null
  city?: string | null
  state?: string | null
  street?: string | null
  zip?: string | null
  category?: WarehouseCategories | null
}

/**
 * Object for creating records
 */
export interface WarehouseVerificationInputCreate {
  verificationDocument?: any | null
  verificationType: WarehouseVerificationType
  userCoordinates?: CoordinatesInput | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
