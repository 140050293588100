// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormFieldLabel_root_rRC03{line-height:12px;line-height:20px;font-size:inherit;color:#2b2b2b;white-space:nowrap;text-overflow:ellipsis;overflow:hidden}.FormFieldLabel_root_rRC03.FormFieldLabel_inverted_sFn0T{color:#ffffff}\n", ""]);
// Exports
exports.locals = {
	"root": "FormFieldLabel_root_rRC03",
	"inverted": "FormFieldLabel_inverted_sFn0T"
};
module.exports = exports;
