// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ExpandableBox_root_KH86d{width:100%;height:0;max-height:100%;transition:height .3s ease;overflow-x:hidden;overflow-y:auto;position:relative}.ExpandableBox_root_KH86d>div{position:absolute;left:0;right:0}.ExpandableBox_root_KH86d.ExpandableBox_topToBottom_3yBv9>div{top:0}.ExpandableBox_root_KH86d.ExpandableBox_bottomToTop_i2rIv>div{bottom:0}\n", ""]);
// Exports
exports.locals = {
	"root": "ExpandableBox_root_KH86d",
	"topToBottom": "ExpandableBox_topToBottom_3yBv9",
	"bottomToTop": "ExpandableBox_bottomToTop_i2rIv"
};
module.exports = exports;
