// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".FormFieldValidationLabel_root_1ntBZ{position:absolute;right:15px;vertical-align:top;line-height:20px}.FormFieldValidationLabel_root_1ntBZ.FormFieldValidationLabel_popup_EI5Cd{align-items:flex-start}.FormFieldValidationLabel_root_1ntBZ a{display:block;outline:none;text-decoration:none}.FormFieldValidationLabel_root_1ntBZ a:hover>i,.FormFieldValidationLabel_root_1ntBZ a:focus>i,.FormFieldValidationLabel_root_1ntBZ a:active>i{color:#0091ff}.FormFieldValidationLabel_root_1ntBZ .FormFieldValidationLabel_label_1RIT7{line-height:inherit;width:8px;height:8px;border-radius:50%}.FormFieldValidationLabel_root_1ntBZ.FormFieldValidationLabel_valid_1zg4b .FormFieldValidationLabel_label_1RIT7{background-color:#28ca42}.FormFieldValidationLabel_root_1ntBZ.FormFieldValidationLabel_invalid_2nLsP .FormFieldValidationLabel_label_1RIT7{background-color:#ff6059}\n", ""]);
// Exports
exports.locals = {
	"root": "FormFieldValidationLabel_root_1ntBZ",
	"popup": "FormFieldValidationLabel_popup_EI5Cd",
	"label": "FormFieldValidationLabel_label_1RIT7",
	"valid": "FormFieldValidationLabel_valid_1zg4b",
	"invalid": "FormFieldValidationLabel_invalid_2nLsP"
};
module.exports = exports;
