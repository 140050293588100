import React, { Fragment } from 'react'

import { ISelectOption } from 'components/form/_partials/FormFieldSelectBase/Partials/FormFieldSelectBaseContent'

/**
 * Convert first letter to uppercase
 */
export const uppercaseFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

/**
 * Function to add http protocol if needed
 */
export const addHttpIfNeeded = (url: string) => {
  let newUrl = url
  if (!/^(?:f|ht)tps?:\/\//.test(url)) {
    newUrl = `http://${url}`
  }
  return newUrl
}

/**
 * Convert to number and remove spaces between numbers
 */
export const toNumber = (text: string | number): number => {
  return Number(text.toString().replace(/\s/g, ''))
}

/**
 * Convert text to camel case
 */
export const toCamelCase = (str: string) => {
  return str
    .split(/\s+/g)
    .map((word) => uppercaseFirstLetter(word))
    .join('')
}

/**
 * Convert newline to html line break
 */
export const nl2br = (str: string | null) => {
  if (!str) {
    return null
  }

  return str.split('\n').map((item, key) => (
    // eslint-disable-next-line
    <Fragment key={key}>
      {item}
      <br />
    </Fragment>
  ))
}

/**
 * Sets characted at specific index to the character given.
 * @param moduleName Name of current module
 * @param categories Ordered list of module category names which specifies the path to current page
 */
export const preparePageTitle = (
  moduleName: string,
  categories: string | (string | null)[] | null = null
) => {
  const domain = 'Kiosks'

  if (!moduleName) {
    return domain
  }

  const moduleNameLowerCase = toCamelCase(moduleName)

  let categoriesArray = Array.isArray(categories) ? categories : [categories]
  categoriesArray = categoriesArray ? categoriesArray.filter((c) => !!c) : []
  const categoriesString =
    categoriesArray.length > 0 ? ` [${categoriesArray.filter((c) => !!c).join(' - ')}]` : ''

  return `${moduleNameLowerCase}.app${categoriesString} | ${domain}`
}

/**
 * Sets characted at specific index to the character given.
 * @param str Source string.
 * @param index Index of the character to be replaced in the source string.
 * @param chr New character / string which will be placed at the given index.
 */
export const setCharAt = (str: string, index: number, chr: string) => {
  if (index > str.length - 1) return str
  return str.substr(0, index) + chr + str.substr(index + 1)
}

/**
 * Sets the native value of the input element.
 * @param element Input element ref.
 * @param value New value.
 */
export const setNativeValue = (element: any, value: any) => {
  const prototype = Object.getPrototypeOf(element)
  const prototypeValueSetter = (Object as any).getOwnPropertyDescriptor(prototype, 'value').set
  const valueSetter = (Object as any).getOwnPropertyDescriptor(element, 'value').set

  if (valueSetter && valueSetter !== prototypeValueSetter) {
    prototypeValueSetter.call(element, value)
  } else {
    valueSetter.call(element, value)
  }
}

/**
 * Transforms number to string with insignificant leading zeros.
 * @param desiredLength The final length the string is expected to have, including the decimal separator.
 * @param number The number to be converted.
 * @returns Number with leading zeros.
 */
export const prefixWithZeros = (desiredLength: number, number: number) => {
  return `${Array(desiredLength)
    .fill(0)
    .splice(0, desiredLength - number.toString().length)
    .join()
    .replace(/,/g, '')}${number}`
}

/**
 * Check if proprety is function
 * @param func Property to check
 */
export const isFunction = (func: any) => {
  return typeof func === 'function'
}

/**
 * Run callback if it is callable
 * @param  callback Function to run if it is a function
 * @param  args Argument which we pass to callback function
 */
export const runCallback = (callback: any, ...args: any[]) => {
  return isFunction(callback) ? callback.apply(this, args) : undefined
}

/**
 * Method to stringify js error
 * @param  error Error to stringify
 */
export const stringifyError = (error: Error) => {
  Object.defineProperty(error, 'toJSON', {
    configurable: true,
    value() {
      const alt = {}

      Object.getOwnPropertyNames(this).forEach(function (key) {
        alt[key] = this[key]
      }, this)

      return alt
    },
    writable: true,
  })

  return JSON.stringify(error)
}

/**
 * Function to format a large number input
 */
export const formatNumber = (labelValue: number) => {
  return Number(labelValue) >= 1.0e9
    ? Number(labelValue) % 1.0e9 === 0
      ? `${Number(labelValue) / 1.0e9}B`
      : Number(labelValue) % 1.0e9 < 100000000
      ? `${Math.floor(Number(labelValue) / 1.0e9)}B`
      : `${(Number(labelValue) / 1.0e9).toFixed(1)}B`
    : Number(labelValue) >= 1.0e6
    ? Number(labelValue) % 1.0e6 === 0
      ? `${Number(labelValue) / 1.0e6}M`
      : Number(labelValue) % 1.0e6 < 100000
      ? `${Math.floor(Number(labelValue) / 1.0e6)}M`
      : `${(Number(labelValue) / 1.0e6).toFixed(1)}M`
    : Number(labelValue) >= 1.0e3
    ? Number(labelValue) % 1.0e3 === 0
      ? `${Number(labelValue) / 1.0e3}K`
      : Number(labelValue) % 1.0e3 < 100
      ? `${Math.floor(Number(labelValue) / 1.0e3)}K`
      : `${(Number(labelValue) / 1.0e3).toFixed(1)}K`
    : Number(labelValue)
}

export const parseNumberValueForAPI = (
  value: string | number,
  replaceDotWithComma?: boolean
): number => {
  return Number(
    Number(
      String(value).replace(replaceDotWithComma ? '.' : ',', replaceDotWithComma ? ',' : '.')
    ).toFixed(2)
  )
}

export const parseDecimalValueForInput = (val: number | string | null | undefined) => {
  return !!val && !isNaN(Number(val)) ? Number(val).toFixed(2).replace('.', ',') : ''
}

export const proccessOptionsToDictionary = (options: ISelectOption[]) => {
  return options.reduce((acc, i) => ({ ...acc, [i.value]: i.title }), {})
}
