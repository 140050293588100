// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".ListWithSearch_root_1zJ1r{display:flex;flex-direction:column;max-height:100%}.ListWithSearch_root_1zJ1r hr{flex:0}.ListWithSearch_root_1zJ1r .ListWithSearch_resultsWrap_1zPvq{flex:1;overflow-y:auto}.ListWithSearch_wSearchBar_1E-ND{flex-shrink:0 !important;margin:8px 25px !important}\n", ""]);
// Exports
exports.locals = {
	"root": "ListWithSearch_root_1zJ1r",
	"resultsWrap": "ListWithSearch_resultsWrap_1zPvq",
	"wSearchBar": "ListWithSearch_wSearchBar_1E-ND"
};
module.exports = exports;
