// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, "\n", ""]);
// Exports
exports.locals = {
	"primary": "#0091ff",
	"success": "#28ca42",
	"warning": "#ffbd2e",
	"danger": "#ff6059",
	"light": "#b4b4b4",
	"neutral": "#a6a6a6",
	"txtDark": "#2b2b2b",
	"linkSelected": "#bfbfbf",
	"animationSpeed": ".3s",
	"bgDefault": "#fff",
	"bgAccent": "#2b2b2b",
	"bgConceal": "#e6e6e6"
};
module.exports = exports;
