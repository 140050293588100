// core
import React, { ReactNode, useCallback } from 'react'

// libraries
import classnames from 'classnames'

// ambitas
import { Icon } from '@ambitas/dotapp-components'

// components
import { IDotappComponentProps } from 'components'
import { FancyIcon, FancyIconNames } from 'components/basic/FancyIcon'
import { Button } from 'components/complex/Button'

// styles
import * as css from '../Toast.scss'

export interface IToastProps extends IDotappComponentProps {
  /**
   * function to happen when close button is clicked
   */
  onClose: (id: string) => void
  /**
   * color of toast
   */
  color?: 'transparent' | 'success' | 'danger' | 'warning' | 'primary'
  /**
   * title of toast
   */
  title: ReactNode | string
  /**
   *  text to display under title
   */
  description?: string | ReactNode
  /**
   *  identificator for toast
   */
  id: string
  /**
   *  which icon should be shown
   */
  icon: string
  /**
   *  color of icon
   */
  iconColor?: 'success' | 'danger' | 'warning' | 'primary' | 'white' | 'txt'
  /**
   * automatic prop status for animation purposes
   */
  status?: 'hide' | 'show'
  /**
   * if toast should be in center
   */
  center?: boolean
}

export const Toast = ({
  color,
  title,
  description,
  id,
  center,
  icon,
  iconColor = 'white',
  status,
  onClose,
  className,
  style,
}: IToastProps) => {
  const handleClose = useCallback(() => {
    onClose(id)
  }, [onClose, id])

  return (
    <div
      className={classnames(
        css.toastRoot,
        center ? css.center : css[color || 'transparent'],
        css[status || 'show'],
        className
      )}
      style={style}
    >
      {center ? (
        <FancyIcon
          inverted
          className={classnames(css.fancyIcon, { [css[iconColor]]: iconColor })}
          name={icon as FancyIconNames}
        />
      ) : (
        <div className={css.toastIcon}>
          <Icon className={classnames({ [css[iconColor]]: iconColor })} name={icon} />
        </div>
      )}

      <div className={classnames({ 'mr-3 overflow-hidden': !center })}>
        <div className={css.toastTitle}>{title}</div>
        {description && <div className={css.toastMessage}>{description}</div>}
      </div>

      {!center && (
        <Button
          contained
          className={css.closeButton}
          color="transparent"
          iconLeft="close"
          size="small"
          variant="icon"
          onClick={handleClose}
        />
      )}
    </div>
  )
}
