// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".NoResultsMessage_root_1cYFr{height:60px;padding:25px 0;margin:0;line-height:20px;display:flex}.NoResultsMessage_root_1cYFr.NoResultsMessage_center_2stc6{justify-content:center}.NoResultsMessage_root_1cYFr.NoResultsMessage_left_rnnM2{justify-content:flex-start}\n", ""]);
// Exports
exports.locals = {
	"root": "NoResultsMessage_root_1cYFr",
	"center": "NoResultsMessage_center_2stc6",
	"left": "NoResultsMessage_left_rnnM2"
};
module.exports = exports;
