// core
import React, { Ref, useCallback, useContext, useEffect, useRef } from 'react'

// formik
import { useFormikContext } from 'formik'

// components
import { Button } from 'components/complex/Button'
import { SearchInput } from 'components/complex/SearchInput/SearchInput'
import { getTranslation } from 'components/complex/Translation'

// utils
import { runCallback } from 'utils/functions'

import { SearchContext } from '../useSearch'

// styles
import * as css from '../Search.scss'

// translate

export interface ISearchFormProps {
  /**
   * Callback to fun on focus
   */
  onFocus?: () => any
  /**
   * Callback to fun on blur
   */
  onBlur?: () => any
  /**
   * Input field reference
   */
  inputRef: Ref<HTMLInputElement>
}

export const SearchForm = ({ inputRef, onFocus, onBlur }: ISearchFormProps) => {
  const timeout = useRef<any>()

  const searchApi = useContext(SearchContext)
  const formik = useFormikContext()

  // clear value on reset
  useEffect(() => {
    if (!searchApi.value) {
      formik.setFieldValue('value', '')
    }
  }, [!!searchApi.value])

  const handleFocus = useCallback(() => {
    searchApi.setFocus(true)
    runCallback(onFocus)
  }, [searchApi, onFocus])

  const handleSearchTypeToggleClick = useCallback(() => {
    searchApi.setGlobal(!searchApi.global)
  }, [searchApi])

  const handleChange = useCallback(
    (value: string) => {
      formik.setFieldValue('value', value)

      if (timeout.current) {
        clearTimeout(timeout.current)
      }

      timeout.current = setTimeout(() => {
        formik.submitForm()
      }, 300)
    },
    [formik, timeout]
  )

  const handleClose = useCallback(() => {
    formik.setFieldValue('value', '')
    searchApi.setValue(null)
    runCallback(onBlur)
  }, [formik, searchApi, onBlur])

  return (
    <div className={css.inputWrap}>
      <SearchInput
        ref={inputRef}
        name="value"
        placeholder={
          searchApi.global
            ? getTranslation('general.search_global')
            : getTranslation('general.search_local')
        }
        rightRender={
          /* temporary hide global search */
          false && searchApi.focused && searchApi.localSearchAvailable ? (
            <Button
              className={css.searchTypeToggle}
              color={searchApi.global ? 'primary' : 'neutral'}
              size="small"
              variant="text"
              onClick={handleSearchTypeToggleClick}
            >
              Global
            </Button>
          ) : undefined
        }
        onBlur={onBlur}
        onChange={handleChange}
        onFocus={handleFocus}
      />

      {searchApi.focused || searchApi.value ? (
        <Button
          contained
          className={css.closeButton}
          color="transparent"
          iconLeft="close"
          size="small"
          variant="icon"
          onClick={handleClose}
        />
      ) : null}
    </div>
  )
}
