// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".MainMenu_root_3wOq_{color:#ffffff;flex:1;overflow-y:auto}.MainMenu_root_3wOq_ ul{margin:0;padding:0;list-style:none}.MainMenu_root_3wOq_ a{text-decoration:none;outline:none;color:inherit}.MainMenu_root_3wOq_ hr{border-color:rgba(255,255,255,0.2);margin:20px 20px 10px 20px}\n", ""]);
// Exports
exports.locals = {
	"root": "MainMenu_root_3wOq_"
};
module.exports = exports;
