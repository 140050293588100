// core
import React, { forwardRef, Ref, useEffect, useRef } from 'react'

// libraries
import classnames from 'classnames'

// components
import { IDotappWrapperComponentProps } from 'components'
import { ExpandableBox, ExpandableBoxExpandDirection } from 'components/basic/ExpandableBox'
import { Paper } from 'components/basic/Paper'

// styles
import * as css from '../Popover.scss'

export type PopoverSide = 'left' | 'right'

export interface IPopoverComponentProps extends IDotappWrapperComponentProps {
  /**
   * Invert colors
   */
  inverted?: boolean
  /**
   * Whether to show content
   */
  open: boolean
  /**
   * From which side to open
   */
  side?: PopoverSide
  /**
   * Width of popover
   */
  width?: number
  /**
   * Direction to which the box should be expanded
   */
  expandDirection?: ExpandableBoxExpandDirection
}

export const PopoverComponent = forwardRef(
  (
    {
      inverted = false,
      open,
      side = 'left',
      width = 400,
      className,
      expandDirection = 'topToBottom',
      children,
      classes = {},
    }: IPopoverComponentProps,
    contentRef: Ref<HTMLDivElement>
  ) => {
    const rootRef = useRef<HTMLDivElement>(null)

    // we have to user Ref to run initial css animation on width
    useEffect(() => {
      if (rootRef.current) {
        rootRef.current.style.width = open ? `${width}px` : '0'
      }
    }, [open, children])

    return (
      <div
        ref={rootRef}
        className={classnames(
          {
            [css.open]: open,
          },
          css[side],
          className,
          classes.root
        )}
        data-testid="root"
      >
        <Paper
          className={classnames(css.paper, css[expandDirection], classes.paper)}
          elevation={2}
          inverted={inverted}
        >
          <ExpandableBox
            fade
            className={classes.expandableBox}
            expandDirection={expandDirection}
            expanded={open}
          >
            <div
              ref={contentRef}
              className={classnames(css.content, classes.content)}
              data-testid="content"
              style={{ width }}
            >
              {children}
            </div>
          </ExpandableBox>
        </Paper>
      </div>
    )
  }
)
