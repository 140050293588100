import { gql } from '@apollo/client'

export const LayoutFragments = {
  MENU_ITEM: gql`
    fragment MenuItemFragment on SysMenu {
      id
      menuTitle
      url
      icon
    }
  `,

  USER: gql`
    fragment UserInfoHeaderFragment on SysUser {
      id
      fullName
      emailPrimary
      profilePictureSysFile {
        id
        fileUrl
      }
      dismissedWelcomeWidget
      slug
      subscriptionStatus
      remainingMessagesCount
      subscriptions {
        id
        activeSince
        activeUntil
        status
        type {
          id
          monthsAmount
        }
      }
      verificationStatus
    }
  `,
}
