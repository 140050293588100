// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".Modal_container_pb-PC{position:absolute;top:0;bottom:0;left:0;right:0;overflow:hidden}.Modal_root_1fjJN{position:absolute;opacity:0;visibility:hidden;transition:opacity .3s linear,visibility 0s linear .3s}.Modal_root_1fjJN.Modal_open_2tx6u{opacity:1;visibility:visible;transition:opacity .3s linear}@media (max-width: 575.98px){.Modal_root_1fjJN{position:absolute !important;width:100% !important;height:auto !important;max-height:unset !important;top:unset !important;bottom:0 !important;left:0 !important;right:0 !important}}\n", ""]);
// Exports
exports.locals = {
	"container": "Modal_container_pb-PC",
	"root": "Modal_root_1fjJN",
	"open": "Modal_open_2tx6u"
};
module.exports = exports;
