// core
import React, { memo, useCallback, useContext, useEffect, useState } from 'react'

// libraries
import classnames from 'classnames'

// apollo
import { useLazyQuery } from '@apollo/client'
import { LayoutQueries } from 'queries/Layout/LayoutQueries'

// data types
import { GetUserMenuData, GetUserMenuDataVariables } from 'queries/Layout/types/GetUserMenuData'

import { Badge } from 'components/basic/Badge/Badge'
// hooks
import { BreakpointNames, useWindowWidth } from 'components/basic/Hidden/useWindowWidth'
// components
import { ITab } from 'components/complex/Tabs/Tabs'

// partials
import { Search } from 'layouts/Search/Search'
import { UserAvatar } from 'layouts/UserAvatar/UserAvatar'

import { LoggedInUserContext } from '../../index'
import { SidePanelContext } from '../Layout'

// styles
import * as css from './Header.scss'

interface HeaderProps {
  /**
   * function to select tab
   */
  tabSelect: (tab: ITab) => void
}

export const Header = memo(function Header({ tabSelect }: HeaderProps) {
  const { loggedInUser } = useContext(LoggedInUserContext)
  const { openSidePanel } = useContext(SidePanelContext)

  const { windowWidth } = useWindowWidth()

  const [searchFocused, setSearchFocused] = useState<boolean>(false)

  const [getUser, { loading, data }] = useLazyQuery<GetUserMenuData, GetUserMenuDataVariables>(
    LayoutQueries.GET_USER_MENU_DATA
  )

  useEffect(() => {
    if (loggedInUser) {
      getUser({
        variables: { id: loggedInUser.id },
      })
    }
  }, [loggedInUser])

  const handleProfileClick = useCallback(() => {
    tabSelect({ key: 'userMenu', title: '' })
    openSidePanel('userMenu')
  }, [])

  const handleSearchFocus = useCallback(() => {
    setSearchFocused(true)
  }, [])

  const handleSearchBlur = useCallback(() => {
    setSearchFocused(false)
  }, [])

  const isMobile = windowWidth === BreakpointNames.xs || windowWidth === BreakpointNames.sm

  return (
    <header className={classnames(css.root, searchFocused ? css.searchFocused : '')}>
      <div className={css.searchWrap}>
        <Search onBlur={handleSearchBlur} onFocus={handleSearchFocus} />
      </div>

      <div className={css.rightIcons}>
        {/* profile controls wrapper */}
        <Badge
          classes={{ badge: 'd-md-none' }}
          content={
            isMobile && data?.unseenNotifications.totalCount
              ? data?.unseenNotifications.totalCount
              : null
          }
          side="left"
        >
          <UserAvatar
            error={!loading && (!data || !data.sysUser)}
            loading={loading}
            profilePicture={data?.sysUser?.profilePictureSysFile?.fileUrl}
            onClick={handleProfileClick}
          />
        </Badge>
      </div>
    </header>
  )
})
