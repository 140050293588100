import { useCallback, useMemo } from 'react'

export enum BreakpointNames {
  xl = 'xl',
  lg = 'lg',
  md = 'md',
  sm = 'sm',
  xs = 'xs',
}

export const breakpoints = [576, 768, 992, 1200]
export type BreakpointType = 'xs' | 'sm' | 'md' | 'lg' | 'xl'

export function useWindowWidth(): {
  windowWidth: BreakpointNames
  isSmallerThan: (breakpoints: BreakpointType) => boolean
  isGreaterThan: (breakpoints: BreakpointType) => boolean
} {
  const breakpointsOrder = ['xs', 'sm', 'md', 'lg', 'xl']

  const windowWidth = useMemo<BreakpointNames>(() => {
    const width = window.innerWidth

    switch (true) {
      case width >= breakpoints[3]:
        return BreakpointNames.xl
      case width >= breakpoints[2]:
        return BreakpointNames.lg
      case width >= breakpoints[1]:
        return BreakpointNames.md
      case width >= breakpoints[0]:
        return BreakpointNames.sm
      default:
        return BreakpointNames.xs
    }
  }, [])

  const isSmallerThan = useCallback(
    (breakpoint: BreakpointType) => {
      return breakpointsOrder.indexOf(breakpoint) > breakpointsOrder.indexOf(windowWidth)
    },
    [breakpointsOrder, windowWidth]
  )

  const isGreaterThan = useCallback(
    (breakpoint: BreakpointType) => {
      return breakpointsOrder.indexOf(breakpoint) < breakpointsOrder.indexOf(windowWidth)
    },
    [breakpointsOrder, windowWidth]
  )

  return { isGreaterThan, isSmallerThan, windowWidth }
}
