// core
import React, { MouseEvent } from 'react'

// libraries
import classnames from 'classnames'
import { NavLink } from 'react-router-dom'

// components
import { IDotappWrapperComponentProps } from 'components'

// utils
import { runCallback } from 'utils/functions'

// styles
import * as css from './Link.scss'

export interface ILinkProps extends IDotappWrapperComponentProps {
  /**
   * Styling of the link, default theme color will be used if none specified.
   */
  color?: 'primary' | 'white' | 'light' | 'neutral' | 'success' | 'warning' | 'danger'
  /**
   * Link to be redirected to
   */
  to?: string
  /**
   * Redirection to link after click
   */
  onClick?: (event: MouseEvent<HTMLAnchorElement>) => void
  /**
   * rel of link
   */
  rel?: string
  /**
   * target of link
   */
  target?: string
  /**
   * Title html attribute
   */
  title?: string
}

export function Link({
  color,
  children,
  to,
  className,
  onClick,
  title,
  ...passingProps
}: ILinkProps) {
  const handleClick = (event: MouseEvent<HTMLAnchorElement>) => {
    event.preventDefault()
    event.stopPropagation()

    runCallback(onClick, event)
  }

  const classNameString = classnames(css.root, color && css[color], className)

  return to ? (
    <NavLink className={classNameString} title={title} to={to} onClick={onClick} {...passingProps}>
      {children || to}
    </NavLink>
  ) : (
    <a className={classNameString} href="#" title={title} onClick={handleClick} {...passingProps}>
      {children || to}
    </a>
  )
}
