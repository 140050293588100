import { createContext, useContext, useEffect, useMemo, useReducer, useRef } from 'react'

type State = {
  /**
   * Whether search is focused
   */
  focused: boolean
  /**
   * Current search value
   */
  value?: string | null
  /**
   * Whether global search is enabled
   */
  global: boolean
  /**
   * Whether it is possible to use local search
   */
  localSearchAvailable: boolean
}

export type SearchContextOptions = State & {
  /**
   * Set search value
   */
  setValue: (value: string | null) => void
  /**
   * Set global search
   */
  setGlobal: (global: boolean) => void
  /**
   * Set focus to search field
   */
  setFocus: (focused: boolean) => void
  /**
   * Enable or disable local search
   */
  enableLocalSearch: (enabled: boolean) => void
  /**
   * Reset search to initial values
   */
  reset: () => any
}

const defaultOptions = {
  enableLocalSearch: () => {
    // do nothing
  },
  focused: false,
  global: true,
  localSearchAvailable: false,
  reset: () => {
    // do nothing
  },
  setFocus: () => {
    // do nothing
  },
  setGlobal: () => {
    // do nothing
  },
  setValue: () => {
    // do nothing
  },
  value: null,
}

export const SearchContext = createContext<SearchContextOptions>(defaultOptions)

function reducer(
  state: State,
  {
    type,
    value,
  }:
    | { type: 'value'; value: string | null }
    | { type: 'global'; value: boolean }
    | { type: 'focus'; value: boolean }
    | { type: 'localSearch'; value: boolean }
    | { type: 'reset'; value: undefined }
) {
  switch (type) {
    case 'value':
      return { ...state, value: value ? value.toString() : null }
    case 'localSearch':
      return {
        ...state,
        focus: value ? state.focused : false,
        global: !value,
        localSearchAvailable: !!value,
        value: value ? state.value : null,
      }
    case 'global':
      return { ...state, global: !state.localSearchAvailable || !!value }
    case 'focus':
      return { ...state, focused: !!value }
    case 'reset':
      return {
        ...state,
        focus: false,
        global: true,
        localSearchAvailable: false,
        value: undefined, // set to undefined to let component initialize value form local storage
      }
    default:
      throw new Error()
  }
}

export function useSearchProvider(): SearchContextOptions {
  const [searchApi, dispatch] = useReducer(reducer, {
    focused: false,
    global: true,
    localSearchAvailable: false,
    value: undefined,
  })

  const contextOptions = useMemo(
    () => ({
      ...searchApi,
      enableLocalSearch: (enabled: boolean) => dispatch({ type: 'localSearch', value: enabled }),
      reset: () => dispatch({ type: 'reset', value: undefined }),
      setFocus: (focused: boolean) => dispatch({ type: 'focus', value: focused }),
      setGlobal: (global: boolean) => dispatch({ type: 'global', value: global }),
      setValue: (value: string | null) => dispatch({ type: 'value', value }),
    }),
    [searchApi, dispatch]
  )

  return contextOptions
}

/**
 * Method to get search context
 */
export const useSearch = (): SearchContextOptions & { value: string | null } => {
  const searchApi = useContext(SearchContext)

  return { ...searchApi, value: searchApi.value || null }
}

/**
 * Method to enable local search for tables
 */
export const useLocalSearch = (
  identifier: string
): SearchContextOptions & { value: string | null } => {
  const didMount = useRef(false)

  const localStorageKey = `search-${identifier}`

  const searchApi = useContext(SearchContext)

  // must run before getting value from local storage
  useEffect(() => {
    searchApi.reset()

    // load value from local storage
    const value = localStorage.getItem(localStorageKey)
    searchApi.setValue(value || null)
    searchApi.enableLocalSearch(true)

    return () => {
      searchApi.enableLocalSearch(false)
    }
  }, [identifier])

  // store value to local storage
  useEffect(() => {
    // do not run on initial mount
    if (didMount.current) {
      // set on change
      if (searchApi.value) {
        localStorage.setItem(localStorageKey, searchApi.value)
      }
      // remove item
      else {
        localStorage.removeItem(localStorageKey)
      }
    } else {
      didMount.current = true
    }
  }, [searchApi.value])

  return { ...searchApi, value: searchApi.value || null }
}
