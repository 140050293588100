// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(false);
// Module
exports.push([module.id, ".SubscribeBanner_root_1yg_4{box-sizing:border-box;overflow:hidden;max-height:0}.SubscribeBanner_root_1yg_4.SubscribeBanner_trial_2pDoF{background-color:#ffbd2e}.SubscribeBanner_root_1yg_4.SubscribeBanner_restricted_UIM-r{background-color:#ff6059}.SubscribeBanner_root_1yg_4.SubscribeBanner_restricted_UIM-r .SubscribeBanner_content_U70By>span:first-child{color:white}.SubscribeBanner_root_1yg_4.SubscribeBanner_restricted_UIM-r .SubscribeBanner_content_U70By .SubscribeBanner_link_2vAEB{text-decoration:underline}.SubscribeBanner_root_1yg_4.SubscribeBanner_restricted_UIM-r .SubscribeBanner_content_U70By .SubscribeBanner_link_2vAEB:hover{text-decoration:none}.SubscribeBanner_root_1yg_4.SubscribeBanner_animation_3l3I9{transition:max-height 2s ease-out 1s;max-height:200px}.SubscribeBanner_root_1yg_4 .SubscribeBanner_content_U70By{flex-wrap:wrap;align-items:center;padding:20px;text-align:center;font-weight:500}.SubscribeBanner_root_1yg_4 .SubscribeBanner_content_U70By>span{margin-right:5px}\n", ""]);
// Exports
exports.locals = {
	"root": "SubscribeBanner_root_1yg_4",
	"trial": "SubscribeBanner_trial_2pDoF",
	"restricted": "SubscribeBanner_restricted_UIM-r",
	"content": "SubscribeBanner_content_U70By",
	"link": "SubscribeBanner_link_2vAEB",
	"animation": "SubscribeBanner_animation_3l3I9"
};
module.exports = exports;
